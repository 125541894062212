import { useTranslation } from "react-i18next";
import moment from "moment";
import { AccordionBooking } from "../AccordionBooking";
import { PaymentScheduleType } from "../../utils/globalTypes";
import { getCurrencyIcon, getFormatAmount } from "../../utils/hooks";
import {
  AccordionWrapper,
  StyledDate,
  StyledPaymentInfo,
  StyledPaymentInfoItem,
  StyledPaymentInfoItemColumn,
  StyledPaymentScheduleWrapper,
  StyledPrice,
  StyledStatusPay,
} from "./PaymentSchedule.styles";

type PaymentScheduleProps = {
  payments: PaymentScheduleType[]
}

export const PaymentSchedule = ({ payments }: PaymentScheduleProps) => {
  const { t } = useTranslation();

  return (
    <StyledPaymentScheduleWrapper>
      <AccordionWrapper>
        <AccordionBooking title={t("paymentSchedule")}>
          <StyledPaymentInfo>
            {payments?.map((item: PaymentScheduleType) => (
              <StyledPaymentInfoItem key={item.actualDate}>
                <StyledPaymentInfoItemColumn>
                  <StyledPrice>
                    <span>{getFormatAmount(item?.amount?.amount)}</span>
                    <span>{getCurrencyIcon(item?.amount?.currency)}</span>
                  </StyledPrice>

                  <StyledDate>
                    <span>{t("until")} </span>
                    <span>{moment(item?.dueDate, 'YYYY-MM-DD hh:mm').format('DD MMM, YY')}</span>
                  </StyledDate>
                </StyledPaymentInfoItemColumn>

                <StyledPaymentInfoItemColumn>
                  <StyledStatusPay 
                    className={item?.isPaid ? "paid" : "overdue"}
                  >
                    {item?.isPaid ? t("paid") : t("isOverdue")}
                  </StyledStatusPay>
                </StyledPaymentInfoItemColumn>
              </StyledPaymentInfoItem>
            ))}
          </StyledPaymentInfo>
        </AccordionBooking>
      </AccordionWrapper>
    </StyledPaymentScheduleWrapper>
  );
};
