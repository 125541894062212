import { BookingDetails } from "../pages/BookingDetails";
import { Bookings } from "../pages/Bookings";
import { ChangePassword } from "../pages/ChangePassword";
import { ForgotPassword } from "../pages/ForgotPassword";
import { Login } from "../pages/Login";
import { Loyalty } from "../pages/Loyalty";
import { Profile } from "../pages/Profile";
import { Registration } from "../pages/Registration";
import { SetPassword } from "../pages/SetPassword";
import { Travellers } from "../pages/Travellers";

export type RoutesLinksType = {
  name: string,
  path: string,
  key: string,
  ignore?: boolean,
  element?: JSX.Element
}

export const PRIVATE_ROUTES: RoutesLinksType[] = [
  {
    name: "Profile",
    path: "/profile",
    key: "profile",
    element: <Profile />
  },
  {
    name: "Bookings",
    path: "/bookings",
    key: "bookings",
    element: <Bookings />
  },
  // {
  //   name: "Loyalty",
  //   path: "/loyalty",
  //   key: "loyalty",
  //   element: <Loyalty />
  // },
  {
    name: "Travellers",
    path: "/travellers",
    key: "travellers",
    element: <Travellers />,
    ignore: true
  },
  {
    name: "BookingDetails",
    path: "/bookingDetails/:id",
    key: "bookingDetails",
    element: <BookingDetails />,
    ignore: true
  }
];

export const PUBLIC_ROUTES: RoutesLinksType[] = [
  {
    name: "login",
    path: "/",
    key: "login",
    element: <Login />
  },
  {
    name: "registration",
    path: "/registration",
    key: "registration",
    element: <Registration />
  },
  {
    name: "forgotPassword",
    path: "/forgotPassword",
    key: "forgotPassword",
    element: <ForgotPassword />
  },
  {
    name: "changePassword",
    path: "/reset/password/:id/:email",
    key: "changePassword",
    element: <ChangePassword />
  },
  {
    name: "setPassword",
    path: "/registration/password/:token/:email",
    key: "setPassword",
    element: <SetPassword />
  }
];