import {gql} from "@apollo/client";

export const CRUISE_ROUTE = gql`
  query CRUISE_ROUTE($context: Context, $cruiseId: Int!) {
    cruiseRoute(context: $context, cruiseId: $cruiseId) {
      day
      port
      arrival
      departure
  }
 }`