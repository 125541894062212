import styled from 'styled-components'
import { mainTheme } from "../../theme"

export const StyledBookingDetailsInfoWrapper = styled.div`
  gap: 12px;
  background: ${mainTheme.main.colors.white};
  padding: 16px;
  box-shadow: 0px 3px 14px -6px rgba(0,0,0,0.40);
`

export const StyledBookingDetailsInfoHeaderColumns = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 20px; 
`

export const StyledBookingDetailsInfoHeaderColumn = styled.div`
  color: ${mainTheme.main.colors.merin};
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.dmsRegular};
  font-size: ${mainTheme.main.fontSizes.small};
`

export const StyledBookingDetailsInfoColumns = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 20px; 
`

export const StyledBookingDetailsInfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

export const StyledBookingDetailsInfoColumnContent = styled.div`
  color: ${mainTheme.main.colors.black};
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.dmsRegular};
  font-size: ${mainTheme.main.fontSizes.medium};

  &.code {
    font-family: ${mainTheme.main.fonts.chapRegular};
  }

  &.status {
    color: ${mainTheme.main.colors.blueFantom};
    font-weight: ${mainTheme.main.fontWeights.boldExtra};
    font-size: ${mainTheme.main.fontSizes.xsmall};
    text-transform: uppercase;
    background-color: ${mainTheme.main.colors.blueLigth};
    padding: 3px 12px;
    border-radius: 4px;
    width: fit-content;
  }

  &.timeLimit {
    padding: 6px 12px;
    background-color: ${mainTheme.main.colors.merin};
    color: ${mainTheme.main.colors.white};
    font-weight: ${mainTheme.main.fontWeights.boldExtra};
    font-family: ${mainTheme.main.fonts.dmsRegular};
    font-size: ${mainTheme.main.fontSizes.medium};
  }
`

export const StyledDateTimeColumn = styled.div`
  display: flex;
  gap: 3px;
`