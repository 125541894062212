import { useTranslation } from "react-i18next";
import {
  StyledCustomerColumn,
  StyledCustomerColumnContent,
  StyledCustomerColumns,
  StyledCustomerHeaderColumn,
  StyledCustomerWrapper,
  StyledIconWrapper,
  StyledStateroomsSectionTitle,
} from "./Customer.styles";
import { IconLoylaty } from "../../icons/IconLoylaty";
import { OrderType } from "../../appolo/graphql/queries/types";

type CustomerProps = {
  booking: OrderType
}

export const Customer = ({ booking }: CustomerProps) => {
  const { t } = useTranslation();

  return (
    <StyledCustomerWrapper>
    <StyledStateroomsSectionTitle>
      {t("customer")} 
    </StyledStateroomsSectionTitle>

    <StyledCustomerColumns>
      <StyledCustomerColumn>
        <StyledCustomerHeaderColumn>
          {t("fullName")}
        </StyledCustomerHeaderColumn>
        <StyledCustomerColumnContent className="row">
          <span>
            {booking?.owner?.lastname}
          </span>
          <span>
            {booking?.owner?.firstname}
          </span>
          <span>
            {booking?.owner?.middlename}
          </span>
        </StyledCustomerColumnContent>
      </StyledCustomerColumn>

      <StyledCustomerColumn>
        <StyledCustomerHeaderColumn>
          {t("email")}
        </StyledCustomerHeaderColumn>
        <StyledCustomerColumnContent>
          {booking?.owner?.contactEmail}
        </StyledCustomerColumnContent>
      </StyledCustomerColumn>

      <StyledCustomerColumn>
        <StyledCustomerHeaderColumn>
          {t("phone")}
        </StyledCustomerHeaderColumn>
        <StyledCustomerColumnContent className="row">
          <span>
            +{booking?.owner?.contactPhone?.code}
          </span>
          <span>
            {booking?.owner?.contactPhone?.number}
          </span>
        </StyledCustomerColumnContent>
      </StyledCustomerColumn>

      <StyledCustomerColumn>
        <StyledCustomerHeaderColumn>
          {t("loyaltyNumber")}
        </StyledCustomerHeaderColumn>
        <StyledCustomerColumnContent className="row">
          <StyledIconWrapper>
            <IconLoylaty />
          </StyledIconWrapper>
          {booking?.owner?.bonusCardNumber ?? "-"}
        </StyledCustomerColumnContent>
      </StyledCustomerColumn>

    </StyledCustomerColumns>
  </StyledCustomerWrapper>
  );
};
