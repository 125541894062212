import styled from 'styled-components'
import { mainTheme } from "../../theme"

export const StyledWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  min-height: 100%;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.01)
`