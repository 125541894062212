/* eslint-disable */
import { useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Box,
  Stack,
  Pagination,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { t } from "i18next";
import {
  BookingsTableProps,
  TableBookingCellProps,
  TableBookingRowsProps,
} from "./types";
import { CommonTable } from "../CustomTable";
import { HEAD_CEALS } from "./consts";
import {
  StyledDoubleColumnWrapper,
  StyledTableCellBookingCode,
  StyledTabeCellStatus,
  StyledTableCellDay,
  StyledTableCellTime,
  StyledTableCellNameShip,
  StyledTableCellCodeShip,
  StyledTableCellRouteWrapper,
  StyledTableCellRouteStart,
  StyledTableCellRouteEnd,
  StyledBoxColumn,
  StyledTableCellRoomNumber,
  StyledTableCellRoomPlace,
  StyledTableCellPassenger,
  StyledTableCellPrice,
  StyledTableCellPaymentTotal,
  StyledTableCellPaymentCode,
  StyledTableCellStatusTitle,
  StyledTableWrapper,
  StyledTableColumnBox,
  StyledTableCellPriceColumn,
  StyledTimeZone,
  StyledPreloaderWrapper,
  StyledPaginationWrapper,
} from "./BookingsTable.styles";
import {
  PaymentStatusEnum,
} from "../../utils/globalTypes";
import { useTranslation } from "react-i18next";
import { getCurrencyIcon } from "../../utils/hooks";
import { OrderListItem } from "../../appolo/graphql/queries/types";

type TableHeaderColumnProps = {
  onRequestSort: (event: any, property: any) => void;
  order: any;
  orderBy: string;
};

type HeadCellProps = {
  id: any;
  numeric: boolean;
  label: string;
};

const TableHeaderColumn = ({
  onRequestSort,
  order,
  orderBy,
}: TableHeaderColumnProps) => {
  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {HEAD_CEALS.map((headCell: HeadCellProps) => {
          return (
            <TableCell
              key={headCell.id}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {t(headCell.id)}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

{
  /* Колонка Booking code */
}
const TableCellBookingCode = ({ booking }: TableBookingCellProps) => {
  return (
    <TableCell>
      <StyledDoubleColumnWrapper>
        <StyledTableCellBookingCode>
          <Link to={`/bookingDetails/${booking?.uuid}`}>{booking?.number}</Link>
        </StyledTableCellBookingCode>
        <StyledTabeCellStatus>{booking?.status}</StyledTabeCellStatus>
      </StyledDoubleColumnWrapper>
    </TableCell>
  );
};

{
  /* Колонка Date / Time */
}
const TableCellDateTime = ({ booking }: TableBookingCellProps) => {
  const getNameTimeZone = booking?.createdAt?.replace(/[^a-zA-Z]+/g, "");
  return (
    <TableCell>
      <StyledDoubleColumnWrapper>
        {booking?.cruises?.map((cruise, index) => (
          <div key={index}>
            <StyledTableColumnBox>
              <StyledTableCellDay>
                {moment(cruise.dateStartRaw, "YYYY-MM-DD hh:mm").format(
                  `ddd, DD MMM YY`
                )}
              </StyledTableCellDay>
              <StyledTableCellTime>
                {moment(cruise.dateStartRaw, "YYYY-MM-DD hh:mm").format(
                  "hh:mm a"
                )}
                <StyledTimeZone>{getNameTimeZone}</StyledTimeZone>
                {moment(cruise.dateStartRaw, "YYYY-MM-DD hh:mm")
                  .format(`Z`)
                  .slice(0, -3)}
              </StyledTableCellTime>
            </StyledTableColumnBox>

            <StyledTableColumnBox>
              <StyledTableCellDay>
                {moment(cruise.dateEndRaw, "YYYY-MM-DD hh:mm").format(
                  `ddd, DD MMM YY`
                )}
              </StyledTableCellDay>
              <StyledTableCellTime>
                {moment(cruise.dateEndRaw, "YYYY-MM-DD hh:mm").format(
                  "hh:mm a"
                )}
                <StyledTimeZone>{getNameTimeZone}</StyledTimeZone>
                {moment(cruise.dateEndRaw, "YYYY-MM-DD hh:mm")
                  .format(`Z`)
                  .slice(0, -3)}
              </StyledTableCellTime>
            </StyledTableColumnBox>
          </div>
        ))}
      </StyledDoubleColumnWrapper>
    </TableCell>
  );
};

{
  /* Колонка Ship / Route */
}
const TableCellShipRoute = ({ booking }: TableBookingCellProps) => {
  return (
    <TableCell>
      <StyledDoubleColumnWrapper>
        {booking?.cruises?.map((cruise, index) => (
          <StyledTableColumnBox key={index}>
            <StyledTableCellNameShip>
              {cruise?.motorshipName}
            </StyledTableCellNameShip>

            <StyledTableCellCodeShip>
              {cruise?.cruiseId}
            </StyledTableCellCodeShip>

            <StyledTableCellRouteWrapper>
              <StyledTableCellRouteStart>
                {cruise?.originPort?.cityName}
              </StyledTableCellRouteStart>
              <StyledTableCellRouteEnd>
                {cruise?.destinationPort?.cityName}
              </StyledTableCellRouteEnd>
            </StyledTableCellRouteWrapper>
          </StyledTableColumnBox>
        ))}
      </StyledDoubleColumnWrapper>
    </TableCell>
  );
};

{
  /* Колонка Stateroom */
}
const TableCellStateRoom = ({ booking }: TableBookingCellProps) => {
  // Массив кают (может ли быть несколько элементов в массиве cruise - ?)
  const cabinsArr = booking?.cruises?.map((cruise) => cruise)[0]?.cabins;

  return (
    <StyledBoxColumn>
      {cabinsArr?.map((cabin, idx: number) => (
        <StyledDoubleColumnWrapper key={idx} className="rowColumn">
          <StyledTableCellRoomNumber>
            {cabin?.number ?? "-"}
          </StyledTableCellRoomNumber>
          <StyledTableCellRoomPlace>
            {cabin?.roomClass ?? "-"}
          </StyledTableCellRoomPlace>
        </StyledDoubleColumnWrapper>
      ))}
    </StyledBoxColumn>
  );
};

{
  /* Колонка Passengers */
}
const TableCellPassengers = ({ booking }: TableBookingCellProps) => {
  // Массив пассажиров
  const passengers = booking?.travellers?.map((traveller) => traveller);

  return (
    <StyledBoxColumn>
      {passengers?.map((passenger, index) => (
        <StyledDoubleColumnWrapper key={index} className="rowColumn">
          <StyledTableCellPassenger>
            {passenger.lastname}
          </StyledTableCellPassenger>

          <StyledTableCellPassenger>
            {passenger.firstname}
          </StyledTableCellPassenger>

          <StyledTableCellPassenger>
            {passenger.middlename}
          </StyledTableCellPassenger>
        </StyledDoubleColumnWrapper>
      ))}
    </StyledBoxColumn>
  );
};

{
  /* Колонка Price */
}
const TableCellPrice = ({ booking }: TableBookingCellProps) => {
  // Стоимость с разделением на десятки (через запятую)
  const price = (booking.price?.amount / 100)
    ?.toString()
    ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");

  return (
    <StyledTableCellPrice>
      <StyledTableCellPriceColumn>
        {getCurrencyIcon(booking?.price?.currency)}
        {price}
      </StyledTableCellPriceColumn>
    </StyledTableCellPrice>
  );
};

{
  /* Колонка Payment Schedule */
}
const TableCellPaymentSchedule = ({ booking }: TableBookingCellProps) => {
  // Массив paymentSchedule
  const paymentSchedule = booking?.paymentSchedule;
  return (
    <StyledBoxColumn>
      {paymentSchedule?.map((schedule, index) => {
        const paymentSchedulePrice = (schedule?.amount.amount / 100)
          ?.toString()
          ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");

        return (
          <StyledDoubleColumnWrapper key={index}>
            <StyledTableCellPaymentTotal>
              {getCurrencyIcon(schedule.amount.currency)} {paymentSchedulePrice}
            </StyledTableCellPaymentTotal>

            <StyledTableCellPaymentCode>
              until{" "}
              {moment(schedule?.dueDate, "YYYY-MM-DD hh:mm").format(
                "DD MMM, YY"
              )}
            </StyledTableCellPaymentCode>
          </StyledDoubleColumnWrapper>
        );
      })}
    </StyledBoxColumn>
  );
};

{
  /* Колонка Status */
}
const TableCellStatus = ({ booking }: TableBookingCellProps) => {
  const status = () => {
    if (booking?.paymentStatus === PaymentStatusEnum.notPaid) {
      return (
        <StyledTableCellStatusTitle className={PaymentStatusEnum.notPaid}>
          {booking?.paymentStatus}
        </StyledTableCellStatusTitle>
      );
    }

    if (booking?.paymentStatus === PaymentStatusEnum.paid) {
      return (
        <StyledTableCellStatusTitle className={PaymentStatusEnum.paid}>
          {booking?.paymentStatus}
        </StyledTableCellStatusTitle>
      );
    }

    if (booking?.paymentStatus === PaymentStatusEnum.partially) {
      return (
        <StyledTableCellStatusTitle className={PaymentStatusEnum.partially}>
          {booking?.paymentStatus}
        </StyledTableCellStatusTitle>
      );
    }

    if (booking?.paymentStatus === PaymentStatusEnum.overpaid) {
      return (
        <StyledTableCellStatusTitle className={PaymentStatusEnum.overpaid}>
          {booking?.paymentStatus}
        </StyledTableCellStatusTitle>
      );
    }
  };

  return (
    <StyledBoxColumn>
      <StyledDoubleColumnWrapper>
        <StyledTableCellStatusTitle>{status()}</StyledTableCellStatusTitle>
      </StyledDoubleColumnWrapper>
    </StyledBoxColumn>
  );
};

const TableBookingRows = ({ booking, onClickRow }: TableBookingRowsProps) => {
  const handleClick = () => {
    onClickRow(booking);
  };

  return (
    <TableRow onClick={handleClick}>
      <TableCellBookingCode booking={booking} />
      <TableCellDateTime booking={booking} />
      <TableCellShipRoute booking={booking} />
      <TableCellStateRoom booking={booking} />
      <TableCellPassengers booking={booking} />
      <TableCellPrice booking={booking} />
      <TableCellPaymentSchedule booking={booking} />
      <TableCellStatus booking={booking} />
    </TableRow>
  );
};

export const BookingsTable = ({
  bookings,
  setCurrentPage,
  pageCount,
  orderListDataLoading,
}: BookingsTableProps) => {
  moment.locale("en");

  const { t } = useTranslation();

  const [page, setPage] = useState(1);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);

    if (setCurrentPage) {
      setCurrentPage(value);
    }
  };

  const [currentSelectedRowInTable, setCurrentSelectedRowInTable] =
    useState<OrderListItem>();

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  if (orderListDataLoading) {
    return (
      <StyledPreloaderWrapper>
        {t("loading")}...
      </StyledPreloaderWrapper>
    )
  }

  return (
    <>
      <StyledTableWrapper>
        <CommonTable
          headerList={
            <TableHeaderColumn
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
          }
        >
          {bookings?.map((item: OrderListItem) => (
            <TableBookingRows
              key={item?.number}
              booking={item}
              onClickRow={(value: OrderListItem) =>
                setCurrentSelectedRowInTable(value)
              }
            />
          ))}
        </CommonTable>
      </StyledTableWrapper>

      <StyledPaginationWrapper>
        <Stack spacing={0}>
          <Pagination
            variant="outlined"
            shape="rounded"
            count={pageCount}
            page={page}
            onChange={handleChange}
          />
        </Stack>
      </StyledPaginationWrapper>
    </>
  );
};
