import {gql} from "@apollo/client";

export const ORDER_CABIN = gql`
  query ORDER_CABIN($uuid: String!, $position: Int!, $cabinId: Int!, $context: Context) {
    orderCabin(uuid: $uuid, position: $position, cabinId: $cabinId, context: $context) {
      id
      number
      class {
        id
        name
      }
      deck {
        id
        name
      }
      side {
        id
        name
      }
      accommodation {
        id
        name
      }
      bedConfiguration {
        id
        name
      }
      places {
        id
        traveller {
          id
          personalInfo {
            title
            firstname
            lastname
            middlename
            hasNoMiddlename
            dob
            countryOfResidence
            gender
          }
          healthDeclaration
          contactInfo {
            contactPhone {
              code
              number
            }
            contactEmail
            country
            region
            city
            zipCode
            address
          }
          emergencyContact {
            firstname
            lastname
            contactPhone {
              code
              number
            }
            contactEmail
            kind
          }
          # logistics
          # additionalRequirements
          document {
            kind
            firstname
            lastname
            middlename
            hasNoMiddlename
            series
            number
            issueDate
            expirationDate
            issuePlace
            issueCountry
            placeOfBirth
          }
        }
        tariff {
          id
          name
        }
        isAboard
        boardingPassPdf
        boardingPassNumber
        qrCode
        priceTariff {
          amount
          currency
          vatAmount
          vatKind
        }
      }
      services {
        id
        serviceId
        placeId
        name
        type {
          id
          name
        }
        category
        cancellationPolicy
        commissionPercent
        commissionAmount {
          amount
          currency
        }
        count
        price {
          amount
          currency
          vatAmount
          vatKind
        }
        startDate
        endDate
      }
  }
 }`