import styled from 'styled-components'
import { mainTheme } from "../../theme"

export const StyledTravellersWrapper = styled.div`
  padding: 10px 20px 34px 20px;
  box-sizing: border-box;
`

export const StyledTravellersMain = styled.div``

export const StyledTravellersHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background: ${mainTheme.main.colors.white};
  padding: 24px;
  padding-bottom: 0px;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    display: flex;
    flex-direction: column;
    padding: 16px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 16px;
  }
`

export const StyledTravellersTitle = styled.div`
  color: ${mainTheme.main.colors.blue};
  font-size: ${mainTheme.main.fontSizes.large};
  font-weight: ${mainTheme.main.fontWeights.bold};
  font-family: ${mainTheme.main.fonts.chapMedium};
  text-transform: uppercase;
`

export const StyledSearchWrapper = styled.div`
  max-width: 343px;
  width: 100%;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    max-width: 100%;
    margin-top: 12px;
  }
`

export const StyledTableTravellersWrapper = styled.div`
  display: block;
  padding: 20px 24px 24px 24px;
  background: ${mainTheme.main.colors.white};
 
  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    display: none;
  }
`

export const StyledAddNewWrapper = styled.div`
  padding: 0px 24px 24px 24px;
  background: ${mainTheme.main.colors.white};

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    padding: 16px;
    padding-left: 0;
    padding-top: 16px;
    background: none;
  }
`

export const StyledAddNew = styled.div`
  color: ${mainTheme.main.colors.merin};
  font-size: 15px;
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.dmsRegular};
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
`

export const StyledTravellersListMobileWrapper = styled.div`
  display: none;
 
  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    display: block;
    padding: 0px;
    padding-top: 0;
  }
`