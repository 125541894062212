/* eslint-disable */
import { useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { IconDelete } from "../../icons/IconDelete";
import { ModalInfo } from "../ModalInfo";
import { ServiceDetails } from "../ServiceDetails/ServiceDetails";
import { getCurrencyIcon, getFormatAmount } from "../../utils/hooks";
import { NameGroup } from "../../pages/BookingDetails/types";
import {
  OrderCabinServiceType,
  ReservationType,
} from "../../appolo/graphql/queries/types";
import {
  AdditionalServicesMobileWrapper,
  StyledAdditionalServiceItem,
  StyledAdditionalServiceRow,
  StyledAdditionalServiceRowItem,
  StyledAdditionalServicesSectionTitle,
  StyledAdditionalServiceTypeName,
  StyledButtonCancel,
  StyledButtonDelete,
  StyledButtonSave,
  StyledButtonsWrapper,
  StyledDatesService,
  StyledModalContent,
  StyledModalContentTwoColumns,
  StyledNameCategoryService,
  StyledNameService,
  StyledNameSevice,
  StyledTravellerFIO,
} from "./AdditionalServicesMobile.styles";

type AdditionalServicesMobileProps = {
  nameGroups: NameGroup[];
  services: any;
  places: ReservationType[];
};

export const AdditionalServicesMobile = ({
  nameGroups,
  services,
  places,
}: AdditionalServicesMobileProps) => {
  const { t } = useTranslation();

  const [selectedService, setSelectedService] = useState();

  const [openModalDetails, setOpenModalDetails] = useState(false);
  const [openModalCancelService, setOpenModalCancelService] = useState(false);
  const [openModalSuccessfully, setOpenModalSuccessfully] = useState(false);

  const handleDelete = (item: any) => {
    setSelectedService(item);
    setOpenModalCancelService(true);
  };

  const handleYes = () => {
    setOpenModalCancelService(false);
    setOpenModalSuccessfully(true);
  };

  console.log("selectedService: ", selectedService);

  return (
    <AdditionalServicesMobileWrapper>
      <StyledAdditionalServicesSectionTitle>
        {t("additionalServices")}
      </StyledAdditionalServicesSectionTitle>

      <StyledAdditionalServiceItem>
        {nameGroups?.map((service: NameGroup) => {
          return (
            <>
              {/* Название категории  */}
              <StyledAdditionalServiceTypeName>
                {service?.name}
              </StyledAdditionalServiceTypeName>

              {/* Сервисы входящие в категорию */}
              {services?.[service?.name]?.map((item: OrderCabinServiceType) => {
                const place =
                  places &&
                  places?.filter((place) => place.id === item.placeId)[0];
                return (
                  <StyledAdditionalServiceRow key={item?.id}>
                    {/* Название категории и имя сервиса */}
                    <StyledAdditionalServiceRowItem className="name">
                      <StyledNameSevice>
                        <StyledNameCategoryService>
                          {/* {item?.type?.name} */}
                          {service?.name === "ONBOARD" &&
                            !item?.type?.name &&
                            t("service")}
                        </StyledNameCategoryService>
                        {item?.name}
                      </StyledNameSevice>

                      {/* Кнопка удаления */}
                      <StyledButtonDelete onClick={() => handleDelete(service)}>
                        <IconDelete />
                      </StyledButtonDelete>
                    </StyledAdditionalServiceRowItem>

                    {/* Дата */}
                    <StyledAdditionalServiceRowItem>
                      <StyledDatesService>
                        <span>
                          {item?.startDate
                            ? moment(item?.startDate).format("DD MMM YYYY")
                            : "-"}
                        </span>
                        <StyledNameCategoryService>
                          {item?.type?.name}
                        </StyledNameCategoryService>

                        {/* <span>
                              {item?.endDate && <span>- {moment(item?.endDate).format("DD MMM YYYY")}</span>}
                            </span> */}
                      </StyledDatesService>
                    </StyledAdditionalServiceRowItem>

                    {/* ФИО пассажира */}
                    <StyledAdditionalServiceRowItem className="namePassenger">
                      {t("fullName")}
                      {place?.traveller?.document?.firstname ? (
                        <div>
                          <span>{place?.traveller?.document?.lastname}</span>
                          <span>{place?.traveller?.document?.firstname}</span>
                          <span>{place?.traveller?.document?.middlename}</span>
                        </div>
                      ) : (
                        <div>
                          <span>-</span>
                        </div>
                      )}
                    </StyledAdditionalServiceRowItem>

                    {/* Цена */}
                    <StyledAdditionalServiceRowItem className="price">
                      {t("price")}
                      <div>
                        <span>{getCurrencyIcon(item?.price?.currency)}</span>
                        <span>{getFormatAmount(item?.price?.amount)}</span>
                      </div>
                    </StyledAdditionalServiceRowItem>
                  </StyledAdditionalServiceRow>
                );
              })}
            </>
          );
        })}
      </StyledAdditionalServiceItem>

      {/* Модальное окно "Details" */}
      <ModalInfo
        title="Details"
        open={openModalDetails}
        setOpen={(value: boolean) => setOpenModalDetails(value)}
      >
        <ServiceDetails />
      </ModalInfo>

      {/* Модальное окно "Cancel Service" */}
      <ModalInfo
        title="cancelService"
        open={openModalCancelService}
        setOpen={(value: boolean) => setOpenModalCancelService(value)}
      >
        <StyledModalContent>{t("questionCancel")}</StyledModalContent>

        <StyledButtonsWrapper>
          <StyledButtonCancel onClick={() => setOpenModalCancelService(false)}>
            {t("cancel")}
          </StyledButtonCancel>
          <StyledButtonSave onClick={handleYes}>{t("yes")}</StyledButtonSave>
        </StyledButtonsWrapper>
      </ModalInfo>

      {/* Модальное окно "Your request has been successfully..." */}
      <ModalInfo
        title=""
        open={openModalSuccessfully}
        setOpen={(value: boolean) => setOpenModalSuccessfully(value)}
      >
        <StyledModalContentTwoColumns>
          <StyledModalContent>
            {t("yourRequestBeenSuccessfully")}
          </StyledModalContent>

          <StyledButtonSave onClick={() => setOpenModalSuccessfully(false)}>
            {t("close")}
          </StyledButtonSave>
        </StyledModalContentTwoColumns>
      </ModalInfo>
    </AdditionalServicesMobileWrapper>
  );
};
