import { useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { TypeFormEnum } from "../ModalFormEdit/types";
import { PersonalInformationProps } from "./types";
import {
  StyledAvatarColumn,
  StyledAvatarWrapper,
  StyledBookingInfoItem,
  StyledBookingInfoItemTitle,
  StyledBookingInfoPersonal,
  StyledBookingInfoPersonalInfoContent,
  StyledBookingInfoPersonalInfoContentItem,
  StyledBookingInfoPersonalInfoDock,
  StyledBookingInfoPersonalInfoList,
  StyledBookingInfoPersonalInfoListItem,
  StyledBookingInfoPersonalInfoListItemColumn,
  StyledBookingInfoPersonalInfoListItemColumnRow,
  StyledBookingInfoPersonalInfoListItemTitle,
  StyledBookingInfoPersonalInfoSection,
  StyledBookingInfoPersonalInfoWrapper,
  StyledChange,
  StyledChangeAvatar,
  StyledIconAvatar,
  StyledImgAvatar,
} from "./PersonalInformation.styles";
import { DragAndDropImage } from "../Forms/components/DragAndDropImage";
import { CustomModal } from "../CustomModal";
import { IconAvatar } from "../../icons/IconAvatar";

export const PersonalInformation = ({ editForm, currentUser }: PersonalInformationProps) => {
  const { t } = useTranslation();

  const [openModalPhoto, setOpenModalPhoto] = useState(false);

  const handleChange = () => {
    if (editForm) {
      editForm({
        title: "personalInformation",
        typeEditForm: TypeFormEnum.personalInformation,
      });
    }
  };

  console.log("currentUser: ", currentUser);

   // Временно (пока даты приходят в секундах)
   const formatDateTime = (seconds: number) => {
    const date = new Date(seconds * 1000);
    const formatDate = moment(date, "YYYY-MM-DD hh:mm").format(`YYYY-MM-DD`);
    const dateTime: any = moment(formatDate, "YYYY-MM-DD hh:mm").format(`DD MMM YYYY`);
    return dateTime
  };

  return (
    <StyledBookingInfoPersonalInfoSection>
      <StyledBookingInfoPersonalInfoWrapper>
        {/* Персональные данные */}
        <StyledBookingInfoItem>
          <StyledBookingInfoItemTitle>
            {t("personalInformation")}
          </StyledBookingInfoItemTitle>

          <StyledBookingInfoPersonalInfoContent>
            <StyledBookingInfoPersonalInfoContentItem>
              <StyledBookingInfoPersonalInfoList>
                {/* Фото / Телефон */}
                <StyledBookingInfoPersonalInfoListItemColumn className="photoColumn">
                  <StyledBookingInfoPersonalInfoListItem>
                    <StyledAvatarColumn>
                      <StyledAvatarWrapper>
                        <StyledIconAvatar>
                          <IconAvatar />
                        </StyledIconAvatar>
                        {/* Фото */}
                        <StyledImgAvatar src=""/>
                      </StyledAvatarWrapper>
                      
                      <StyledChangeAvatar onClick={() => setOpenModalPhoto(true)}>
                        {t("addPhoto")} 
                      </StyledChangeAvatar>
                    </StyledAvatarColumn>
                  </StyledBookingInfoPersonalInfoListItem>

                  <StyledBookingInfoPersonalInfoListItem>
                    <StyledBookingInfoPersonalInfoListItemTitle>
                      {t("phone")}
                    </StyledBookingInfoPersonalInfoListItemTitle>
                    <StyledBookingInfoPersonal>
                      {currentUser?.phone?.length ? currentUser?.phone : "-"}
                    </StyledBookingInfoPersonal>
                  </StyledBookingInfoPersonalInfoListItem>
                </StyledBookingInfoPersonalInfoListItemColumn>

                {/* ФИО / Email */}
                <StyledBookingInfoPersonalInfoListItemColumn className="paddingDown">
                  <StyledBookingInfoPersonalInfoListItem>
                    <StyledBookingInfoPersonalInfoListItemTitle>
                      {t("fullName")}
                    </StyledBookingInfoPersonalInfoListItemTitle>
                    <StyledBookingInfoPersonal>
                      <span>{currentUser?.lastname?.length ? currentUser?.lastname : ""}</span>
                      <span>{currentUser?.firstname?.length ? currentUser?.firstname  : ""}</span>
                      <span>{currentUser?.patronymic?.length ? currentUser?.patronymic  : ""}</span>
                    </StyledBookingInfoPersonal>
                  </StyledBookingInfoPersonalInfoListItem>

                  <StyledBookingInfoPersonalInfoListItem className="mobile">
                    <StyledBookingInfoPersonalInfoListItemTitle>
                      {t("phone")}
                    </StyledBookingInfoPersonalInfoListItemTitle>
                    <StyledBookingInfoPersonal>
                      {currentUser?.phone?.length ? currentUser?.phone : "-"}
                    </StyledBookingInfoPersonal>
                  </StyledBookingInfoPersonalInfoListItem>

                  <StyledBookingInfoPersonalInfoListItem>
                    <StyledBookingInfoPersonalInfoListItemTitle>
                      {t("email")}
                    </StyledBookingInfoPersonalInfoListItemTitle>
                    <StyledBookingInfoPersonal>
                      {currentUser?.email?.length ?currentUser?.email : "-"}
                    </StyledBookingInfoPersonal>
                  </StyledBookingInfoPersonalInfoListItem>
                </StyledBookingInfoPersonalInfoListItemColumn>

                {/* Дата рождения / Гражданство */}
                <StyledBookingInfoPersonalInfoListItemColumn className="paddingDown mobileColumnRigth">
                  <StyledBookingInfoPersonalInfoListItemColumnRow>
                    <StyledBookingInfoPersonalInfoListItem>
                      <StyledBookingInfoPersonalInfoListItemTitle>
                        {t("birthDate")}
                      </StyledBookingInfoPersonalInfoListItemTitle>
                      <StyledBookingInfoPersonal>
                        {formatDateTime(Number(currentUser?.dob))}
                      </StyledBookingInfoPersonal>
                    </StyledBookingInfoPersonalInfoListItem>

                    <StyledBookingInfoPersonalInfoListItemColumn className="mobileColumnRigth">
                      <StyledBookingInfoPersonalInfoListItem>
                        <StyledBookingInfoPersonalInfoListItemTitle>
                          {t("countryOfResidence")}
                        </StyledBookingInfoPersonalInfoListItemTitle>

                        <StyledBookingInfoPersonal>
                          {currentUser?.countryOfResidence?.length ? currentUser?.countryOfResidence : "-"}
                        </StyledBookingInfoPersonal>
                      </StyledBookingInfoPersonalInfoListItem>
                    </StyledBookingInfoPersonalInfoListItemColumn>
                  </StyledBookingInfoPersonalInfoListItemColumnRow>

                  {/* Адрес */}
                  <StyledBookingInfoPersonalInfoListItem>
                    <StyledBookingInfoPersonalInfoListItemTitle>
                      {t("fullAddress")}
                    </StyledBookingInfoPersonalInfoListItemTitle>
                    <StyledBookingInfoPersonal>
                      {currentUser?.address?.length ? currentUser?.address : "-"}
                    </StyledBookingInfoPersonal>
                  </StyledBookingInfoPersonalInfoListItem>
                </StyledBookingInfoPersonalInfoListItemColumn>
              </StyledBookingInfoPersonalInfoList>
            </StyledBookingInfoPersonalInfoContentItem>
          </StyledBookingInfoPersonalInfoContent>

          <StyledChange onClick={handleChange}>{t("edit")}</StyledChange>
        </StyledBookingInfoItem>
      </StyledBookingInfoPersonalInfoWrapper>
      
      {/* Модальное окно добавления фото */}
      <CustomModal
        open={openModalPhoto}
        setOpen={(value) => setOpenModalPhoto(value)}
        title={t("addPhoto")}
      >
        <div>
          <DragAndDropImage 
            onClose={(value: boolean) => setOpenModalPhoto(value)}
          />
        </div>
      </CustomModal>
    </StyledBookingInfoPersonalInfoSection>
  );
};
