/* eslint-disable */
import { useEffect, useState } from "react";
import moment from "moment";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import { useMutation } from "@apollo/client";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { FormControlLabel, Radio, TextField } from "@mui/material";
import { mainTheme } from "../../../theme";
import { CustomSelect } from "../components/CustomSelect";
import { FieldDatePicker } from "../components/FieldDatePicker";
import { CustomRadioGroup } from "../components/CustomRadioGroup";
import IconCheckRadio from "../../../icons/IconCheckRadio";
import { VariantCustomSelectEnum } from "../components/CustomSelect/types";
import { CustomCheckbox } from "../components/CustomCheckbox";
import { GENDER, INITIALS } from "./consts";
import { FormEditPersonalInformationProps } from "./types";
import { CitiesListType, CountriesListType } from "../../../utils/globalTypes";
import { UPDATE_USER_INFO } from "../../../appolo/graphql/mutations/updateUserInfo";
import { getCitiesList, getCountriesList } from "../../../utils/axios/hooks";
import { CustomAutocomplete } from "../components/CustomAutocomplete";
import {
  StyledButtonCancel,
  StyledButtonSave,
  StyledButtonsWrapper,
  StyledCheckboxFieldWrapper,
  StyledError,
  StyledErrorMessage,
  StyledFiedlsRow,
  StyledFiedlsRowContent,
  StyledFormEditPersonalInformationWrapper,
  StyledSectionFieldsTitle,
} from "./FormEditPersonalInformation.styles";

type IFormEditPersonalInformationInput = {
  firstname?: string;
  middlename?: string;
  hasNoMiddlename?: boolean;
  lastname: string;
  title?: string;
  dob: string;
  countryOfResidence?: string;
  gender?: string;
  phone?: string;
  email?: string;
  address?: string;
  country?: string;
  region?: string;
  city?: string;
  zipCode?: string;
};

export const FormEditPersonalInformation = ({
  setOpen,
  currentUser,
  refetchCurrentUser,
}: FormEditPersonalInformationProps) => {
  const { t } = useTranslation();

  const [countriesList, setCountriesList] = useState<CountriesListType[]>([]);

  const [citiesList, setCitiesList] = useState<CitiesListType[]>([]);

  const [isNotMiddleName, setIsNotMiddleName] = useState(false);

  const [errorUpdateUser, setErrorUpdateUser] = useState("");

  // Временно (пока даты приходят в секундах)
  const formatDateTime = (seconds: number) => {
    const date = new Date(seconds * 1000);
    const formatDate = moment(date, "YYYY-MM-DD hh:mm").format(`YYYY-MM-DD`);
    const dateTime: any = moment(formatDate, "YYYY-MM-DD hh:mm").format(
      `MM.DD.YYYY`
    );

    return dateTime;
  };

  const schema = yup.object().shape({
    firstname: yup.string(),
    middlename: isNotMiddleName
      ? yup.string()
      : yup.string().required(t("fieldRequired")),
    hasNoMiddlename: yup.boolean(),
    lastname: yup.string().required(t("fieldRequired")),
    title: yup.string(),
    dob: yup.string().required(t("fieldRequired")),
    // countryOfResidence: yup.string(),
    gender: yup.string(),
    phone: yup.string(),
    email: yup.string(),
    address: yup.string(),
    // country: yup.string(),
    region: yup.string(),
    city: yup.string(),
    zipCode: yup.string(),
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    reset,
  } = useForm<IFormEditPersonalInformationInput>({ resolver: yupResolver(schema) });

  useEffect(() => {
    if (currentUser && Object.keys(currentUser).length) {
      reset({
        firstname: currentUser?.firstname,
        middlename: currentUser?.patronymic ?? "",
        lastname: currentUser?.lastname,
        title: "MR",
        dob: formatDateTime(Number(currentUser?.dob) ?? ""),
        gender: currentUser?.gender ?? "MALE",
        phone: currentUser?.phone,
        email: currentUser?.email,
        address: currentUser?.address,
        countryOfResidence: currentUser?.countryOfResidence ?? "",
        country: "", // Не приходит с бекенда
        region: "", // Не приходит с бекенда
        city: "", // Не приходит с бекенда
        zipCode: "", // Не приходит с бекенда
        hasNoMiddlename: false, // Не приходит с бекенда
      });
    }
  }, [currentUser]);

  const getValuesForm = watch();

  const [updateUserInfo] = useMutation(UPDATE_USER_INFO);

  const onSubmit = async (data: IFormEditPersonalInformationInput) => {
    const formValue = {
      firstname: data?.firstname,
      middlename: data?.middlename ?? "",
      lastname: data?.lastname ?? "",
      title: data?.title ?? "MR",
      dob: dayjs(data?.dob).format("YYYY-MM-DD") ?? "",
      // @ts-ignore
      countryOfResidence: data?.countryOfResidence?.code ? data?.countryOfResidence?.code :  data?.countryOfResidence, // жду бекенд чтобы отдавал и принимал всегда объект code / value
      // @ts-ignore
      country: data.country?.code ? data.country?.code : data.country,  // жду бекенд чтобы отдавал и принимал всегда объект code / value
      gender: data?.gender ?? "MALE",
      phone: {
        code: "+7",
        number: data?.phone,
      },
      address: data?.address,
      region: data?.region,
      city: data?.city,
      zipCode: data?.zipCode,
    };

    updateUserInfo({
      variables: {
        form: {
          ...formValue,
        },
      },
      onCompleted: () => {
        setErrorUpdateUser("");
        if (setOpen) {
          setOpen(false);
        }
        if (refetchCurrentUser) {
          refetchCurrentUser();
        }
      },
      onError: (error) => {
        setErrorUpdateUser(error.message);
      },
    });
  };

  const handleCancel = () => {
    if (setOpen) {
      setOpen(false);
    }
  };

  useEffect(() => {
    getCountriesList(setCountriesList);
    getCitiesList(setCitiesList);
  }, []);

  useEffect(() => {
    setIsNotMiddleName(getValuesForm.hasNoMiddlename ?? false);
  }, [getValuesForm]);

  return (
    <StyledFormEditPersonalInformationWrapper>
      <form>
        <StyledFiedlsRow>
          <Controller
            control={control}
            name="firstname"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <TextField
                label={t("firstName")}
                variant="standard"
                onChange={onChange}
                value={value}
                InputProps={{
                  className: "textFieldForm",
                  style: {
                    color: mainTheme.main.colors.black,
                    fontSize: mainTheme.main.fontSizes.large,
                    fontWeight: mainTheme.main.fontWeights.normal,
                    fontFamily: mainTheme.main.fonts.dmsRegular,
                  },
                }}
                disabled
              />
            )}
          />

          <StyledFiedlsRowContent>
            <Controller
              control={control}
              name="middlename"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  label={t("middleName")}
                  variant="standard"
                  onChange={onChange}
                  value={value}
                  InputProps={{
                    className: "textFieldForm",
                    style: {
                      color: mainTheme.main.colors.black,
                      fontSize: mainTheme.main.fontSizes.large,
                      fontWeight: mainTheme.main.fontWeights.normal,
                      fontFamily: mainTheme.main.fonts.dmsRegular,
                    },
                  }}
                  disabled
                />
              )}
            />

            <StyledCheckboxFieldWrapper>
              <Controller
                control={control}
                name="hasNoMiddlename"
                render={({ field: { onChange, value } }) => (
                  <CustomCheckbox
                    value={value}
                    label={t("noMiddleName")}
                    setValue={onChange}
                    disabled
                  />
                )}
              />
            </StyledCheckboxFieldWrapper>

            {errors.middlename && (
              <StyledError className="middleName">
                {errors.middlename.message}
              </StyledError>
            )}
          </StyledFiedlsRowContent>

          <StyledFiedlsRowContent>
            <Controller
              control={control}
              name="lastname"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  label={t("lastName")}
                  variant="standard"
                  onChange={onChange}
                  value={value}
                  InputProps={{
                    className: "textFieldForm",
                    style: {
                      color: mainTheme.main.colors.black,
                      fontSize: mainTheme.main.fontSizes.large,
                      fontWeight: mainTheme.main.fontWeights.normal,
                      fontFamily: mainTheme.main.fonts.dmsRegular,
                    },
                  }}
                  disabled
                />
              )}
            />
            {errors.lastname && (
              <StyledError>{errors.lastname.message}</StyledError>
            )}
          </StyledFiedlsRowContent>
        </StyledFiedlsRow>

        <StyledFiedlsRow className="space">
          <Controller
            control={control}
            name="title"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <CustomSelect
                label={t("initials")}
                onChange={onChange}
                defaultValue=""
                variant={VariantCustomSelectEnum.standard}
              >
                {INITIALS.map((item) => (
                  <MenuItem value={item.value}>{item.label}</MenuItem>
                ))}
              </CustomSelect>
            )}
          />

          <StyledFiedlsRowContent>
            <Controller
              control={control}
              name="dob"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <FieldDatePicker
                  onChange={onChange}
                  value={value ? dayjs(value) : null}
                  label={t("dateOfBirth")}
                  disabled
                />
              )}
            />
            {errors.dob && <StyledError>{errors.dob.message}</StyledError>}
          </StyledFiedlsRowContent>

          <Controller
            control={control}
            name="countryOfResidence"
            render={({ field: { onChange, value } }) => {
              return (
                <div>
                  {!!countriesList?.length && (
                    <CustomAutocomplete
                      label={t("countryOfResidence")}
                      value={value}
                      onChange={onChange}
                      items={countriesList}
                      getOptionLabel={(option: {
                        code: string;
                        value: string;
                      }) => {
                        if (option?.hasOwnProperty("value")) {
                          return option.value;
                        }
                        return option;
                      }}
                      minSize={120}
                      variant="standard"
                      keyParams="value"
                    />
                  )}
                </div>
              );
            }}
          />
        </StyledFiedlsRow>

        <StyledFiedlsRow className="oneColumns">
          <Controller
            control={control}
            name="gender"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <CustomRadioGroup
                label={t("gender")}
                onChange={onChange}
                defaultValue={value}
              >
                {GENDER.map((item) => (
                  <FormControlLabel
                    key={item.value}
                    value={item.value}
                    control={<Radio checkedIcon={<IconCheckRadio />} />}
                    // @ts-ignore
                    label={t(item.label)}
                  />
                ))}
              </CustomRadioGroup>
            )}
          />
        </StyledFiedlsRow>

        <StyledFiedlsRow className="twoColumns">
          <Controller
            control={control}
            name="phone"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <TextField
                label={t("phone")}
                variant="standard"
                onChange={onChange}
                value={value}
                InputProps={{
                  className: "textFieldForm",
                  style: {
                    color: mainTheme.main.colors.black,
                    fontSize: mainTheme.main.fontSizes.large,
                    fontWeight: mainTheme.main.fontWeights.normal,
                    fontFamily: mainTheme.main.fonts.dmsRegular,
                  },
                }}
                disabled
              />
            )}
          />
          <Controller
            control={control}
            name="email"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <TextField
                label={t("email")}
                variant="standard"
                onChange={onChange}
                value={value}
                InputProps={{
                  className: "textFieldForm",
                  style: {
                    color: mainTheme.main.colors.black,
                    fontSize: mainTheme.main.fontSizes.large,
                    fontWeight: mainTheme.main.fontWeights.normal,
                    fontFamily: mainTheme.main.fonts.dmsRegular,
                  },
                }}
                disabled
              />
            )}
          />
        </StyledFiedlsRow>

        <StyledSectionFieldsTitle>
          {t("contactDetails")}
        </StyledSectionFieldsTitle>

        <StyledFiedlsRow className="oneColumns">
          <Controller
            control={control}
            name="address"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <TextField
                label={t("fullAddress")}
                variant="standard"
                onChange={onChange}
                value={value}
                InputProps={{
                  className: "textFieldForm",
                  style: {
                    color: mainTheme.main.colors.black,
                    fontSize: mainTheme.main.fontSizes.large,
                    fontWeight: mainTheme.main.fontWeights.normal,
                    fontFamily: mainTheme.main.fonts.dmsRegular,
                  },
                }}
              />
            )}
          />
        </StyledFiedlsRow>

        <StyledFiedlsRow className="twoColumns">
          <StyledFiedlsRowContent>
            <Controller
              control={control}
              name="country"
              render={({ field: { onChange, onBlur, value, ref } }) => {
                return (
                  <div>
                    {!!countriesList?.length && (
                      <CustomAutocomplete
                        label={t("country")}
                        value={value}
                        onChange={onChange}
                        items={countriesList}
                        getOptionLabel={(option: {
                          code: string;
                          value: string;
                        }) => {
                          if (option?.hasOwnProperty("value")) {
                            return option.value;
                          }
                          return option;
                        }}
                        minSize={120}
                        variant="standard"
                        keyParams="value"
                      />
                    )}
                  </div>
                );
              }}
            />

            {errors.country && (
              <StyledError>{errors.country.message}</StyledError>
            )}
          </StyledFiedlsRowContent>

          <Controller
            control={control}
            name="region"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <TextField
                label={t("countyState")}
                variant="standard"
                onChange={onChange}
                value={value}
                InputProps={{
                  className: "textFieldForm",
                  style: {
                    color: mainTheme.main.colors.black,
                    fontSize: mainTheme.main.fontSizes.large,
                    fontWeight: mainTheme.main.fontWeights.normal,
                    fontFamily: mainTheme.main.fonts.dmsRegular,
                  },
                }}
              />
            )}
          />
        </StyledFiedlsRow>

        <StyledFiedlsRow className="twoColumns">
          <Controller
            control={control}
            name="city"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <CustomSelect
                label={t("city")}
                onChange={onChange}
                // defaultValue="1"
                variant={VariantCustomSelectEnum.standard}
              >
                {citiesList?.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.value}
                  </MenuItem>
                ))}
              </CustomSelect>
            )}
          />

          <Controller
            control={control}
            name="zipCode"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <TextField
                label={t("postCodeZipCode")}
                variant="standard"
                onChange={onChange}
                value={value}
                InputProps={{
                  className: "textFieldForm",
                  style: {
                    color: mainTheme.main.colors.black,
                    fontSize: mainTheme.main.fontSizes.large,
                    fontWeight: mainTheme.main.fontWeights.normal,
                    fontFamily: mainTheme.main.fonts.dmsRegular,
                  },
                }}
              />
            )}
          />
        </StyledFiedlsRow>

        {errorUpdateUser && (
          <StyledErrorMessage>{errorUpdateUser}</StyledErrorMessage>
        )}

        <StyledButtonsWrapper>
          <StyledButtonCancel variant="contained" onClick={handleCancel}>
            {t("cancel")}
          </StyledButtonCancel>
          <StyledButtonSave
            type="submit"
            variant="contained"
            onClick={handleSubmit(onSubmit)}
          >
            {t("save")}
          </StyledButtonSave>
        </StyledButtonsWrapper>
      </form>
    </StyledFormEditPersonalInformationWrapper>
  );
};
