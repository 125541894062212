import { useTranslation } from "react-i18next";
import moment from "moment";
import {
  StyledBookingDetailsInfoWrapper,
  StyledBookingDetailsInfoRow,
  StyledBookingDetailsInfoColumn,
  StyledBookingDetailsInfoHeaderColumn,
  StyledBookingDetailsInfoColumnContent,
  StyledDateTimeColumn,
} from "./BookingDetailsInfoMobile.styles";
import { OrderType } from "../../appolo/graphql/queries/types";


type BookingDetailsInfoMobileProps = {
  booking: OrderType
}

export const BookingDetailsInfoMobile = ({ booking }: BookingDetailsInfoMobileProps) => {
  const { t } = useTranslation();

  const getNameTimeZone = booking?.createdAt?.replace(/[^a-zA-Z]+/g, "");

  return (
    <StyledBookingDetailsInfoWrapper>
      <>
        <StyledBookingDetailsInfoRow>
          <StyledBookingDetailsInfoColumn>
            <StyledBookingDetailsInfoHeaderColumn>
              {t("bookingCode")}
            </StyledBookingDetailsInfoHeaderColumn>
            <StyledBookingDetailsInfoColumnContent className="code">
              {booking?.number}
            </StyledBookingDetailsInfoColumnContent>

            <StyledBookingDetailsInfoColumnContent className="status">
              {booking?.status}
            </StyledBookingDetailsInfoColumnContent>
          </StyledBookingDetailsInfoColumn>
        </StyledBookingDetailsInfoRow>

        <StyledBookingDetailsInfoRow className="row">
          <StyledBookingDetailsInfoColumn>
            <StyledBookingDetailsInfoHeaderColumn>
              {t("bookedAt")}
            </StyledBookingDetailsInfoHeaderColumn>
            <StyledBookingDetailsInfoColumnContent>
              <StyledDateTimeColumn>
                {moment(booking?.createdAt, "YYYY-MM-DD hh:mm").format(`MMMM DD, YYYY`)}
              </StyledDateTimeColumn>

              <StyledDateTimeColumn>
                <span>
                  {moment(booking?.createdAt, "YYYY-MM-DD hh:mm").format("hh:mm a")}
                </span>
                <span>
                  {getNameTimeZone}
                </span>
                <span>
                  UTC ({moment(booking?.createdAt, "YYYY-MM-DD hh:mm").format(`Z`).slice(0, -3)})
                </span>
              </StyledDateTimeColumn>
            </StyledBookingDetailsInfoColumnContent>
          </StyledBookingDetailsInfoColumn>

          <StyledBookingDetailsInfoColumn className="last">
            <StyledBookingDetailsInfoHeaderColumn>
              {t("bookingTimeLimit")}
            </StyledBookingDetailsInfoHeaderColumn>
            <StyledBookingDetailsInfoColumnContent className="last timeLimit">
              <StyledDateTimeColumn>
              {t("until")} {moment(booking?.bookedTo, "YYYY-MM-DD hh:mm").format(`MMMM DD, YYYY`)}
              </StyledDateTimeColumn>

              <StyledDateTimeColumn>
                <span>
                  {moment(booking?.bookedTo, "YYYY-MM-DD hh:mm").format("hh:mm a")}
                </span>
                <span>
                  {getNameTimeZone}
                </span>
                <span>
                  UTC ({moment(booking?.bookedTo, "YYYY-MM-DD hh:mm").format(`Z`).slice(0, -3)})
                </span>
              </StyledDateTimeColumn>
            </StyledBookingDetailsInfoColumnContent>
          </StyledBookingDetailsInfoColumn>
        </StyledBookingDetailsInfoRow>
      </>
    </StyledBookingDetailsInfoWrapper>
  );
};
