/* eslint-disable */
// https://omakoleg.gitlab.io/posts/spa-image-base64-react/
import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import {
  StyledButtonCancel,
  StyledButtonSave,
  StyledControlWrapper,
  StyledDropzoneText,
  StyledDropZoneWrapper,
  StyledFileName,
  StyledImagePreview,
  StyledLabel,
  StyledMain,
} from "./DragAndDropImage.styles";
import { IconDownload } from "../../../../icons/IconDownload";

type EncodedFile = {
  name: string;
  size: number;
  encoded: string; // base64 encoded content
}

type ErrorFile = {
  name: string;
  size: number;
  error: string; // error description
}

type DragAndDropImageProps = {
  onClose?: (value: boolean) => void
}

export const DragAndDropImage = ({ onClose }: DragAndDropImageProps) => {
  const { t } = useTranslation();

  const [files, setFiles] = useState([]);
  const [encodedFiles, setEncodedFiles] = useState<EncodedFile[]>([]);
  const [errors, setErrors] = useState<ErrorFile[]>([]);

  const onDrop = useCallback((acceptedFiles: any) => {
    acceptedFiles.forEach((file: File) =>
      loadFile(file)
        .then((encFile) => setEncodedFiles((list) => [...list, encFile]))
        .catch((error) =>
          setErrors((list) => [
            ...list,
            {
              name: file.name,
              size: file.size,
              error,
            },
          ])
        )
    );

    setFiles(
      acceptedFiles.map((file: any) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  }, []);

  const loadFile = (file: File): Promise<EncodedFile> =>
    new Promise((res, rej) => {
      var reader = new FileReader();
      let base = {
        name: file.name,
        size: file.size,
      };
      reader.addEventListener("abort", (e) => rej(`File upload aborted:${e}`));
      reader.addEventListener("error", (e) => rej(`File upload error: ${e}`));
      reader.addEventListener(
        "load",
        () =>
          res({
            ...base,
            encoded: reader.result as string,
          }),
        false
      );
      reader.readAsDataURL(file);
    });

  const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
    useDropzone({
      onDrop,
      accept: {
        "image/jpeg": [],
        "image/png": [],
        "text/html": [".html", ".htm"],
      },
      maxSize: 1024 * 1024 * 5,
      maxFiles: 1,
      // getFilesFromEvent: (event: any) => myCustomFileGetter(event),
    });

  const fileList = files.map((file: any) => (
    <li key={file.name}>
      <StyledImagePreview src={file.preview} alt={file.name} />
      {/* <StyledFileName>{file.name}</StyledFileName> */}
    </li>
  ));

  const handleCancel = () => {
    if (onClose) {
      onClose(false);
    }
  }

  console.log("files: ", files);
  console.log("encodedFiles: ", encodedFiles); // base64
  console.log("errors: ", errors);

  return (
    <StyledMain>
      <StyledLabel>
        {t("addFile")}  
      </StyledLabel>
      <StyledDropZoneWrapper
        className={
          isDragActive ? "dropzoneStyle activeDropzone" : "dropzoneStyle"
        }
        {...getRootProps({
          onDrop: (event: { stopPropagation: () => any; target: any }) =>
            console.log(event.target.value),
        })}
      >
        <input {...getInputProps()} />
        {!files?.length && (
          <>
            <StyledDropzoneText>
              <IconDownload />
              <span>{t("dragDrop")}</span>
              <span>{t("or")}</span> 
              <span className="under">{t("browseFiles")}</span> 
            </StyledDropzoneText>
          </>
        )}
        <ul>{fileList}</ul>
      </StyledDropZoneWrapper>
      <StyledControlWrapper>
          <StyledButtonCancel 
            className={!files?.length ? "full" : ""}
            onClick={handleCancel}
          >
            {t("cancel")}
          </StyledButtonCancel>
          {!!files?.length && (
            <StyledButtonSave>
              {t("add")} 
            </StyledButtonSave>
          )}
        </StyledControlWrapper>
    </StyledMain>
  );
};
