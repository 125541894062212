import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import IconEdit from "../../icons/IconEdit";
import { IconLoylaty } from "../../icons/IconLoylaty";
import { IconVerifySuccess } from "../../icons/IconVerifySuccess";
import { IconVerifyError } from "../../icons/IconVerifyError";
import { IconDownloadDocuments } from "../../icons/IconDownloadDocuments";
import {
  StyledButtonDownload,
  StyledDetails,
  StyledDetailsWrapper,
  StyledGuestDataItem,
  StyledGuestDataItemContent,
  StyledGuestDataItemTitle,
  StyledGuestDataItemWrapper,
  StyledGuestDataWrapper,
  StyledGuestNumberButton,
  StyledSelectedGuestWrapper,
  StyledStateroomsCabinButton,
  StyledStateroomsCabinsWrapper,
  StyledStateroomsColumn,
  StyledStateroomsColumnContent,
  StyledStateroomsColumnEditButton,
  StyledStateroomsColumnEditWrapper,
  StyledStateroomsColumnsWrapper,
  StyledStateroomsColumnTitle,
  StyledStateroomsSectionTitle,
  StyledStateroomsWrapper,
} from "./StateroomsMobile.styles";
import { CurrentEditFormType } from "../../utils/globalTypes";
import { TypeFormEnum } from "../ModalFormEdit/types";
import { ModalFormEdit } from "../ModalFormEdit";
import {
  StyledButtonCancel,
  StyledButtonSave,
  StyledButtonsWrapper,
  StyledTextInfo,
} from "../Staterooms/Staterooms.styles";
import {
  OrderCabin,
  OrderCabinItemType,
  OrderCruiseType,
  OrderType,
  ReservationType,
} from "../../appolo/graphql/queries/types";

type StateroomsMobileProps = {
  booking: OrderType;
  handleClickCabinNumber: (cabinId: number) => void;
  orderCabin: OrderCabinItemType;
};

export const StateroomsMobile = ({
  booking,
  handleClickCabinNumber,
  orderCabin,
}: StateroomsMobileProps) => {
  const { t } = useTranslation();

  const [currentCabin, setCurrentCabin] = useState<OrderCabin>();

  const [currentEditForm, setCurrentEditForm] = useState<CurrentEditFormType>({
    title: "",
    typeEditForm: "",
  });

  const [currentPlace, setCurrentPlace] = useState<ReservationType>();

  // Редактировань данные путешественника
  const handleClickEdit = (value: { title: string; typeEditForm: string }) => {
    setCurrentEditForm(value);
  };

  // Каюта по-умолчанию
  useEffect(() => {
    if (booking) {
      const cabin = booking?.cruises[0]?.cabins[0];
      setCurrentCabin(cabin);
    }
  }, [booking]);

  // Путешественник по-умолчанию
  useEffect(() => {
    if (orderCabin && orderCabin.places?.length) {
      setCurrentPlace(orderCabin.places[0]);
    }
  }, [orderCabin]);

  // Выбор каюты
  const onClickCabinNumber = (cabin: OrderCabin) => {
    setCurrentCabin(cabin);
    handleClickCabinNumber(cabin.id);
  };

  // Выбор путешественника
  const onClickNumberPlace = (place: ReservationType) => {
    setCurrentPlace(place);
  };

  const formatAmountPrice = (amount: number) => {
    const price = amount
      ? (amount / 100)
          ?.toString()
          ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
      : null;
    return price ?? 0;
  };

  // const placesTariff = orderCabin.places;

  console.log("currentEditForm: ", currentEditForm);
  console.log("currentPlace: ", currentPlace);

  return (
    <StyledStateroomsWrapper>
      <StyledStateroomsSectionTitle>
        {t("staterooms")}
      </StyledStateroomsSectionTitle>

      {/* Секция с номер кают */}
      <StyledStateroomsCabinsWrapper>
        {booking?.cruises?.map((cruise: OrderCruiseType) => (
          <div>
            {cruise?.cabins.map((cabin: OrderCabin) => (
              <StyledStateroomsCabinButton
                className={
                  cabin?.number === currentCabin?.number ? "active" : ""
                }
                onClick={() => onClickCabinNumber(cabin)}
              >
                {cabin?.number}
              </StyledStateroomsCabinButton>
            ))}
          </div>
        ))}
      </StyledStateroomsCabinsWrapper>

      {/* Секция с информацией о выбраной каюте */}

      {/* Stateroom number / Deck */}
      <StyledStateroomsColumnsWrapper>
        <StyledStateroomsColumn>
          <StyledStateroomsColumnTitle>
            {t("stateRoomNumber")}
          </StyledStateroomsColumnTitle>
          <StyledStateroomsColumnContent>
            {orderCabin?.number}
          </StyledStateroomsColumnContent>
        </StyledStateroomsColumn>

        <StyledStateroomsColumn className="last">
          <StyledStateroomsColumnTitle>{t("deck")}</StyledStateroomsColumnTitle>
          <StyledStateroomsColumnContent>
            {orderCabin?.deck.name}
          </StyledStateroomsColumnContent>
        </StyledStateroomsColumn>
      </StyledStateroomsColumnsWrapper>

      {/* Category / Bed type */}
      <StyledStateroomsColumnsWrapper>
        <StyledStateroomsColumn>
          <StyledStateroomsColumnTitle>
            {t("category")}
          </StyledStateroomsColumnTitle>
          <StyledStateroomsColumnContent>
            {orderCabin?.class.name}
          </StyledStateroomsColumnContent>
        </StyledStateroomsColumn>

        <StyledStateroomsColumn className="last">
          <StyledStateroomsColumnEditWrapper>
            <StyledStateroomsColumnTitle>
              {t("bedType")}
            </StyledStateroomsColumnTitle>
            <StyledStateroomsColumnEditButton
              onClick={() =>
                setCurrentEditForm({
                  title: "Additional requirements",
                  typeEditForm: TypeFormEnum.bedType,
                })
              }
            >
              <IconEdit />
            </StyledStateroomsColumnEditButton>
          </StyledStateroomsColumnEditWrapper>

          <StyledStateroomsColumnContent>
            {orderCabin?.bedConfiguration?.name}
          </StyledStateroomsColumnContent>
        </StyledStateroomsColumn>
      </StyledStateroomsColumnsWrapper>

      <StyledStateroomsSectionTitle className="guest">
        {t("guest")}
      </StyledStateroomsSectionTitle>

      <StyledSelectedGuestWrapper>
        {orderCabin?.places?.map((place, index) => (
          <StyledGuestNumberButton onClick={() => onClickNumberPlace(place)}>
            {index + 1}
          </StyledGuestNumberButton>
        ))}
      </StyledSelectedGuestWrapper>

      {/* Данные о путешествениках */}
      <StyledGuestDataWrapper>
        {/* Full name */}
        <StyledGuestDataItemWrapper>
          <StyledGuestDataItem>
            <StyledGuestDataItemTitle>{t("fullName")}</StyledGuestDataItemTitle>
            <StyledGuestDataItemContent>
              <span>{currentPlace?.traveller?.personalInfo?.lastname}</span>
              <span>{currentPlace?.traveller?.personalInfo?.firstname}</span>
              <span>{currentPlace?.traveller?.personalInfo?.middlename}</span>
            </StyledGuestDataItemContent>
          </StyledGuestDataItem>

          <StyledGuestDataItem>
            <StyledStateroomsColumnEditButton
              onClick={() =>
                handleClickEdit({
                  title: "traveller",
                  typeEditForm: TypeFormEnum.traveller,
                })
              }
            >
              <IconEdit />
            </StyledStateroomsColumnEditButton>
          </StyledGuestDataItem>
        </StyledGuestDataItemWrapper>

        {/* Date of birth / Citizenship */}
        <StyledGuestDataItemWrapper>
          <StyledGuestDataItem>
            <StyledGuestDataItemTitle>
              {t("dateOfBirth")}
            </StyledGuestDataItemTitle>
            <StyledGuestDataItemContent>
              {currentPlace?.traveller?.personalInfo?.dob ?? "-"},
            </StyledGuestDataItemContent>
          </StyledGuestDataItem>

          <StyledGuestDataItem className="last">
            <StyledGuestDataItemTitle>
              {t("citizenship")}
            </StyledGuestDataItemTitle>
            <StyledGuestDataItemContent>
              {/* Нет данных о национальносьти от бека */}
              {"-"}
            </StyledGuestDataItemContent>
          </StyledGuestDataItem>
        </StyledGuestDataItemWrapper>

        {/* Passport / Loyalty number */}
        <StyledGuestDataItemWrapper>
          <StyledGuestDataItem>
            <StyledGuestDataItemTitle>{t("passport")}</StyledGuestDataItemTitle>
            <StyledGuestDataItemContent>
              <span>{currentPlace?.traveller?.document?.series ?? "-"}</span>
              <span>{currentPlace?.traveller?.document?.number ?? "-"}</span>
            </StyledGuestDataItemContent>
          </StyledGuestDataItem>

          <StyledGuestDataItem className="last">
            <StyledGuestDataItemTitle>
              {t("loyaltyNumber")}
            </StyledGuestDataItemTitle>

            {/* Не вижу на бекенде данных о карте лояльности */}
            <StyledGuestDataItemContent>
              <IconLoylaty />
              {"-"}
            </StyledGuestDataItemContent>
          </StyledGuestDataItem>
        </StyledGuestDataItemWrapper>

        {/* Phone / Email */}
        <StyledGuestDataItemWrapper>
          <StyledGuestDataItem>
            <StyledGuestDataItemTitle>{t("phone")}</StyledGuestDataItemTitle>
            <StyledGuestDataItemContent>
              <span>{currentPlace?.traveller?.contactInfo?.contactPhone?.code ?? "-"}</span>
              <span>{currentPlace?.traveller?.contactInfo?.contactPhone?.number ?? "-"}</span>
            </StyledGuestDataItemContent>
          </StyledGuestDataItem>

          <StyledGuestDataItem className="last">
            <StyledGuestDataItemTitle>{t("email")}</StyledGuestDataItemTitle>
            <StyledGuestDataItemContent>
              {currentPlace?.traveller?.contactInfo?.contactEmail ?? "-"}
            </StyledGuestDataItemContent>
          </StyledGuestDataItem>
        </StyledGuestDataItemWrapper>

        {/* Emergency contact */}
        <StyledGuestDataItemWrapper className="borderRow">
          <StyledGuestDataItem>
            <StyledGuestDataItemTitle>
              {t("emergencyContact")}
            </StyledGuestDataItemTitle>
          </StyledGuestDataItem>

          <StyledGuestDataItem>
            <StyledGuestDataItemContent>
              <StyledDetailsWrapper>
                {currentPlace?.traveller?.emergencyContact ? (
                  <div>{<IconVerifySuccess />}</div>
                ) : (
                  <div>{<IconVerifyError />}</div>
                )}
                <StyledDetails
                  onClick={() =>
                    handleClickEdit({
                      title: "Emergency contact details",
                      typeEditForm: TypeFormEnum.emergencyContact,
                    })
                  }
                >
                  {t("details")}
                </StyledDetails>
              </StyledDetailsWrapper>
            </StyledGuestDataItemContent>
          </StyledGuestDataItem>
        </StyledGuestDataItemWrapper>

        {/* Additional requirements */}
        <StyledGuestDataItemWrapper className="borderRow">
          <StyledGuestDataItem>
            <StyledGuestDataItemTitle>
              {t("additionalRequirements")}
            </StyledGuestDataItemTitle>
          </StyledGuestDataItem>

          <StyledGuestDataItem>
            <StyledGuestDataItemContent>
              <StyledDetailsWrapper>
                {/* {currentPlace?.traveller?.additionalRequirements ? (
                  <div>{<IconVerifySuccess />}</div>
                ) : (
                  <div>{<IconVerifyError />}</div>
                )} */}
                <StyledDetails
                  onClick={() =>
                    handleClickEdit({
                      title: "Additional requirements",
                      typeEditForm: TypeFormEnum.additionalRequirements,
                    })
                  }
                >
                  {t("details")}
                </StyledDetails>
              </StyledDetailsWrapper>
            </StyledGuestDataItemContent>
          </StyledGuestDataItem>
        </StyledGuestDataItemWrapper>

        {/* Boot Size EU */}
        <StyledGuestDataItemWrapper className="borderRow">
          <StyledGuestDataItem>
            <StyledGuestDataItemTitle>
              {t("bootSizeEU")}
            </StyledGuestDataItemTitle>
          </StyledGuestDataItem>

          <StyledGuestDataItem>
            {/* Не вижу на бекенде данных о обуви */}
            <StyledGuestDataItemContent>{"-"}</StyledGuestDataItemContent>
          </StyledGuestDataItem>
        </StyledGuestDataItemWrapper>

        {/* Parka size */}
        <StyledGuestDataItemWrapper className="borderRow">
          <StyledGuestDataItem>
            <StyledGuestDataItemTitle>
              {t("parkaSize")}
            </StyledGuestDataItemTitle>
          </StyledGuestDataItem>

          <StyledGuestDataItem>
            {/* Не вижу на бекенде данных о одежде */}
            <StyledGuestDataItemContent>{"-"}</StyledGuestDataItemContent>
          </StyledGuestDataItem>
        </StyledGuestDataItemWrapper>

        {/* Travel arrangement */}
        <StyledGuestDataItemWrapper className="borderRow">
          <StyledGuestDataItem>
            <StyledGuestDataItemTitle>
              {t("travelArrangement")}
            </StyledGuestDataItemTitle>
          </StyledGuestDataItem>

          <StyledGuestDataItem>
            <StyledGuestDataItemContent>
              <StyledDetailsWrapper>
                {/* Не вижу на бекенде данных о Traveler Arrangement статусе */}
                {false ? (
                  <div>{<IconVerifySuccess />}</div>
                ) : (
                  <div>{<IconVerifyError />}</div>
                )}
                <StyledDetails
                  onClick={() =>
                    handleClickEdit({
                      title:
                        "Details of travel arrangements booked independently of Swan Hellenic",
                      typeEditForm: TypeFormEnum.travelArrangement,
                    })
                  }
                >
                  {t("details")}
                </StyledDetails>
              </StyledDetailsWrapper>
            </StyledGuestDataItemContent>
          </StyledGuestDataItem>
        </StyledGuestDataItemWrapper>

        {/* Health declaration */}
        <StyledGuestDataItemWrapper className="borderRow">
          <StyledGuestDataItem>
            <StyledGuestDataItemTitle>
              {t("healthDeclaration")}
            </StyledGuestDataItemTitle>
          </StyledGuestDataItem>

          <StyledGuestDataItem>
            <StyledGuestDataItemContent>
              <StyledDetailsWrapper>
                {currentPlace?.traveller?.healthDeclaration ? (
                  <div>{<IconVerifySuccess />}</div>
                ) : (
                  <div>{<IconVerifyError />}</div>
                )}
                <StyledDetails
                  onClick={() =>
                    handleClickEdit({
                      title: "Health declaration",
                      typeEditForm: TypeFormEnum.healthDeclaration,
                    })
                  }
                >
                  {t("details")}
                </StyledDetails>
              </StyledDetailsWrapper>
            </StyledGuestDataItemContent>
          </StyledGuestDataItem>
        </StyledGuestDataItemWrapper>

        {/* Fare */}
        <StyledGuestDataItemWrapper className="borderRow">
          <StyledGuestDataItem>
            <StyledGuestDataItemTitle>{t("fare")}</StyledGuestDataItemTitle>
          </StyledGuestDataItem>

          <StyledGuestDataItem>
            <StyledGuestDataItemContent>{currentPlace?.tariff?.name}</StyledGuestDataItemContent>
          </StyledGuestDataItem>
        </StyledGuestDataItemWrapper>

        {/* Price */}
        <StyledGuestDataItemWrapper className="borderRow">
          <StyledGuestDataItem>
            <StyledGuestDataItemTitle>{t("price")}</StyledGuestDataItemTitle>
          </StyledGuestDataItem>

          <StyledGuestDataItem>
            <StyledGuestDataItemContent className="boldContent">
              <span>
                {formatAmountPrice(currentPlace?.priceTariff.amount ?? 0)}
              </span>
              <span>
                {currentPlace?.priceTariff?.currency}
              </span>
            </StyledGuestDataItemContent>
          </StyledGuestDataItem>
        </StyledGuestDataItemWrapper>

        {/* Travel documents */}
        <StyledGuestDataItemWrapper className="borderRow">
          <StyledGuestDataItem>
            <StyledGuestDataItemTitle>
              {t("travelDocuments")}
            </StyledGuestDataItemTitle>
          </StyledGuestDataItem>

          <StyledGuestDataItem>
            <StyledGuestDataItemContent>
              <StyledButtonDownload>
                <IconDownloadDocuments />
              </StyledButtonDownload>
            </StyledGuestDataItemContent>
          </StyledGuestDataItem>
        </StyledGuestDataItemWrapper>
      </StyledGuestDataWrapper>

      {/* Модальное окно в котором формы для редактирования данных */}
      <ModalFormEdit
        title={currentEditForm.title}
        currentEditForm={currentEditForm}
        setCurrentEditForm={(value) => setCurrentEditForm(value)}
      >
        {currentEditForm.typeEditForm === TypeFormEnum.healthDeclaration && (
          <div>
            <StyledTextInfo>
              You can submit your Medical Screening Form. You will be redirected
              to the submission page
            </StyledTextInfo>
            <StyledButtonsWrapper>
              <StyledButtonCancel>{t("cancel")}</StyledButtonCancel>
              <StyledButtonSave>
                <Link to={`https://vikandmedical.com/swan-hellenic-en/`} target="_blank">
                  {t("submit")}
                </Link>
              </StyledButtonSave>
            </StyledButtonsWrapper>
          </div>
        )}
      </ModalFormEdit>
    </StyledStateroomsWrapper>
  );
};
