import axios from "axios";
import { axiosInstance } from ".";

// Для регистрации / восстановления пароля / изменения пароля (нужно обращаться по данному url)
// url также зависит от стенда (папка env)
const mainUrlApiRegistration = process.env.REACT_APP_PUBLIC_API_URL;

console.log("mainUrlApiRegistration: ", mainUrlApiRegistration);

export const getCountriesList = async (setResponse: (value: any) => void) => {
  await axiosInstance
    .get(`https://api-crs-stage.swanhellenic.com/v4/references/countries`)
    .then(function (response) {
      setResponse(response?.data?.data);
      return response;
    })
    .catch((error) => {
      console.log("error: ", error);
    });
};

export const getCitiesList = async (setResponse: (value: any) => void)  => {
  await axiosInstance
    .get(`https://api-crs-stage.swanhellenic.com/v4/references/cities`)
    .then(function (response) {
      setResponse(response?.data);
      return response;
    })
    .catch((error) => {
      console.log("error: ", error);
    });
};

export const setRegistration = async (setResponse: (value: any) => void, dataForm: any, setErrorsRegistration: (value: string) => void)  => {
  await axios
    .post(`${mainUrlApiRegistration}/registration`, {
      ...dataForm
    })
    .then(function (response) {
      setErrorsRegistration("");
      setResponse(response?.data);
      console.log("response: ", response);
      return response;
    })
    .catch((error) => {
      setErrorsRegistration(error)
      console.log("error setRegistration: ", error);
    });
};