import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Box,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { t } from "i18next";
import { TableCellDocumentsProps, TableCellPriceProps, TableCellTravelerNumberProps, TableCellTravelerProps, TravelersTableProps, TravelersTableRowsProps } from "./types";
import { CommonTable } from "../CustomTable";
import { HEAD_CEALS } from "../Staterooms/consts";
import { IconVerifySuccess } from "../../icons/IconVerifySuccess";
import { IconVerifyError } from "../../icons/IconVerifyError";
import IconEdit from "../../icons/IconEdit";
import { IconLoylaty } from "../../icons/IconLoylaty";
import { IconStatusUser } from "../../icons/IconStatusUser";
import { IconPhone } from "../../icons/IconPhone";
import { IconEmail } from "../../icons/IconEmail";
import { IconDownloadDocuments } from "../../icons/IconDownloadDocuments";
import { TypeFormEnum } from "../ModalFormEdit/types";
import { ReservationType } from "../../appolo/graphql/queries/types";
import { CurrentEditFormType } from "../../utils/globalTypes";
import {
  StyledTableWrapper,
  StyledTravelerNumber,
  StyledTravelerMainDataFio,
  StyledTravelerMainData,
  StyledButtonEdit,
  StyledTravelerDocumentData,
  StyledTravelerDocumentDataItem,
  StyledTravelerDocumentDataText,
  StyledTravelerDocumentType,
  StyledTravelerDocumentColumn,
  StyledTravelerLoylatySection,
  StyledTravelerContactsWrapper,
  StyledNameSection,
  StyledContactPhone,
  StyledContactEmail,
  StyledStatusSection,
  StyledTravelerAdditionalWrapper,
  StyledAdditionalColumns,
  StyledAdditionalColumnItem,
  StyledAdditionalColumnTitle,
  StyledAdditionalColumnText,
  StyledTravelerArrangement,
  StyledPriceColumns,
  StyledPriceColumn,
  StyledPriceTitleColumn,
  StyledPriceText,
  StyledButtonDownloadWrapper,
  StyledButtonDownload,
  StyledTravelerNumberWrapper,
} from "./TravelersTable.styles";

type TableHeaderColumnProps = {
  onRequestSort: (event: any, property: any) => void;
  order: any;
  orderBy: string;
};

type HeadCellProps = {
  id: any;
  numeric: boolean;
  label: string;
};

const TableHeaderColumn = ({
  onRequestSort,
  order,
  orderBy,
}: TableHeaderColumnProps) => {
  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {HEAD_CEALS.map((headCell: HeadCellProps) => {
          return (
            <TableCell
              key={headCell.id}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {t(headCell.id)}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

const TableCellTravelerNumber = ({ index }: TableCellTravelerNumberProps) => {
  return (
    <StyledTravelerNumberWrapper>
      <TableCell>
        <StyledTravelerNumber>
          {index + 1}
        </StyledTravelerNumber>
      </TableCell>
    </StyledTravelerNumberWrapper>
  );
};

const TableCellTravelerMainData = ({ traveler, onClickEdit }: TableCellTravelerProps) => {
  // eslint-disable-next-line
  const { t } = useTranslation();

  return (
    <TableCell>
      <StyledTravelerMainData>
        <IconStatusUser />
        <StyledTravelerMainDataFio>
          <span>{traveler?.personalInfo?.lastname}</span>
          <span>{traveler?.personalInfo?.firstname}</span>
          <span>{traveler?.personalInfo?.middlename}</span> 
        </StyledTravelerMainDataFio>
        <StyledButtonEdit
          onClick={() =>
            onClickEdit({
              title: "traveller",
              typeEditForm: TypeFormEnum.traveller,
            })
          }
        >
          <IconEdit />
        </StyledButtonEdit>
      </StyledTravelerMainData>

      <StyledTravelerDocumentData>
        <StyledTravelerDocumentDataItem>
          <div>
            {/* Не вижу на бекенде флаг о статусе документов */}
            {false ? (
              <div>{<IconVerifySuccess />}</div>
            ) : (
              <div>{<IconVerifyError />}</div>
            )}
          </div>
          <StyledTravelerDocumentDataText>
            {traveler?.personalInfo?.dob ?? "-"},
          </StyledTravelerDocumentDataText>
          <StyledTravelerDocumentDataText>
            {/* Не вижу на бекенде данные о национальности */}
            {"-"}
          </StyledTravelerDocumentDataText>
        </StyledTravelerDocumentDataItem>

        <StyledTravelerDocumentColumn>
          <StyledTravelerDocumentType>
            {t("passport")}
          </StyledTravelerDocumentType>
          <StyledTravelerDocumentDataText>
            <span>
              {traveler?.document?.series ?? "-"} 
            </span>
            <span>
              {traveler?.document?.number ?? "-"}
            </span>
          </StyledTravelerDocumentDataText>
        </StyledTravelerDocumentColumn>

        <StyledTravelerLoylatySection>
          <IconLoylaty />
           {/* Не вижу на бекенде данных о карте лояльности */}
          <StyledTravelerDocumentDataText>
            {"-"}
          </StyledTravelerDocumentDataText>
        </StyledTravelerLoylatySection>
      </StyledTravelerDocumentData>
    </TableCell>
  );
};

const TableCellContacts = ({ traveler, onClickEdit }: TableCellTravelerProps) => {
  // eslint-disable-next-line
  const { t } = useTranslation();

  return (
    <TableCell>
      <StyledTravelerContactsWrapper>
        <StyledStatusSection>
          {traveler?.emergencyContact ? (
            <div>{<IconVerifySuccess />}</div>
          ) : (
            <div>{<IconVerifyError />}</div>
          )}
          <StyledNameSection
            onClick={() =>
              onClickEdit({
                title: "Emergency contact details",
                typeEditForm: TypeFormEnum.emergencyContact,
              })
            }
          >
            {t("emergencyContact")}
          </StyledNameSection>
        </StyledStatusSection>

        {/* Не вижу на бекенде данных о экстренных контактах */}
        <StyledContactPhone>
          <IconPhone />
          {"-"}
        </StyledContactPhone>
        {/* Не вижу на бекенде данных о экстренных контактах */}
        <StyledContactEmail>
          <IconEmail />
          {"-"}
        </StyledContactEmail>
      </StyledTravelerContactsWrapper>
    </TableCell>
  );
};

const TableCellAdditionalRequirements = ({ traveler, onClickEdit }: TableCellTravelerProps) => {
  // eslint-disable-next-line
  const { t } = useTranslation();

  return (
    <TableCell>
      <StyledTravelerAdditionalWrapper>
        <StyledStatusSection>
          {/* {traveler?.additionalRequirements ? (
            <div>{<IconVerifySuccess />}</div>
          ) : (
            <div>{<IconVerifyError />}</div>
          )} */}
          <StyledNameSection
            onClick={() =>
              onClickEdit({
                title: "Additional requirements",
                typeEditForm: TypeFormEnum.additionalRequirements,
              })
            }
          >
            {t("details")}
          </StyledNameSection>
        </StyledStatusSection>

        <StyledAdditionalColumns>
          <StyledAdditionalColumnItem>
            <StyledAdditionalColumnTitle>
              {t("bootSizeEU")}
            </StyledAdditionalColumnTitle>

            {/* Не вижу на бекенде данных о обуви */}
            <StyledAdditionalColumnText>
              {"-"}
            </StyledAdditionalColumnText>
          </StyledAdditionalColumnItem>

          <StyledAdditionalColumnItem>
            <StyledAdditionalColumnTitle>
              {t("parkaSize")}
            </StyledAdditionalColumnTitle>
            
            {/* Не вижу на бекенде данных о одежде */}
            <StyledAdditionalColumnText>
              {"-"}
            </StyledAdditionalColumnText>
          </StyledAdditionalColumnItem>
        </StyledAdditionalColumns>
      </StyledTravelerAdditionalWrapper>
    </TableCell>
  );
};

const TableCellTravelerArrangement = ({ traveler, onClickEdit }: TableCellTravelerProps) => {
  // eslint-disable-next-line
  const { t } = useTranslation();
  return (
    <TableCell>
      <StyledTravelerArrangement>
        <StyledStatusSection>
          {/* Не вижу на бекенде данных о Traveler Arrangement статусе */}
          {false ? (
            <div>{<IconVerifySuccess />}</div>
          ) : (
            <div>{<IconVerifyError />}</div>
          )}

          <StyledNameSection
            onClick={() =>
              onClickEdit({
                title: "Details of travel arrangements booked independently of Swan Hellenic",
                typeEditForm: TypeFormEnum.travelArrangement,
              })
            }
          >
            {t("details")}
          </StyledNameSection>
        </StyledStatusSection>
      </StyledTravelerArrangement>
    </TableCell>
  );
};

const TableCellHealthDeclaration = ({ traveler, onClickEdit }: TableCellTravelerProps) => {
  // eslint-disable-next-line
  const { t } = useTranslation();
  return (
    <TableCell>
      <StyledStatusSection>
        {traveler?.healthDeclaration ? (
          <div>{<IconVerifySuccess />}</div>
        ) : (
          <div>{<IconVerifyError />}</div>
        )}
        <StyledNameSection
          onClick={() =>
            onClickEdit({
              title: "Health declaration",
              typeEditForm: TypeFormEnum.healthDeclaration,
            })
          }
        >
          {t("details")}
        </StyledNameSection>
      </StyledStatusSection>
    </TableCell>
  );
};

const TableCellPrice = ({ price, placesTariff }: TableCellPriceProps) => {

  const amount = price?.amount && (price.amount / 100)?.toString()?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");

  return (
    <TableCell>
      <StyledPriceColumns>
        <StyledPriceColumn>

          <StyledPriceTitleColumn>
            {t("fare")} 
          </StyledPriceTitleColumn>
          <StyledPriceText>
            {placesTariff}
          </StyledPriceText>
        </StyledPriceColumn>

        <StyledPriceColumn>
          <StyledPriceTitleColumn>
            {t("price")}
          </StyledPriceTitleColumn>
          <StyledPriceText>
            <span>{amount}</span>
            <span>{price?.currency}</span>
          </StyledPriceText>
        </StyledPriceColumn>
      </StyledPriceColumns>
    </TableCell>
  );
};

const TableCellDocuments = ({ linkDonwloadDocument }: TableCellDocumentsProps) => {
  console.log("linkDonwloadDocument: ", linkDonwloadDocument);
  return (
    <TableCell>
      <StyledButtonDownloadWrapper>
        <StyledButtonDownload>
          <IconDownloadDocuments />
        </StyledButtonDownload>
      </StyledButtonDownloadWrapper>
    </TableCell>
  );
};

const TravelersTableRows = ({
  place,
  onClickRow,
  index,
  onClickEdit,
}: TravelersTableRowsProps) => {

  const handleClick = () => {
    onClickRow(place);
  };

  const traveller = place?.traveller;
  const price = place?.priceTariff;
  const placesTariff = place?.tariff?.name
  const linkDonwloadDocument = place?.boardingPassPdf;

  return (
    <TableRow onClick={handleClick}>
      <TableCellTravelerNumber 
        index={index} 
      />
      <TableCellTravelerMainData
        traveler={traveller}
        onClickEdit={(value: CurrentEditFormType) => onClickEdit(value)}
      />
      <TableCellContacts
        traveler={traveller}
        onClickEdit={(value: CurrentEditFormType) => onClickEdit(value)}
      />
      <TableCellAdditionalRequirements
        traveler={traveller}
        onClickEdit={(value: CurrentEditFormType) => onClickEdit(value)}
      />
      <TableCellTravelerArrangement
        traveler={traveller}
        onClickEdit={(value: CurrentEditFormType) => onClickEdit(value)}
      />
      <TableCellHealthDeclaration
        traveler={traveller}
        onClickEdit={(value: CurrentEditFormType) => onClickEdit(value)}
      />
      <TableCellPrice 
        price={price}
        placesTariff={placesTariff}
      />
      <TableCellDocuments 
        linkDonwloadDocument={linkDonwloadDocument} 
      />
    </TableRow>
  );
};

export const TravelersTable = ({ places, setCurrentEditForm }: TravelersTableProps) => {
  const [currentSelectedRowInTable, setCurrentSelectedRowInTable] = useState<ReservationType>();

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");

  const [currentEditColumn, setCurrentEditColumn] = useState({
    title: "",
    typeEditForm: "",
  });

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClickEdit = (value: any) => {
    setCurrentEditColumn(value);
    setCurrentEditForm(value);
  };

  console.log("currentSelectedRowInTable: ", currentSelectedRowInTable);
  console.log("currentEditColumn: ", currentEditColumn);

  return (
    <StyledTableWrapper>
      <CommonTable
        headerList={
          <TableHeaderColumn
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
        }
      >
        {places?.map((item: ReservationType, index: number) => (
          <TravelersTableRows
            key={item?.id}
            place={item}
            onClickRow={(value: ReservationType) => setCurrentSelectedRowInTable(value)}
            index={index}
            onClickEdit={(value: CurrentEditFormType) => handleClickEdit(value)}
          />
        ))}
      </CommonTable>
    </StyledTableWrapper>
  );
};
