import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { TextField } from "@mui/material";
import { mainTheme } from "../../../theme";
import { REQUEST_PASSWORD_CHANGE } from "../../../appolo/graphql/mutations/passwordChange";
import { ModalInfo } from "../../ModalInfo";
import {
  StyledWrapper,
  StyledButtonsWrapper,
  StyledButtonCancel,
  StyledButtonSave,
  StyledFieldWrapper,
  StyledError,
  StyledMessage,
  StyledModalRegistrationContent,
  StyledModalButtonsWrapper,
  StyledErrorMessage,
} from "./FormForgotPassword.styles";

type IFormForgotPasswordInput = {
  email: string;
};

export const FormForgotPassword = () => {
  const { t } = useTranslation();

  const [openModalSuccessPassword, setOpenModalSuccessPassword] = useState(false);
  const [emailSendLinkError, setEmailSendLinkError] = useState("")

  const navigate = useNavigate();

  const [changePassword] = useMutation(REQUEST_PASSWORD_CHANGE);

  const schema = yup.object().shape({
    email: yup.string().required(t("fieldRequired")),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<IFormForgotPasswordInput>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: IFormForgotPasswordInput) => {
    const formValue = {
      ...data,
    };
    console.log("formValue: ", formValue);

    changePassword({
      variables: {
        ...formValue
      },
      onCompleted: () => {
        setEmailSendLinkError("");
        setOpenModalSuccessPassword(true)
      },
      onError: (error) => {
        setEmailSendLinkError(error.message)
        setOpenModalSuccessPassword(false)
      }
    });
  };

  const handleCancel = () => {
    navigate("/")
  };

  return (
    <StyledWrapper>
      <StyledMessage>
        {t("haveForgottenYourPassword")}
      </StyledMessage>
      <form>
        <StyledFieldWrapper>
          <Controller
            control={control}
            name="email"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <TextField
                label={t("email")}
                variant="standard"
                onChange={onChange}
                value={value}
                InputProps={{
                  className: "textFieldForm",
                  style: {
                    color: mainTheme.main.colors.black,
                    fontSize: mainTheme.main.fontSizes.large,
                    fontWeight: mainTheme.main.fontWeights.normal,
                    fontFamily: mainTheme.main.fonts.dmsRegular,
                  },
                }}
              />
            )}
          />
          {errors.email && <StyledError>{errors.email.message}</StyledError>}
        </StyledFieldWrapper>

        <StyledButtonsWrapper>
          <StyledButtonCancel variant="contained" onClick={handleCancel}>
            {t("cancel")}
          </StyledButtonCancel>
          <StyledButtonSave
            type="submit"
            variant="contained"
            onClick={handleSubmit(onSubmit)}
          >
            {t("send")}
          </StyledButtonSave>
        </StyledButtonsWrapper>

        {emailSendLinkError && (
          <StyledErrorMessage>
            {emailSendLinkError}
          </StyledErrorMessage>
        )}
      </form>

      {/* Модальное окно "Password send email Success" */}
      <ModalInfo
        title=""
        open={openModalSuccessPassword}
        setOpen={(value: boolean) => setOpenModalSuccessPassword(value)}
      >
        <StyledModalRegistrationContent>
          <span>
            {t("newPasswordSendEmail")} 
          </span>
          <StyledModalButtonsWrapper>
            <StyledButtonSave         
              variant="contained"
              onClick={() => navigate("/")}
            >
              {t("close")}
            </StyledButtonSave>
          </StyledModalButtonsWrapper>
        </StyledModalRegistrationContent>
      </ModalInfo>
    </StyledWrapper>
  );
};
