import { gql } from '@apollo/client'

const ORDER_LIST = gql`
  query ORDER_LIST($page: Int, $perPage: Int, $context: Context, $filter: OrderListFilter) {
    orderList(page: $page, context: $context, perPage: $perPage, filter: $filter) {
      pagination {
        totalCount
        currentPage
        perPage
        pageCount
      }
      list {
        uuid
        number
        market
        source {
          id
          name
        }
        createdAt
        bookedTo
        bookedTimeLeft
        status
        paymentStatus
        refundStatus
        price {
          amount
          currency
        }
        currency
        companyComment
        managerComment
        cancellationConditions
        isCancelable
        isRefundable
        isPayable
        cruises {
          cruiseId
          position
          isGroupOrder
          motorshipName
          name
          motorshipId
          dateStartRaw
          dateEndRaw
          duration
          originPort {
            portId
            cityName
            portName
          }
          destinationPort {
            portId
            cityName
            portName
          }
          cabins {
            id
            number
            deck
            roomClass
          }
          reservationKind
          destinations {
            id
            name
          }
        }
        travellers {
          id
          reservations {
            cruiseId
            cabinId
            placeId
            room
            roomClass
            tariffName
          }
          firstname
          lastname
          middlename
          hasNoMiddlename
          gender
          dob
          contactPhone {
            code
            number
          }
          contactEmail
          nationality
          document {
            kind
            nationality
            series
            number
            issueDate
            expirationDate
            issuePlace
            issueCountry
            placeOfBirth
          }
        }
        paymentSchedule {
          dueDate
          actualDate
          amount {
            amount
            currency
          }
          isPaid
          isOverdue
        }
        refunds {
          number
          orderUuid
          id
          status
          compensation {
            amount
            currency
          }
          amountTotal {
            amount
            currency
          }
          amountOnline {
            amount
            currency
          }
          amountOffline {
            amount
            currency
          }
          isTotal
          penalty {
            amount
            currency
          }
          reason
          comment
          positions {
            position
            type
            name
            amount {
              amount
              currency
            }
            items
          }
          createdAt
        }
        payments {
          id
          orderUuid
          status
          amount {
            amount
            currency
          }
          method
          comment
          createdAt
        }
        owner {
          id
          firstname
          lastname
          middlename
          fullname
          hasNoMiddlename
          gender
          dob
          contactPhone {
            code
            number
          }
          contactEmail
          bonusCardNumber
          document {
            kind
            nationality
            series
            number
            issueDate
            expirationDate
            issuePlace
            issueCountry
            placeOfBirth
          }
          company {
            id
            name
            longName
            inn
            email
            phone {
              code
              number
            }
          }
        }
        responsible {
          id
          firstname
          lastname
          middlename
          fullname
          hasNoMiddlename
          gender
          dob
          contactPhone {
            code
            number
          }
          contactEmail
          bonusCardNumber
          document {
            kind
            nationality
            series
            number
            issueDate
            expirationDate
            issuePlace
            issueCountry
            placeOfBirth
          }
          company {
            id
            name
            longName
            inn
            email
            phone {
              code
              number
            }
          }
        }
        customer {
          id
          firstname
          lastname
          middlename
          fullname
          hasNoMiddlename
          gender
          dob
          contactPhone {
            code
            number
          }
          contactEmail
          bonusCardNumber
          document {
            kind
            nationality
            series
            number
            issueDate
            expirationDate
            issuePlace
            issueCountry
            placeOfBirth
          }
          company {
            id
            name
            longName
            inn
            email
            phone {
              code
              number
            }
          }
        }
        paymentsTotal
        paymentLink
        documents {
          link
          name
          kind
        }
      }
    }
  }
`

export { ORDER_LIST }
