import { useTranslation } from "react-i18next";
import { FormRegistration } from "../../components/Forms/FormRegistration"
import { StyledFormWrapper, StyledPageWrapper, StyledTitle } from "./Registration.styles";

export const Registration = () => {
  const { t } = useTranslation();
  return (
    <StyledPageWrapper>
      <StyledTitle>
        {t("registration")}
      </StyledTitle>

      <StyledFormWrapper>
        <FormRegistration />
      </StyledFormWrapper>
    </StyledPageWrapper>
  )
}