import styled from "styled-components";
import { Button } from "@mui/material";
import { mainTheme } from "../../../theme";

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  form {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0;
    gap: 20px;
  }

  .textFieldForm {
    width: 100%;    
  }

  .MuiInputLabel-root {
    font-size: ${mainTheme.main.fontSizes.large};
    font-weight: ${mainTheme.main.fontWeights.normal};
    color: ${mainTheme.main.colors.black} !important;
    font-family: ${mainTheme.main.fonts.dmsRegular};
    opacity: 0.5;
  }
`;

export const StyledButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  margin-top: 20px;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
`;

export const StyledButtonSave = styled(Button)`
  &.MuiButton-root {
    border-radius: 20px;
    color: ${mainTheme.main.colors.white};
    font-size: ${mainTheme.main.fontSizes.medium};
    font-weight: ${mainTheme.main.fontWeights.normal};
    background: ${mainTheme.main.colors.blue};
    border: 1px solid ${mainTheme.main.colors.blue};
    text-transform: none;

    &:hover {
      background: ${mainTheme.main.colors.blue};
    }

    @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
      width: 100%;
      padding: 9px 50px;
    }
  }
`;

export const StyledButtonCancel = styled(Button)`
  &.MuiButton-root {
    border-radius: 20px;
    color: ${mainTheme.main.colors.black};
    font-size: ${mainTheme.main.fontSizes.medium};
    font-weight: ${mainTheme.main.fontWeights.normal};
    background: ${mainTheme.main.colors.white};
    width: 138px;
    border: 1px solid ${mainTheme.main.colors.graySilver};
    box-shadow: none;
    text-transform: none;

    &:hover {
      background: ${mainTheme.main.colors.white};
    }

    @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
      width: 100%;
      padding: 9px 50px;
    }
  }
`;

export const StyledFiedlsRow = styled.div`
  display: grid;
  grid-template-columns: minmax(168px, auto) minmax(168px, auto) minmax(168px, auto);
  gap: 20px;
  grid-auto-flow: row;
  box-sizing: border-box;
  align-items: end;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    display: grid;
    grid-template-columns: minmax(auto, auto);
  }

  &.oneColumns {
    grid-template-columns: minmax(auto, auto);

    @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
      display: grid;
      grid-template-columns: minmax(auto, auto);
    }
  }

  &.twoColumns {
    grid-template-columns: minmax(260px, auto) minmax(260px, auto);

    @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
      display: grid;
      grid-template-columns: minmax(auto, auto);
    }
  }
`;

export const StyledSectionFieldsTitle = styled.div`
  color: ${mainTheme.main.colors.blue};
  font-size: ${mainTheme.main.fontSizes.large};
  font-weight: ${mainTheme.main.fontWeights.bold};
  font-family: ${mainTheme.main.fonts.chapMedium};
  margin-top: 16px;
`;

export const StyledTitle = styled.div`
  font-family: ${mainTheme.main.fonts.chapMedium};
  font-size: ${mainTheme.main.fontSizes.large};
  font-weight: ${mainTheme.main.fontWeights.bold};
  color: ${mainTheme.main.colors.blue};
`

export const StyledFieldWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const StyledError = styled.div`
  position: absolute;
  bottom: -25px;
  color: ${mainTheme.main.colors.red};
  font-size: ${mainTheme.main.fontSizes.xsmall};
  font-weight: ${mainTheme.main.fontWeights.normal};
`

export const StyledFormFieldsRow = styled.div`
  &.oneField {
    width: 100%;
    max-width: 320px;
  }

  &.twoFields {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 320px));
    gap: 20px; 
  }
`

export const StyledModalRegistrationContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  color: ${mainTheme.main.colors.blue};
  font-size: 45px;
  font-weight: ${mainTheme.main.fontWeights.bold};
  font-family: ${mainTheme.main.fonts.chapMedium};
`

export const StyledModalButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  box-sizing: border-box;
`

export const StyledErrorMessage = styled.div`
  margin-top: 12px;
  margin-bottom: 12px;
  color: ${mainTheme.main.colors.red};
  font-size: ${mainTheme.main.fontSizes.xsmall};
  font-weight: ${mainTheme.main.fontWeights.normal};
`