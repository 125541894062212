import { useEffect } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { AdditionalServices } from "../../components/AdditionalServices";
import { AdditionalServicesMobile } from "../../components/AdditionalServicesMobile";
import { BookingDetailsInfo } from "../../components/BookingDetailsInfo";
import { BookingDetailsInfoMobile } from "../../components/BookingDetailsInfoMobile";
import { Comments } from "../../components/Comments";
import { Cruise } from "../../components/Cruise";
import { CruiseMobile } from "../../components/CruiseMobile";
import { Customer } from "../../components/Customer";
import { CustomerMobile } from "../../components/CustomerMobile";
import { DocumentsSection } from "../../components/DocumentsSection";
import { PaymentSchedule } from "../../components/PaymentSchedule";
import { PolicyRemark } from "../../components/PolicyRemark";
import { PriceDetails } from "../../components/PriceDetails";
import { Staterooms } from "../../components/Staterooms";
import { StateroomsMobile } from "../../components/StateroomsMobile";
import { ORDER_INFO } from "../../appolo/graphql/queries/order";
import { CRUISE_ROUTE } from "../../appolo/graphql/queries/cruiseRoute";
import {
  CruiseRouteItemType,
  CruiseRouteType,
  OrderCabinItemType,
  OrderCabinServiceType,
  OrderCabinType,
  OrderInfoType,
  OrderPriceType,
  OrderType,
  ReservationType,
} from "../../appolo/graphql/queries/types";
import { ORDER_CABIN } from "../../appolo/graphql/queries/orderCabin";
import { PaymentScheduleType } from "../../utils/globalTypes";
import {
  StyledWrapperBookingDetails,
  StyledWrapperBookingDetailsColumn,
  StyledWrapperBookingDetailsColumnMobile,
} from "./BookingDetails.styles";
import { AddiatinalServisexEnum, NameGroup } from "./types";

export const BookingDetails = () => {
  const params = useParams();

  const {
    data: orderInfoData,
    loading,
    refetch,
  } = useQuery<OrderInfoType>(ORDER_INFO, {
    variables: {
      uuid: params?.id,
      context: {
        market: "ru",
      },
    },
  });

  // Для таблицы в модальном окне Route в разделе Cruise
  const [getRouteCruise, { data: cruiseRouteData }] =
    useLazyQuery<CruiseRouteType>(CRUISE_ROUTE);

  const order = orderInfoData?.order;

  const handleGetRouteCruise = (id: number) => {
    getRouteCruise({
      fetchPolicy: "network-only",
      variables: {
        cruiseId: id,
        context: {
          market: "ru",
        },
      },
    });
  };

  // Данные по каюте
  const [getOrderCabinData, { data: orderCabinData }] =
    useLazyQuery<OrderCabinType>(ORDER_CABIN);

  // Для получения данных о каюте по-умолчанию  (раздел Staterooms)
  useEffect(() => {
    if (order && order?.cruises?.length) {
      getOrderCabinData({
        fetchPolicy: "network-only",
        variables: {
          uuid: params?.id,
          // uuid: "3d58c30c-abf1-4dd1-8c27-99b60ffd655a", // тут есть Additional services
          position: order?.cruises[0].position, // Приходит массив cruises не совсем понятно, что делать если будет несколько
          cabinId: order?.cruises[0]?.cabins[0]?.id ?? 0,
          // cabinId: 15669, // тут есть Additional services
          context: {
            market: "ru",
          },
        },
      });
    }
  }, [orderInfoData]);

  // Выбор каюты в разделе Staterooms
  const handleClickCabinNumber = (cabinId: number) => {
    getOrderCabinData({
      fetchPolicy: "network-only",
      variables: {
        uuid: params?.id,
        // uuid: "3d58c30c-abf1-4dd1-8c27-99b60ffd655a",
        position: order?.cruises[0].position, // Приходит массив cruises не совсем понятно, что делать если будет несколько
        cabinId,
        // cabinId: 15669, // тут есть Additional services
        context: {
          market: "ru",
        },
      },
    });
  };

  const services = orderCabinData?.orderCabin.services;
  const places = orderCabinData?.orderCabin?.places;

  // Так бекенд не отдает имена категорий
  // value должно совпадать с параметром period_of_service_provision
  // значение value для каждой категории задал Александр Моноскин
  const aditionalServicesCategory: any = {
    PRE: {
      name: AddiatinalServisexEnum.preCruise,
      value: 1,
    },
    ONBOARD: {
      name: AddiatinalServisexEnum.onBoard,
      value: 2,
    },
    POST: {
      name: AddiatinalServisexEnum.postCruise,
      value: 3,
    },
    EXCURSIONS: {
      name: AddiatinalServisexEnum.excursions,
      value: 4,
    },
  };

  const arrAditionalServicesCategory = Object.values(aditionalServicesCategory);

  // Группировка массива "Additional services"
  const groupByKeyServices = (array: any, key: any) => {
    return array?.reduce((hash: any, obj: any) => {
      return Object.assign(hash, {
        [aditionalServicesCategory?.[obj[key]]?.name]: (
          hash[aditionalServicesCategory?.[obj[key]]?.name] || []
        ).concat(obj),
      });
    }, []);
  };

  // category -> это ключ по которому происходит группировка
  const groupAdditionalServices = services
    ? groupByKeyServices(services, "category")
    : {};

  return (
    <StyledWrapperBookingDetails>
      {/* Только desktop */}
      <StyledWrapperBookingDetailsColumn>
        <BookingDetailsInfo booking={order as OrderType} />
        <Customer booking={order as OrderType} />
        <Cruise
          booking={order as OrderType}
          handleGetRouteCruise={(value: number) => handleGetRouteCruise(value)}
          cruiseRoute={cruiseRouteData?.cruiseRoute as CruiseRouteItemType[]}
        />
        <Staterooms
          booking={order as OrderType}
          handleClickCabinNumber={(cabinId: number) =>
            handleClickCabinNumber(cabinId)
          }
          orderCabin={orderCabinData?.orderCabin as OrderCabinItemType}
        />
        <AdditionalServices
          nameGroups={arrAditionalServicesCategory as NameGroup[]}
          services={groupAdditionalServices}
          places={places as ReservationType[]}
        />
        <PolicyRemark
          services={
            orderCabinData?.orderCabin.services as OrderCabinServiceType[]
          }
        />
        <Comments />
      </StyledWrapperBookingDetailsColumn>

      {/* Только desktop */}
      <StyledWrapperBookingDetailsColumn>
        <PriceDetails
          priceData={order?.price as OrderPriceType}
          uuidOrder={orderInfoData?.order?.uuid as string}
          isCancelable={orderInfoData?.order.isCancelable as boolean}
          isRefundable={orderInfoData?.order.isRefundable as boolean}
          isPayable={orderInfoData?.order.isPayable as boolean}
        />
        <PaymentSchedule
          payments={order?.paymentSchedule as PaymentScheduleType[]}
        />
        <DocumentsSection />
      </StyledWrapperBookingDetailsColumn>

      {/* Только mobile */}
      <StyledWrapperBookingDetailsColumnMobile>
        <BookingDetailsInfoMobile booking={order as OrderType} />
        <CruiseMobile booking={order as OrderType} />
        <PriceDetails
          priceData={order?.price as OrderPriceType}
          uuidOrder={orderInfoData?.order?.uuid as string}
          isCancelable={orderInfoData?.order.isCancelable as boolean}
          isRefundable={orderInfoData?.order.isRefundable as boolean}
          isPayable={orderInfoData?.order.isPayable as boolean}
        />
        <CustomerMobile booking={order as OrderType} />
        <StateroomsMobile
          booking={order as OrderType}
          handleClickCabinNumber={(cabinId: number) =>
            handleClickCabinNumber(cabinId)
          }
          orderCabin={orderCabinData?.orderCabin as OrderCabinItemType}
        />
  
        <AdditionalServicesMobile
           nameGroups={arrAditionalServicesCategory as NameGroup[]}
           services={groupAdditionalServices}
           places={places as ReservationType[]}
        />
        <PolicyRemark
          services={
            orderCabinData?.orderCabin.services as OrderCabinServiceType[]
          }
        />
        <Comments />
        <PaymentSchedule
          payments={order?.paymentSchedule as PaymentScheduleType[]}
        />
        <DocumentsSection />
      </StyledWrapperBookingDetailsColumnMobile>
    </StyledWrapperBookingDetails>
  );
};
