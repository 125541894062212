import { useTranslation } from "react-i18next";
import moment from "moment";
import { IconLoylaty } from "../../icons/IconLoylaty";
import { OrderCruiseType, OrderType } from "../../appolo/graphql/queries/types";
import {
  StyledCruiseColumn,
  StyledCruiseColumnContent,
  StyledCruiseColumnWrapper,
  StyledCruiseHeaderColumn,
  StyledCruiseItem,
  StyledCruiseRow,
  StyledCruiseWrapper,
  StyledDash,
  StyledDateTime,
  StyledNameShip,
  StyledRouteDate,
  StyledRouteLink,
  StyledRouteName,
  StyledRoutePort,
  StyledRoutesColumn,
  StyledRoutesRow,
  StyledSectionTitle,
} from "./CruiseMobile.styles";

type CruiseMobileProps = {
  booking: OrderType
}

export const CruiseMobile = ({ booking }: CruiseMobileProps) => {
  const { t } = useTranslation();

  // Временно (пока даты приходят в секундах)
  const formatDateTime = (seconds: number) => {
    const date = new Date(seconds * 1000);
  
    const formatDate = moment(date, "YYYY-MM-DD hh:mm").format(`YYYY-MM-DD`);

    const getNameTimeZone = formatDate?.replace(/[^a-zA-Z]+/g, "");

    const dateTime = moment(formatDate, "YYYY-MM-DD hh:mm").format(`ddd, MMMM DD, YYYY`);
    const time = moment(formatDate, "YYYY-MM-DD hh:mm").format("hh:mm a");
    const zone =  moment(formatDate, "YYYY-MM-DD hh:mm").format(`Z`).slice(0, -3);

    return (
      <StyledDateTime>
        <span>{dateTime}</span>
        <span>{time}</span>
        <span>{getNameTimeZone}</span>
        <span>(UTC {zone})</span>
      </StyledDateTime>
    )
  };

  return (
    <StyledCruiseWrapper>
      <StyledSectionTitle>
        {t("cruise")} 
      </StyledSectionTitle>
      {booking?.cruises?.map((cruise: OrderCruiseType) => (
        <StyledCruiseItem key={cruise.cruiseId}>
          <StyledCruiseRow>
            <StyledCruiseColumn>
              <StyledCruiseHeaderColumn>
                {t("cruiseCode")}
              </StyledCruiseHeaderColumn>
              <StyledCruiseColumnContent>
                {cruise?.cruiseId}
              </StyledCruiseColumnContent>
            </StyledCruiseColumn>

            <StyledCruiseColumn className="last">
              <StyledCruiseHeaderColumn>
                {t("ship")}
              </StyledCruiseHeaderColumn>
              <StyledCruiseColumnContent className="ship">
                <IconLoylaty />
                <StyledNameShip>
                  {cruise?.motorshipName}
                </StyledNameShip>
              </StyledCruiseColumnContent>
            </StyledCruiseColumn>
          </StyledCruiseRow>

          <StyledCruiseRow>
            <StyledCruiseColumn>
              <StyledCruiseHeaderColumn>
                {t("cruiseName")}
              </StyledCruiseHeaderColumn>
              <StyledCruiseColumnContent>
                {cruise.name ?? "-"}
              </StyledCruiseColumnContent>
            </StyledCruiseColumn>

            <StyledCruiseColumn className="last">
              <StyledCruiseHeaderColumn>
                {t("boardingTime")}
              </StyledCruiseHeaderColumn>

              {/* Время посадки не увидел данные от бекенда  */}
              <StyledCruiseColumnContent className="last">
                {"-"}
              </StyledCruiseColumnContent>
            </StyledCruiseColumn>
          </StyledCruiseRow>

          <StyledRoutesRow>
            <StyledRoutesColumn>
              {/* Сейчас приходит число вместо даты (нужна дата) */}
              <StyledRouteDate>
                {formatDateTime(cruise?.dateStartRaw)}
              </StyledRouteDate>
            </StyledRoutesColumn>

            <StyledRoutesColumn className="last">
              {/* Сейчас приходит число вместо даты (нужна дата) */}
              <StyledRouteDate>
                {formatDateTime(cruise?.dateEndRaw)}
              </StyledRouteDate>
            </StyledRoutesColumn>
          </StyledRoutesRow>

          <StyledDash />

          <StyledRoutesRow>
            {/* Сейчас приходит число вместо даты (нужна дата) */}
            <StyledRouteDate>
              {"-"}
            </StyledRouteDate>
            <StyledRouteDate>
              {"-"}
            </StyledRouteDate>
          </StyledRoutesRow>

          <StyledRoutesRow>
            <StyledRouteName>
              {cruise.originPort.cityName}
            </StyledRouteName>
            <StyledRouteName>
              {cruise.destinationPort.cityName}
            </StyledRouteName>
          </StyledRoutesRow>
        </StyledCruiseItem>
      ))}      
    </StyledCruiseWrapper>
  );
};
