import styled from 'styled-components'
import { Button } from '@mui/material'
import { mainTheme } from "../../theme"

export const StyledPriceDetailsWrapper = styled.div`
  background: ${mainTheme.main.colors.yellow};
  padding: 12px;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    margin-top: 12px;
  }
`

export const StyledPriceDetailsDescriptions = styled.div`
  background: ${mainTheme.main.colors.white};
  padding: 12px;
  font-size: ${mainTheme.main.fontSizes.xsmall};
  font-weight: ${mainTheme.main.fontWeights.normal};
  color: ${mainTheme.main.colors.blue};
  font-family: ${mainTheme.main.fonts.dmsRegular};
`

export const AccordionWrapper = styled.div`
  .MuiPaper-root {
    background: ${mainTheme.main.colors.yellow};

    box-shadow: none;
    border: none;
  }

  .MuiAccordionSummary-root {
    min-height: auto !important;
    height: 40px;
    margin-top: 14px;
  }

  .MuiAccordionSummary-content {
    div {
      font-size: ${mainTheme.main.fontSizes.large};
      font-weight: ${mainTheme.main.fontWeights.boldExtra};
      color: ${mainTheme.main.colors.black};
      font-family: ${mainTheme.main.fonts.chapRegular};
    }
  }

  .MuiButtonBase-root {
    padding-left: 0;
    padding-right: 0;
    box-shadow: none;
    border: none;
  }

  .MuiAccordionDetails-root {
    padding: 0;
  } 
`

export const StyledAccordionContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const StyledPriceDetailsItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  box-sizing: border-box;
  flex-wrap: wrap;
  width: 100%;
`

export const StyledCategory = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
`

export const StyledCategoryRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 100%;
  box-sizing: border-box;
`

export const StyledNameCategory = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  font-size: ${mainTheme.main.fontSizes.medium};
  font-weight: ${mainTheme.main.fontWeights.normal};
  color: ${mainTheme.main.colors.black};
  font-family: ${mainTheme.main.fonts.dmsRegular};
`

export const StyledPriceCategory = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 4px;
  width: 100%;
  font-size: ${mainTheme.main.fontSizes.medium};
  font-weight: ${mainTheme.main.fontWeights.normal};
  color: ${mainTheme.main.colors.black};
  font-family: ${mainTheme.main.fonts.dmsRegular};

  div {
    display: flex;
    gap: 4px;
  }
`

export const StyledSubCategoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  padding-left: 12px;
  font-size: ${mainTheme.main.fontSizes.medium};
  font-weight: ${mainTheme.main.fontWeights.normal};
  color: ${mainTheme.main.colors.black};
  font-family: ${mainTheme.main.fonts.dmsRegular};
`


export const StyledSubCategoryItem = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: ${mainTheme.main.fontSizes.medium};
  font-weight: ${mainTheme.main.fontWeights.normal};
  color: ${mainTheme.main.colors.black};
  font-family: ${mainTheme.main.fonts.dmsRegular};
  margin-top: 4px;
`

export const StyledSubCategoryName = styled.div`
  color: ${mainTheme.main.colors.white};
  font-size: ${mainTheme.main.fontSizes.medium};
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.dmsRegular};
  display: flex;
  flex-direction: row;
  gap: 5px;
`

export const StyledType = styled.div`
  opacity: 0.6;
`

export const StyledSubCategoryPrice = styled.div`
  color: ${mainTheme.main.colors.white};
  font-size: ${mainTheme.main.fontSizes.medium};
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.dmsRegular};
`

export const StyledPriceDetailsPriceTotal = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: ${mainTheme.main.fontSizes.medium};
  font-weight: ${mainTheme.main.fontWeights.normal};
  color: ${mainTheme.main.colors.black};
  font-family: ${mainTheme.main.fonts.dmsRegular};
`

export const StyledPriceDetailsPriceTotalName = styled.div`
  font-size: ${mainTheme.main.fontSizes.medium};
  font-weight: ${mainTheme.main.fontWeights.boldExtra};
  color: ${mainTheme.main.colors.black};
  font-family: ${mainTheme.main.fonts.dmsRegular};
`

export const StyledPriceDetailsPriceTotalAmount = styled.div`
  font-size: ${mainTheme.main.fontSizes.medium};
  font-weight: ${mainTheme.main.fontWeights.boldExtra};
  color: ${mainTheme.main.colors.black};
  font-family: ${mainTheme.main.fonts.dmsRegular};
  display: flex;
  flex-direction: row;
  gap: 4px;
`

export const StyledModalButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  box-sizing: border-box;
  gap: 20px;
`

export const StyledErrorMessage = styled.div`
  margin-top: 12px;
  margin-bottom: 12px;
  color: ${mainTheme.main.colors.red};
  font-size: ${mainTheme.main.fontSizes.xsmall};
  font-weight: ${mainTheme.main.fontWeights.normal};
`

export const StyledButtonSave = styled(Button)`
  &.MuiButton-root {
    border-radius: 24px;
    color: ${mainTheme.main.colors.white};
    font-size: ${mainTheme.main.fontSizes.large};
    font-weight: ${mainTheme.main.fontWeights.normal};
    font-family: ${mainTheme.main.fonts.dmsRegular};
    background: ${mainTheme.main.colors.blue};
    width: 153px;
    border: 1px solid ${mainTheme.main.colors.blue};
    text-transform: capitalize;
    box-shadow: none;
    padding: 4px;

    &:hover {
      background: ${mainTheme.main.colors.blue};
    }

    @media (max-width: ${mainTheme.main.breakPoints.mobile}){
      width: 100%;
    }
  }
`;

export const StyledButtonsControlWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin-top: 24px;
`

export const StyledButtonControlBooking = styled(Button)`
  &.pay {
    &.MuiButton-root {
      background: ${mainTheme.main.colors.blue};
      color: ${mainTheme.main.colors.white};
    }
  }

  &.MuiButton-root {
    border-radius: 24px;
    color: ${mainTheme.main.colors.blue};
    font-size: ${mainTheme.main.fontSizes.medium};
    font-weight: ${mainTheme.main.fontWeights.normal};
    font-family: ${mainTheme.main.fonts.dmsRegular};
    background: ${mainTheme.main.colors.white};
    width: 276px;
    border: 1px solid ${mainTheme.main.colors.blue};
    text-transform: none;
    box-shadow: none;
    padding: 2px 20px;

    &:hover {
      background: ${mainTheme.main.colors.white};
    }

    @media (max-width: ${mainTheme.main.breakPoints.mobile}){
      width: 100%;
    }
  }
`;

export const StyledModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-sizing: border-box;
`