import { useTranslation } from "react-i18next";
import { FormForgotPassword } from "../../components/Forms/FormForgotPassword";
import { StyledPageWrapper, StyledTitle } from "./ForgotPassword.styles";

export const ForgotPassword = () => {
  const { t } = useTranslation();
  return (
    <StyledPageWrapper>
      <StyledTitle>
        {t("forgotPassword")}
      </StyledTitle>
      <FormForgotPassword />
    </StyledPageWrapper>
  );
};
