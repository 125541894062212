import { useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { IconLoylaty } from "../../icons/IconLoylaty";
import { ModalInfo } from "../ModalInfo";
import { CruisesTable } from "../CruisesTable";
import { CruiseRouteItemType, OrderCruiseType, OrderType } from "../../appolo/graphql/queries/types";
import {
  StyledCruiseColumn,
  StyledCruiseColumnContent,
  StyledCruiseColumns,
  StyledCruiseColumnWrapper,
  StyledCruiseHeaderColumn,
  StyledCruiseWrapper,
  StyledDateTime,
  StyledNameShip,
  StyledRouteDate,
  StyledRouteItem,
  StyledRouteLink,
  StyledRouteName,
  StyledRoutePort,
  StyledRoutesColumns,
  StyledSectionTitle,
} from "./Cruise.styles";

type CruiseProps = {
  booking: OrderType
  handleGetRouteCruise: (value: number) => void
  cruiseRoute: CruiseRouteItemType[]
}

export const Cruise = ({ booking, handleGetRouteCruise, cruiseRoute }: CruiseProps) => {
  const { t } = useTranslation();

  const [openModalCruise, setOpenModalCruise] = useState(false);

  const handleClickRoute = (id: number) => {
    handleGetRouteCruise(id);
    setOpenModalCruise(true)
  }

  // Временно (пока даты приходят в секундах)
  const formatDateTime = (seconds: number) => {
    const date = new Date(seconds * 1000);
 
    const formatDate = moment(date, "YYYY-MM-DD hh:mm").format(`YYYY-MM-DD`);

    const getNameTimeZone = formatDate?.replace(/[^a-zA-Z]+/g, "");

    const dateTime = moment(formatDate, "YYYY-MM-DD hh:mm").format(`dddd, MMMM DD, YYYY`);
    const time = moment(formatDate, "YYYY-MM-DD hh:mm").format("hh:mm a");
    const zone =  moment(formatDate, "YYYY-MM-DD hh:mm").format(`Z`).slice(0, -3);

    return (
      <StyledDateTime>
        <span>{dateTime}</span>
        <span>{time}</span>
        <span>{getNameTimeZone}</span>
        <span>(UTC {zone})</span>
      </StyledDateTime>
    )
  };

  return (
    <StyledCruiseWrapper>
      <StyledSectionTitle>
        {t("cruise")}   
      </StyledSectionTitle>
      {booking?.cruises?.map((cruise: OrderCruiseType) => (
        <StyledCruiseColumns key={cruise.cruiseId}>
          <StyledCruiseColumnWrapper>
            <StyledCruiseColumn>
              <StyledCruiseHeaderColumn>
                {t("cruiseCode")}
              </StyledCruiseHeaderColumn>

              <StyledCruiseColumnContent>
                {cruise?.cruiseId}
              </StyledCruiseColumnContent>
            </StyledCruiseColumn>

            <StyledCruiseColumn>
              <StyledCruiseHeaderColumn>
                {t("cruiseName")}
              </StyledCruiseHeaderColumn>
              <StyledCruiseColumnContent>
                {cruise.name ?? "-"}
              </StyledCruiseColumnContent>
            </StyledCruiseColumn>
          </StyledCruiseColumnWrapper>

          <StyledCruiseColumnWrapper>
            <StyledCruiseColumn>
              <StyledCruiseHeaderColumn>
                {t("ship")}
              </StyledCruiseHeaderColumn>
              <StyledCruiseColumnContent className="ship">
                <IconLoylaty /> 
                <StyledNameShip>
                  {cruise.motorshipName}
                </StyledNameShip>
              </StyledCruiseColumnContent>
            </StyledCruiseColumn>

            <StyledCruiseColumn>
              <StyledCruiseHeaderColumn>
                {t("boardingTime")}
              </StyledCruiseHeaderColumn>

              {/* Время посадки не увидел данные от бекенда  */}
              <StyledCruiseColumnContent>
                {"-"}
              </StyledCruiseColumnContent>
            </StyledCruiseColumn>
          </StyledCruiseColumnWrapper>

          <StyledRoutesColumns>
            <StyledRouteItem>
              <StyledRouteName>
                {cruise.originPort.cityName}
              </StyledRouteName>

              {/* Сейчас приходит число вместо даты (нужна дата) */}
              <StyledRouteDate>
                {formatDateTime(cruise?.dateStartRaw)}
              </StyledRouteDate>

              <StyledRoutePort>
                {cruise.originPort.portName}
              </StyledRoutePort>
            </StyledRouteItem>

            <StyledRouteLink
              onClick={() => handleClickRoute(cruise.cruiseId)}
            >
              {t("route")}
            </StyledRouteLink>

            <StyledRouteItem>
              <StyledRouteName>
                {cruise.destinationPort.cityName}
              </StyledRouteName>

              {/* Сейчас приходит число вместо даты (нужна дата) */}
              <StyledRouteDate>
                {formatDateTime(cruise?.dateEndRaw)}
              </StyledRouteDate>

              <StyledRoutePort>
                {cruise.destinationPort.portName}
              </StyledRoutePort>
            </StyledRouteItem>
          </StyledRoutesColumns>
        </StyledCruiseColumns>
      ))}


      {/* Модальное окно "Details" */}
      <ModalInfo
        title="cruise"
        open={openModalCruise}
        setOpen={(value: boolean) => setOpenModalCruise(value)}
        size="1200px"
      >
        <CruisesTable 
          cruises={cruiseRoute}
        />
      </ModalInfo>
    </StyledCruiseWrapper>
  );
};
