import { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import { Navigation } from "./components/Navigation";
import { PRIVATE_ROUTES, PUBLIC_ROUTES } from "./utils/consts";

const App: React.FC = () => {
  const navigate = useNavigate();

  // Токен для авторизации
  const getAccessToken = localStorage.getItem("accessToken");

  const routes = getAccessToken ? PRIVATE_ROUTES : PUBLIC_ROUTES;

  // Проверка авторизации (наличие токена)
  useEffect(() => {
    if (!getAccessToken) {
      navigate("/");
    } else {
      navigate("/profile");
    }
  }, []);
  
  return (
    <div className="App">
      {getAccessToken && <Navigation links={PRIVATE_ROUTES} />}
      <Routes>
        {routes?.map((route) => (
          <Route key={route.key} path={route.path} element={route.element} />
        ))}
      </Routes>
    </div>
  );
};

export default App;
