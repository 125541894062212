export const INITIALS = [
  {
    label: "Mr",
    value: "MR"
  },
  {
    label: "Ms",
    value: "MS"
  }
]

export const GENDER = [
  {
    label: "Male",
    value: "MALE"
  },
  {
    label: "Female",
    value: "FEMALE"
  },
  {
    label: "Others",
    value: "OTHER"
  }
]
