import { useTranslation } from "react-i18next";
import { POLICY, REMARK } from "./consts";
import {
  StyledPolicyList,
  StyledPolicyListItem,
  StyledPolicyRemarkWrapper,
  StyledPolicySection,
  StyledPolicyTitle,
  StyledRemarkSection,
  StyledRemarkText,
  StyledRemarkTitle,
} from "./PolicyRemark.styles";
import { OrderCabinServiceType } from "../../appolo/graphql/queries/types";

type PolicyRemarkProps = {
  services: OrderCabinServiceType[]
}

export const PolicyRemark = ({ services }: PolicyRemarkProps) => {
  const { t } = useTranslation();

  return (
    <StyledPolicyRemarkWrapper>
      <StyledPolicySection>
        <StyledPolicyTitle>
          {t("cancellationPolicy")}
        </StyledPolicyTitle>

        <StyledPolicyList>
          {services?.map((item) => (
            <StyledPolicyListItem>
              {item.cancellationPolicy ?? "-"}
            </StyledPolicyListItem>
          ))}
        </StyledPolicyList>
      </StyledPolicySection>

      <StyledRemarkSection>
        <StyledRemarkTitle>
          {t("swanHellenicBookingRemark")}
        </StyledRemarkTitle>
        <StyledRemarkText>
          {"-"}
        </StyledRemarkText>
      </StyledRemarkSection>
    </StyledPolicyRemarkWrapper>
  );
};
