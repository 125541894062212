import { useTranslation } from "react-i18next";
import IconEdit from "../../icons/IconEdit";
import { OrderType } from "../../appolo/graphql/queries/types";
import {
  StyledCustomerWrapper,
  StyledCustomerColumn,
  StyledCustomerHeaderColumn,
  StyledCustomerColumnContent,
  StyledCustomerRow,
  StyledButtonEdit,
  StyledStateroomsSectionTitle,
} from "./CustomerMobile.styles";

type CustomerMobileProps = {
  booking: OrderType
}

export const CustomerMobile = ({ booking }: CustomerMobileProps) => {
  const { t } = useTranslation();

  const handleEdit = () => {
    console.log("handleEdit")
  };

  return (
    <StyledCustomerWrapper>
      <StyledStateroomsSectionTitle>
        {t("customer")} 
      </StyledStateroomsSectionTitle>
      <StyledCustomerRow>
        <StyledCustomerColumn>
          <StyledCustomerHeaderColumn>
            {t("fullName")}
          </StyledCustomerHeaderColumn>
          <StyledCustomerColumnContent>
            <span>
              {booking?.owner?.lastname}
            </span>
            <span>
              {booking?.owner?.firstname}
            </span>
            <span>
              {booking?.owner?.middlename}
            </span>
          </StyledCustomerColumnContent>
        </StyledCustomerColumn>

        <StyledCustomerColumn>
          <StyledButtonEdit onClick={handleEdit}>
            <IconEdit />
          </StyledButtonEdit>
        </StyledCustomerColumn>
      </StyledCustomerRow>

      <StyledCustomerRow>
        <StyledCustomerColumn>
          <StyledCustomerHeaderColumn>{t("phone")}</StyledCustomerHeaderColumn>
          <StyledCustomerColumnContent className="row">
            <span>
              +{booking?.owner?.contactPhone?.code}
            </span>
            <span>
              {booking?.owner?.contactPhone?.number}
            </span>
          </StyledCustomerColumnContent>
        </StyledCustomerColumn>

        <StyledCustomerColumn className="last">
          <StyledCustomerHeaderColumn>{t("email")}</StyledCustomerHeaderColumn>
          <StyledCustomerColumnContent>
            {booking?.owner?.contactEmail}
          </StyledCustomerColumnContent>
        </StyledCustomerColumn>
      </StyledCustomerRow>

      <StyledCustomerRow>
        <StyledCustomerColumn>
          <StyledCustomerHeaderColumn>
            {t("loyaltyNumber")}
          </StyledCustomerHeaderColumn>
          <StyledCustomerColumnContent>
            {booking?.owner?.bonusCardNumber ?? "-"}
          </StyledCustomerColumnContent>
        </StyledCustomerColumn>
      </StyledCustomerRow>
    </StyledCustomerWrapper>
  );
};
