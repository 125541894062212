import styled from 'styled-components'
import { mainTheme } from "../../theme"

export const StyledPolicyRemarkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 12px;
`

export const StyledPolicySection = styled.div`
  background: #FFE6C7;
  padding: 24px;
`

export const StyledPolicyTitle = styled.div`
  color: #986C34;
  font-size: ${mainTheme.main.fontSizes.large};
  font-weight: ${mainTheme.main.fontWeights.bold};
  font-family: ${mainTheme.main.fonts.chapMedium};
`

export const StyledPolicyList = styled.ul`
  padding-left: 14px;
  line-height: 24px;
  margin-top: 12px;
  margin-bottom: 0;
`

export const StyledPolicyListItem = styled.li`
  font-size: ${mainTheme.main.fontSizes.medium};
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.dmsRegular};
  color: #986C34;

  &::marker {
    color: #986C34;
  }
`

export const StyledPolicyTextBold = styled.div``

export const StyledRemarkSection = styled.div`
  background: #CAD6D9;
  padding: 24px;
`

export const StyledRemarkTitle = styled.div`
  color: ${mainTheme.main.colors.blue};
  font-size: ${mainTheme.main.fontSizes.large};
  font-weight: ${mainTheme.main.fontWeights.bold};
  font-family: ${mainTheme.main.fonts.chapMedium};
`

export const StyledRemarkText = styled.div`
  color: ${mainTheme.main.colors.blue};
  font-size: ${mainTheme.main.fontSizes.medium};
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family: ${mainTheme.main.fonts.dmsRegular};
  margin-top: 12px;
`