import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { TextField } from "@mui/material";
import { mainTheme } from "../../../theme";
import { ModalInfo } from "../../ModalInfo";
import { SET_PASSWORD } from "../../../appolo/graphql/mutations/setPassword";
import {
  StyledWrapper,
  StyledButtonsWrapper,
  StyledButtonCancel,
  StyledButtonSave,
  StyledError,
  StyledFieldWrapper,
  StyledFormFieldsRow,
  StyledModalRegistrationContent,
  StyledModalButtonsWrapper,
  StyledErrorMessage,
} from "./FormSetPassword.styles";

type IFormSetPasswordInput = {
  email: string
  newPassword: string;
  repeatNewPassword: string;
};

type FormSetPasswordProps = {
  token: string
  email: string
}

export const FormSetPassword = ({ token, email }: FormSetPasswordProps) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [setPasswordMutation] = useMutation(SET_PASSWORD);

  const [passwordError, setPassowrdError] = useState("");
  const [openModalSuccessPassword, setOpenModalSuccessPassword] = useState(false);

  const schema = yup.object().shape({
    email: yup.string().required(t("fieldRequired")),
    newPassword: yup.string().required(t("fieldRequired")),
    repeatNewPassword: yup.string().required(t("fieldRequired")),
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<IFormSetPasswordInput>({
    defaultValues: {
      email: email ?? ""
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: IFormSetPasswordInput) => {
    const formValue = {
      oneTimeToken: token,
      newPassword: data.newPassword,
      repeatNewPassword: data.repeatNewPassword
    };
    setPasswordMutation({
      variables: {
        ...formValue
      },
      onCompleted: () => {
        setPassowrdError("");
        setOpenModalSuccessPassword(true)
      },
      onError: (error) => {
        setPassowrdError(error.message)
        setOpenModalSuccessPassword(false)
      }
    })
    console.log("formValue: ", formValue);
  };

  const handleCancel = () => {
    navigate("/")
  };

  return (
    <StyledWrapper>
      <form>
        <StyledFormFieldsRow className="oneField">
          <StyledFieldWrapper>
            <Controller
              control={control}
              name="email"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  label={t("email")}
                  variant="standard"
                  onChange={onChange}
                  value={value}
                  InputProps={{
                    className: "textFieldForm",
                    style: {
                      color: mainTheme.main.colors.black,
                      fontSize: mainTheme.main.fontSizes.large,
                      fontWeight: mainTheme.main.fontWeights.normal,
                      fontFamily: mainTheme.main.fonts.dmsRegular,
                    },
                  }}
                  disabled
                />
              )}
            />
            {errors.email && <StyledError>{errors.email.message}</StyledError>}
          </StyledFieldWrapper>
        </StyledFormFieldsRow>

        <StyledFormFieldsRow className="twoFields">
          <StyledFieldWrapper>
            <Controller
              control={control}
              name="newPassword"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  label={t("password")}
                  variant="standard"
                  onChange={onChange}
                  value={value}
                  InputProps={{
                    className: "textFieldForm",
                    style: {
                      color: mainTheme.main.colors.black,
                      fontSize: mainTheme.main.fontSizes.large,
                      fontWeight: mainTheme.main.fontWeights.normal,
                      fontFamily: mainTheme.main.fonts.dmsRegular,
                    },
                  }}
                />
              )}
            />
            {errors.newPassword && <StyledError>{errors.newPassword.message}</StyledError>}
          </StyledFieldWrapper>

          <StyledFieldWrapper>
            <Controller
              control={control}
              name="repeatNewPassword"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  label={t("repeatPassword")}
                  variant="standard"
                  onChange={onChange}
                  value={value}
                  InputProps={{
                    className: "textFieldForm",
                    style: {
                      color: mainTheme.main.colors.black,
                      fontSize: mainTheme.main.fontSizes.large,
                      fontWeight: mainTheme.main.fontWeights.normal,
                      fontFamily: mainTheme.main.fonts.dmsRegular,
                    },
                  }}
                />
              )}
            />
            {errors.repeatNewPassword && <StyledError>{errors.repeatNewPassword.message}</StyledError>}
          </StyledFieldWrapper>
        </StyledFormFieldsRow>

        <StyledButtonsWrapper>
          <StyledButtonCancel variant="contained" onClick={handleCancel}>
            {t("cancel")}
          </StyledButtonCancel>
          
          <StyledButtonSave
            type="submit"
            variant="contained"
            onClick={handleSubmit(onSubmit)}
          >
            {t("setPassword")}
          </StyledButtonSave>
        </StyledButtonsWrapper>
      </form>

      {passwordError && (
        <StyledErrorMessage>
          {passwordError}
        </StyledErrorMessage>
      )}
      
      {/* Модальное окно "Password Success" */}
      <ModalInfo
        title=""
        open={openModalSuccessPassword}
        setOpen={(value: boolean) => setOpenModalSuccessPassword(value)}
      >
        <StyledModalRegistrationContent>
          <span>
            {t("passwordChangedSuccessfully")}
          </span>
          <StyledModalButtonsWrapper>
            <StyledButtonSave         
              variant="contained"
              onClick={() => navigate("/")}
            >
              {t("close")}
            </StyledButtonSave>
          </StyledModalButtonsWrapper>
        </StyledModalRegistrationContent>
      </ModalInfo>
    </StyledWrapper>
  );
};
