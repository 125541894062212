/* eslint-disable */
import { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { MenuItem, TextField } from "@mui/material";
import { mainTheme } from "../../../theme";
import { CustomSelect } from "../components/CustomSelect";
import { FieldDatePicker } from "../components/FieldDatePicker";
import { VariantCustomSelectEnum } from "../components/CustomSelect/types";
import { FormEditPassportDetailsProps } from "./types";
import { CountriesListType } from "../../../utils/globalTypes";
import { UPDATE_USER_DOCUMENT } from "../../../appolo/graphql/mutations/updateUserDocument";
import { getCountriesList } from "../../../utils/axios/hooks";
import { CustomAutocomplete } from "../components/CustomAutocomplete";
import {
  StyledFormEditPassportDetailsWrapper,
  StyledFormEditPassportDetailsItem,
  StyledFormEditPassportDetailsItemsList,
  StyledButtonsWrapper,
  StyledButtonCancel,
  StyledButtonSave,
  StyledFormEditPassportDetailsItemsRow,
  StyledFormEditPassportDetailsAnatation,
  StyledErrorMessage,
} from "./FormEditPassportDetails.styles";

interface IFormEditPassportDetailsInput {
  number?: string;
  issueDate?: string;
  expirationDate?: string;
  placeOfBirth?: string;
  issuePlace?: string;
  issueCountry?: string;
  nationality?: string;
}

export const FormEditPassportDetails = ({
  setOpen,
  currentUser,
  refetchCurrentUser,
}: FormEditPassportDetailsProps) => {
  const { t } = useTranslation();

  const [countriesList, setCountriesList] = useState<CountriesListType[]>([]);

  const [errorUpdateUserDocument, setErrorUpdateUserDocument] = useState("");

  const [updateUserDocument] = useMutation(UPDATE_USER_DOCUMENT);

  const schema = yup.object().shape({
    number: yup.string(),
    issueDate: yup.string(),
    expirationDate: yup.string(),
    placeOfBirth: yup.string(),
    issuePlace: yup.string(),
    // issueCountry: yup.string(),
    // nationality: yup.string(),
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset
  } = useForm<IFormEditPassportDetailsInput>({
    resolver: yupResolver(schema)});

  useEffect(() => {
    if (currentUser && Object.keys(currentUser).length) {
      reset({
        number: currentUser?.document.number ?? "",
        issueDate: currentUser?.document.issueDate ?? "",
        expirationDate: currentUser?.document.expirationDate,
        placeOfBirth: currentUser?.document.placeOfBirth,
        issuePlace: currentUser?.document.issuePlace,
        issueCountry: currentUser?.document.issueCountry,
        nationality: currentUser?.document.nationality,
      });
    }
  }, [currentUser]);

  const onSubmit = async (data: IFormEditPassportDetailsInput) => {
    const formValue = {
      kind: "FOREIGN_PASS", // Сказали пока хардкод
      number: data.number,
      issueDate: dayjs(data.issueDate).format("YYYY-MM-DD"),
      expirationDate: dayjs(data.expirationDate).format("YYYY-MM-DD"),
      placeOfBirth: data.placeOfBirth,
      // @ts-ignore
      nationality: data.nationality?.code ? data.nationality?.code : data.nationality, // жду бекенд чтобы отдавал и принимал всегда объект code / value
      // @ts-ignore
      issuePlace: data.issuePlace?.code ? data.issuePlace?.code : data.issuePlace, // жду бекенд чтобы отдавал и принимал всегда объект code / value
      // @ts-ignore
      issueCountry: data.issueCountry?.code ? data.issueCountry?.code : data.issueCountry, // жду бекенд чтобы отдавал и принимал всегда объект code / value
    };

    updateUserDocument({
      variables: {
        form: {
          ...formValue,
        },
      },
      onCompleted: () => {
        setErrorUpdateUserDocument("");
        if (setOpen) {
          setOpen(false);
        }
        if (refetchCurrentUser) {
          refetchCurrentUser();
        }
      },
      onError: (error) => {
        setErrorUpdateUserDocument(error.message);
      },
    });
  };

  const handleCancel = () => {
    setOpen(false);
  };

  useEffect(() => {
    getCountriesList(setCountriesList);
  }, []);

  return (
    <StyledFormEditPassportDetailsWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledFormEditPassportDetailsItemsRow>
          <StyledFormEditPassportDetailsItem>
            <Controller
              control={control}
              name="number"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  label={t("number")}
                  variant="standard"
                  onChange={onChange}
                  value={value}
                  InputProps={{
                    className: "textFieldForm",
                    style: {
                      color: mainTheme.main.colors.black,
                      fontSize: mainTheme.main.fontSizes.large,
                      fontWeight: mainTheme.main.fontWeights.normal,
                      fontFamily: mainTheme.main.fonts.dmsRegular,
                    },
                  }}
                />
              )}
            />
          </StyledFormEditPassportDetailsItem>

          <StyledFormEditPassportDetailsItem>
            <Controller
              control={control}
              name="issueDate"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <FieldDatePicker
                  onChange={onChange}
                  value={value ? dayjs(value) : null}
                  label="issueDate"
                />
              )}
            />
          </StyledFormEditPassportDetailsItem>

          <StyledFormEditPassportDetailsItem>
            <Controller
              control={control}
              name="expirationDate"
              render={({ field: { onChange, onBlur, value, ref } }) => {
                return (
                  <FieldDatePicker
                    onChange={onChange}
                    value={value ? dayjs(value) : null}
                    label="expirationDate"
                  />
                );
              }}
            />
          </StyledFormEditPassportDetailsItem>
        </StyledFormEditPassportDetailsItemsRow>

        <StyledFormEditPassportDetailsItemsList>
          <StyledFormEditPassportDetailsItem>
            <Controller
              control={control}
              name="placeOfBirth"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  label={t("placeOfBirth")}
                  variant="standard"
                  onChange={onChange}
                  value={value}
                  InputProps={{
                    className: "textFieldForm",
                    style: {
                      color: mainTheme.main.colors.black,
                      fontSize: mainTheme.main.fontSizes.large,
                      fontWeight: mainTheme.main.fontWeights.normal,
                      fontFamily: mainTheme.main.fonts.dmsRegular,
                    },
                  }}
                />
              )}
            />
          </StyledFormEditPassportDetailsItem>

          <StyledFormEditPassportDetailsItemsRow>
            <StyledFormEditPassportDetailsItem>
              <Controller
                control={control}
                name="issuePlace"
                render={({ field: { onChange, onBlur, value, ref } }) => {
                  return (
                    <TextField
                      label={t("issuePlace")}
                      variant="standard"
                      onChange={onChange}
                      value={value}
                      InputProps={{
                        className: "textFieldForm",
                        style: {
                          color: mainTheme.main.colors.black,
                          fontSize: mainTheme.main.fontSizes.large,
                          fontWeight: mainTheme.main.fontWeights.normal,
                          fontFamily: mainTheme.main.fonts.dmsRegular,
                        },
                      }}
                    />
                  );
                }}
              />
            </StyledFormEditPassportDetailsItem>

            <StyledFormEditPassportDetailsItem>
              <Controller
                control={control}
                name="issueCountry"
                render={({ field: { onChange, onBlur, value, ref } }) => {
                  return (
                    <CustomAutocomplete
                      label={t("issueCountry")}
                      value={value}
                      onChange={onChange}
                      items={countriesList}
                      getOptionLabel={(option: {
                        code: string;
                        value: string;
                      }) => {
                        if (option?.hasOwnProperty("value")) {
                          return option.value;
                        }
                        return option;
                      }}
                      minSize={120}
                      variant="standard"
                      keyParams="value"
                    />
                  );
                }}
              />
            </StyledFormEditPassportDetailsItem>
          </StyledFormEditPassportDetailsItemsRow>

          <StyledFormEditPassportDetailsItem>
            <Controller
              control={control}
              name="nationality"
              render={({ field: { onChange, onBlur, value, ref } }) => {
                return (
                  <CustomAutocomplete
                    label={t("nationality")}
                    value={value}
                    onChange={onChange}
                    items={countriesList}
                    getOptionLabel={(option: {
                      code: string;
                      value: string;
                    }) => {
                      if (option?.hasOwnProperty("value")) {
                        return option.value;
                      }
                      return option;
                    }}
                    minSize={120}
                    variant="standard"
                    keyParams="value"
                  />
                ); 
              }}
            />
          </StyledFormEditPassportDetailsItem>
        </StyledFormEditPassportDetailsItemsList>

        {errorUpdateUserDocument && (
          <StyledErrorMessage>{errorUpdateUserDocument}</StyledErrorMessage>
        )}

        <StyledFormEditPassportDetailsAnatation>
          {t("anatationEditPassportDetails")}
        </StyledFormEditPassportDetailsAnatation>

        <StyledButtonsWrapper>
          <StyledButtonCancel variant="contained" onClick={handleCancel}>
            {t("cancel")}
          </StyledButtonCancel>
          <StyledButtonSave
            type="submit"
            variant="contained"
            onClick={handleSubmit(onSubmit)}
          >
            {t("save")}
          </StyledButtonSave>
        </StyledButtonsWrapper>
      </form>
    </StyledFormEditPassportDetailsWrapper>
  );
};
