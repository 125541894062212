/* eslint-disable */
import { useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { IconDelete } from "../../icons/IconDelete";
import { ModalInfo } from "../ModalInfo";
import { ServiceDetails } from "../ServiceDetails/ServiceDetails";
import {
  OrderCabinServiceType,
  ReservationType,
} from "../../appolo/graphql/queries/types";
import { getCurrencyIcon, getFormatAmount } from "../../utils/hooks";
import {
  StyledAdditionalServiceItem,
  StyledAdditionalServiceItemColumn,
  StyledAdditionalServiceItemColumns,
  StyledAdditionalServiceList,
  StyledAdditionalServicesSectionTitle,
  StyledAdditionalServicesWrapper,
  StyledAdditionalServiceTypeName,
  StyledButtonCancel,
  StyledButtonDelete,
  StyledButtonSave,
  StyledButtonsWrapper,
  StyledDatesService,
  StyledModalContent,
  StyledModalContentTwoColumns,
  StyledNameCategoryService,
  StyledNameSevice,
  StyledTravellerFIO,
} from "./AdditionalServices.styles";
import { NameGroup } from "../../pages/BookingDetails/types";

type AdditionalServicesProps = {
  nameGroups: NameGroup[];
  services: any;
  places: ReservationType[];
};

export const AdditionalServices = ({
  nameGroups,
  services,
  places,
}: AdditionalServicesProps) => {
  const { t } = useTranslation();

  const [selectedService, setSelectedService] = useState();

  const [openModalDetails, setOpenModalDetails] = useState(false);
  const [openModalCancelService, setOpenModalCancelService] = useState(false);
  const [openModalSuccessfully, setOpenModalSuccessfully] = useState(false);

  const handleDelete = (item: any) => {
    setSelectedService(item);
    setOpenModalCancelService(true);
  };

  const handleYes = () => {
    setOpenModalCancelService(false);
    setOpenModalSuccessfully(true);
  };

  console.log("selectedService: ", selectedService);

  return (
    <StyledAdditionalServicesWrapper>
      <StyledAdditionalServicesSectionTitle>
        {t("additionalServices")}
      </StyledAdditionalServicesSectionTitle>

      <StyledAdditionalServiceList>
        {nameGroups?.map((service: NameGroup) => {
          return (
            <StyledAdditionalServiceList>
              <StyledAdditionalServiceItemColumn>
                {/* Название категории  */}
                <StyledAdditionalServiceTypeName>
                  {service?.name}
                </StyledAdditionalServiceTypeName>

                {/* Сервисы входящие в категорию */}
                {services?.[service?.name]?.map(
                  (item: OrderCabinServiceType) => {
                    const place =
                      places &&
                      places?.filter((place) => place.id === item.placeId)[0];
                    return (
                      <StyledAdditionalServiceItemColumns key={item?.id}>
                        {/* Дата */}
                        <StyledAdditionalServiceItemColumn>
                          <StyledDatesService>
                            <span>
                              {item?.startDate
                                ? moment(item?.startDate).format("DD MMM YYYY")
                                : "-"}
                            </span>

                            <span>
                              {item?.endDate && <span>- {moment(item?.endDate).format("DD MMM YYYY")}</span>}
                            </span>
                          </StyledDatesService>
                        </StyledAdditionalServiceItemColumn>

                        {/* Название категории и имя сервиса */}
                        <StyledAdditionalServiceItemColumn className="name">
                          <StyledNameSevice>
                            <StyledNameCategoryService>
                              {item?.type?.name}
                              {(service?.name === "ONBOARD" && !item?.type?.name) && (
                                t("service")  
                              )}
                            </StyledNameCategoryService>
                            {item?.name}
                          </StyledNameSevice>
                        </StyledAdditionalServiceItemColumn>

                        {/* ФИО пассажира */}
                        <StyledAdditionalServiceItemColumn>
                          {place?.traveller?.document?.firstname ? (
                            <div>
                              <span>
                                {place?.traveller?.document?.lastname}
                              </span>
                              <span>
                                {place?.traveller?.document?.firstname}
                              </span>
                              <span>
                                {place?.traveller?.document?.middlename}
                              </span>
                            </div>
                          ) : (
                            "-"
                          )}
                        </StyledAdditionalServiceItemColumn>

                        {/* Цена */}
                        <StyledAdditionalServiceItemColumn>
                          <span>{getCurrencyIcon(item?.price?.currency)}</span>
                          <span>{getFormatAmount(item?.price?.amount)}</span>
                        </StyledAdditionalServiceItemColumn>

                        {/* Кнопка удаления */}
                        <StyledAdditionalServiceItemColumn className="last">
                          <StyledButtonDelete
                            onClick={() => handleDelete(service)}
                          >
                            <IconDelete />
                          </StyledButtonDelete>
                        </StyledAdditionalServiceItemColumn>
                      </StyledAdditionalServiceItemColumns>
                    );
                  }
                )}
              </StyledAdditionalServiceItemColumn>
            </StyledAdditionalServiceList>
          );
        })}
      </StyledAdditionalServiceList>

      {/* Модальное окно "Details" */}
      <ModalInfo
        title="Details"
        open={openModalDetails}
        setOpen={(value: boolean) => setOpenModalDetails(value)}
      >
        <ServiceDetails />
      </ModalInfo>

      {/* Модальное окно "Cancel Service" */}
      <ModalInfo
        title="cancelService"
        open={openModalCancelService}
        setOpen={(value: boolean) => setOpenModalCancelService(value)}
      >
        <StyledModalContent>{t("questionCancel")}</StyledModalContent>

        <StyledButtonsWrapper>
          <StyledButtonCancel onClick={() => setOpenModalCancelService(false)}>
            {t("cancel")}
          </StyledButtonCancel>
          <StyledButtonSave onClick={handleYes}>{t("yes")}</StyledButtonSave>
        </StyledButtonsWrapper>
      </ModalInfo>

      {/* Модальное окно "Your request has been successfully..." */}
      <ModalInfo
        title=""
        open={openModalSuccessfully}
        setOpen={(value: boolean) => setOpenModalSuccessfully(value)}
      >
        <StyledModalContentTwoColumns>
          <StyledModalContent>
            {t("yourRequestBeenSuccessfully")}
          </StyledModalContent>

          <StyledButtonSave onClick={() => setOpenModalSuccessfully(false)}>
            {t("close")}
          </StyledButtonSave>
        </StyledModalContentTwoColumns>
      </ModalInfo>
    </StyledAdditionalServicesWrapper>
  );
};
