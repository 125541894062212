import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { StyledPageWrapper, StyledTitle } from "./SetPassword.styles"
import { FormSetPassword } from "../../components/Forms/FormSetPassword";

export const SetPassword = () => {
  const { t } = useTranslation();

  const currentPath: any = location?.pathname;

  const params = useParams();

  console.log("currentPath: ", currentPath);
  console.log("params token: ", params?.token)
  console.log("params email: ", params?.email)

  return (
    <StyledPageWrapper>
      <StyledTitle>
        {t("setPassword")}
      </StyledTitle>

      <FormSetPassword 
        token={params?.token ?? ""}
        email={params?.email ?? ""}
      />
    </StyledPageWrapper>
  )
}