import styled from 'styled-components'
import { mainTheme } from "../../theme/index"

export const StyledAuthWrapper = styled.div`
  width: 100%;
  height: 100%;
  max-width: 414px;
  max-height: 384px;
  background: ${mainTheme.main.colors.white};
  padding: 20px 20px 10px 20px;
`

export const StyledText = styled.p`
  font-family:  ${mainTheme.main.fonts.dmsRegular};
`

export const StyledTitle = styled.div`
  font-family: ${mainTheme.main.fonts.chapMedium};
  font-size: ${mainTheme.main.fontSizes.large};
  font-weight: ${mainTheme.main.fontWeights.bold};
  color: ${mainTheme.main.colors.blue};
`