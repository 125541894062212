export const IconAvatar = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={45} height={45} fill="none">
    <path
      stroke="#8C8C8C"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.246}
      d="M22.5 28.125c6.213 0 11.25-5.037 11.25-11.25S28.713 5.625 22.5 5.625s-11.25 5.037-11.25 11.25 5.037 11.25 11.25 11.25ZM5.625 37.969c3.405-5.884 9.589-9.844 16.875-9.844s13.47 3.96 16.875 9.844"
    />
  </svg>
);
