import { OrderListItem } from "../appolo/graphql/queries/types"
import { TypeFormEnum } from "../components/ModalFormEdit/types"

export type CurrentEditFormType = {
  title: string,
  typeEditForm: TypeFormEnum | string
}

export type CountriesListType = {
  value: string
  code: string
}

export type CitiesListType = {
  value: string
}

export type PaymentType = {
  id: number
  orderUuid: string
  status: string
  amount: MoneyType
  method: string
  comment: string
  createdAt: string
}

export type DocumentType = {
  link?: string
  kind: string
  firstname: string
  lastname: string
  middlename: string
  hasNoMiddlename: boolean
  series: string
  number: string
  issueDate: string
  expirationDate: string
  issuePlace: string
  issueCountry: string
  placeOfBirth: string
}

export type DocumentCurrentUserType = {
  link?: string
  kind: string
  nationality: string
  series: string
  number: string
  issueDate: string
  expirationDate: string
  issuePlace: string
  issueCountry: string
  placeOfBirth: string
}

export type PaymentScheduleType = {
  dueDate: string
  actualDate: string
  amount: {
    amount: number
    currency: string
  }
  isPaid: boolean
  isOverdue: boolean
}

export type OrderListType = {
  orderList: {
    list: OrderListItem[]
    pagination: PaginationType
  }
}

export type PaginationType = {
  currentPage: number
  pageCount: number
  perPage: number
  totalCount: number
}

export enum PaymentStatusEnum {
  notPaid = "NOT_PAID",
  partially = "PARTIALLY",
  paid = "PAID",
  overpaid = "OVERPAID"
}

export enum CurrencyEnum {
  RUB = "RUB",
  EUR = "EUR",
  GBP = "GBP",
  USD = "USD"
}

export type MoneyType = {
  amount: number
  currency: string
}

export type DictionaryType = {
  id: number
  name: string
}

export type MarketType = {
  id: number
  name: string
  alias: string
  timezone: string
  settings: {
    isPassengersDocumentsRequired: boolean
  }
}

export type CompanyType = {
  id: number
  name: string
  longName: string
  inn: string
  email: string
  phone: PhoneType
}

export type PriceType = {
  amount: number
  currency: string
  vatAmount: number
  vatKind: string
}

export type PhoneType = {
  code: string
  number: string
}