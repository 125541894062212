import { ThemeProvider } from "styled-components";
import { BreakPoints, Colors, FontSizes, FontWeights, Fonts } from "./types";

export const mainTheme = {
  main: {
    colors: {
      black: Colors.black,
      gray: Colors.gray,
      white: Colors.white,
      blue: Colors.blue,
      merin: Colors.merin,
      red: Colors.red,
      blueMain: Colors.blueMain,
      blueFantom: Colors.blueFantom,
      grayLigth: Colors.grayLigth,
      yellow: Colors.yellow,
      blueLigth: Colors.blueLigth,
      whiteGray: Colors.whiteGray,
      grayInfo: Colors.grayInfo,
      green: Colors.green,
      blueUnder: Colors.blueUnder,
      grayOpacity: Colors.grayOpacity,
      graySilver: Colors.graySilver,
      olivic: Colors.green
    },

    fonts: {
      dmsRegular: Fonts.dmsRegular,
      dmsRegularBold: Fonts.dmsRegularBold,
      dmsRegularMedium: Fonts.dmsRegularMedium,
      chapRegular: Fonts.chapRegular,
      chapMedium: Fonts.chapMedium,
      interRegular: Fonts.interRegular,
      arsenalRegular: Fonts.arsenalRegular,
      arsenalBold: Fonts.arsenalBold
    },
  
    fontSizes: {
      xsmall: FontSizes.xsmall,
      small: FontSizes.small,
      medium: FontSizes.medium,
      large: FontSizes.large,
      xlarge: FontSizes.xlarge
    },
  
    fontWeights: {
      light: FontWeights.light,
      normal: FontWeights.normal,
      bold: FontWeights.bold,
      boldExtra: FontWeights.boldExtra
    },

    breakPoints: {
      mobile: BreakPoints.modile
    }

  },
};

export default function Theme({ children }: any) {
  return (
    <ThemeProvider theme={mainTheme}>
      {children}
    </ThemeProvider>
  );
}
