export enum AddiatinalServisexEnum {
  preCruise = "Pre-cruise",
  onBoard = "On board",
  excursions = "Excursions",
  postCruise = "Post-cruise"
}

export type NameGroup = {
  name: string
  value: number
}