export const GENDER = [
  {
    label: "Male",
    value: "MALE"
  },
  {
    label: "Female",
    value: "FEMALE"
  },
  {
    label: "Others",
    value: "OTHER"
  }
]