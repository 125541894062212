import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { StyledPageWrapper, StyledTitle } from "./ChangePassword.styles"
import { FormChangePassword } from "../../components/Forms/FormChangePassword";

export const ChangePassword = () => {
  const { t } = useTranslation();

  const params = useParams();

  return (
    <StyledPageWrapper>
      <StyledTitle>
        {t("changePassword")}
      </StyledTitle>
      
      <FormChangePassword 
        id={params?.id ?? ""}
        email={params?.email ?? ""}
      />
    </StyledPageWrapper>
  )
}