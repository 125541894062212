/* eslint-disable */
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { FormControlLabel, Radio, TextField } from "@mui/material";
import { mainTheme } from "../../../theme";
import { CustomSelect } from "../components/CustomSelect";
import { FieldDatePicker } from "../components/FieldDatePicker";
import { CustomRadioGroup } from "../components/CustomRadioGroup";
import IconCheckRadio from "../../../icons/IconCheckRadio";
import { VariantCustomSelectEnum } from "../components/CustomSelect/types";
import { CustomCheckbox } from "../components/CustomCheckbox";
import { GENDER } from "./consts";
import { CountriesListType } from "../../../utils/globalTypes";
import { REGISTER_USER } from "../../../appolo/graphql/mutations/registrationUser";
import { ModalInfo } from "../../ModalInfo";
import { getCountriesList, setRegistration } from "../../../utils/axios/hooks";
import {
  StyledWrapper,
  StyledFiedlsRow,
  StyledButtonsWrapper,
  StyledButtonCancel,
  StyledButtonSave,
  StyledError,
  StyledFiedlsRowContent,
  StyledRadioItemWrapper,
  StyledErrorMessage,
  StyledModalRegistrationContent,
  StyledModalButtonsWrapper,
  StyledCheckboxFieldWrapper,
} from "./FormRegistration.styles";

type IFormRegistrationInput = {
  firstname: string;
  middlename?: string;
  lastname: string;
  dob: string;
  countryCode: string;
  gender: string;
  email: string;
  agreement: boolean;
  noMiddleName?: boolean
};

export const FormRegistration = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [countriesList, setCountriesList] = useState<CountriesListType[]>([]);

  const [registerUser] = useMutation(REGISTER_USER);

  const [registrationSuccess, setRegistrationSuccess] = useState();
  const [errorsRegistration, setErrorsRegistration] = useState("");
  const [captchaToken, setCaptchaToken] = useState("");

  const [openModalRegistrationSuccess, setOpenModalRegistrationSuccess] = useState(false);
  const [isNotMiddleName, setIsNotMiddleName] = useState(false);

  const VALIDATION_SCHEMA = yup.object().shape({
    firstname: yup.string().required(t("fieldRequired")),
    middlename: isNotMiddleName 
      ? yup.string()
      : yup.string().required(t("fieldRequired")), 
    lastname: yup.string().required(t("fieldRequired")),
    dob: yup.string().required(t("fieldRequired")),
    countryCode: yup.string().required(t("fieldRequired")),
    gender: yup.string().required(t("fieldRequired")),
    email: yup.string().required(t("fieldRequired")),
    agreement: yup.boolean().required(t("fieldRequired")),
  })
  
  const {
    handleSubmit,
    formState: { errors },
    control,
    watch
  } = useForm<IFormRegistrationInput>({
    resolver: yupResolver(VALIDATION_SCHEMA),
    defaultValues: {},
  });

  const getValuesForm = watch();

  const onSubmit = async (data: IFormRegistrationInput) => {
    // @ts-ignore
    window?.getCapture?.(); // Вызов функции которая вызовит капчу (капча находится в родительстком приложение SH)

    // Запрос токена капчи (ожидаем пока капча вернет токен)
    const intervalGetCaptureReact = setInterval(() => {
      // Токен который вернет captcha из SH (родительского приложения для CPA)
      const captureReact = localStorage.getItem("captureReact") ?? "";
      console.log("tick timer checked token in captcha")

      if (captureReact?.length > 15) {
        console.log("stop timer");
        clearInterval(intervalGetCaptureReact);
        setCaptchaToken(captureReact);
        console.log("captureReact: ", captureReact);
      }
    }, 1000);

    // registerUser({
    //   variables: {
    //     form: {
    //       firstname: formValue.firstname,
    //       middlename: formValue.middlename,
    //       lastname: formValue.lastname,
    //       dob: formValue?.dob,
    //       countryCode: formValue?.countryCode,
    //       gender: formValue?.gender,
    //       email: formValue.email,
    //       hasNoMiddlename: false     
    //     }
    //   },
    //   onCompleted: () => {
    //     setErrorsRegistration("");
    //     setOpenModalRegistrationSuccess(true)
    //   },
    //   onError: (error) => {
    //     setErrorsRegistration(error.message)
    //     setOpenModalRegistrationSuccess(false)
    //   }
    // })
  };

  const handleCancel = () => {
    navigate("/")
  };

  // Если капча отдала токен тогда нужно вызвать метод регистрации
  useEffect(() => {
    if (captchaToken?.length) {
      console.log("captchaToken useEffect");
      console.log("captchaToken!!!: ", captchaToken);
      const formValues = {
        ...getValuesForm,
        dob: dayjs(getValuesForm.dob).format("YYYY-MM-DD"),
        gender: getValuesForm.gender,
        captureReact: captchaToken
      };
      setRegistration(setRegistrationSuccess, formValues, setErrorsRegistration);
    }
  }, [captchaToken]);

  const handleCloseModalRegistration = () => {
    setOpenModalRegistrationSuccess(false);
    navigate("/");
  };

  useEffect(() => {
    getCountriesList(setCountriesList);
    console.log("test 18.10.2024 13:00")
  }, []);

  useEffect(() => {
    setIsNotMiddleName(getValuesForm.noMiddleName ?? false)
  }, [getValuesForm]);

  return (
    <StyledWrapper>
      <form>
        <StyledFiedlsRow>
          <StyledFiedlsRowContent>
            <Controller
              control={control}
              name="firstname"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  label={t("firstName")}
                  variant="standard"
                  onChange={onChange}
                  value={value}
                  InputProps={{
                    className: "textFieldForm",
                    style: {
                      color: mainTheme.main.colors.black,
                      fontSize: mainTheme.main.fontSizes.large,
                      fontWeight: mainTheme.main.fontWeights.normal,
                      fontFamily: mainTheme.main.fonts.dmsRegular,
                    },
                  }}
                />
              )}
            />
            {errors.firstname && <StyledError>{errors.firstname.message}</StyledError>}
          </StyledFiedlsRowContent>
          
          <StyledFiedlsRowContent>
            <Controller
              control={control}
              name="middlename"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  label={t("middleName")}
                  variant="standard"
                  onChange={onChange}
                  value={value}
                  InputProps={{
                    className: "textFieldForm",
                    style: {
                      color: mainTheme.main.colors.black,
                      fontSize: mainTheme.main.fontSizes.large,
                      fontWeight: mainTheme.main.fontWeights.normal,
                      fontFamily: mainTheme.main.fonts.dmsRegular,
                    },
                  }}
                />
              )}
            />

            <StyledCheckboxFieldWrapper>
              <Controller
                control={control}
                name="noMiddleName"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <CustomCheckbox 
                    value={value}
                    label={t("noMiddleName")}
                    setValue={onChange}
                  />
                )}
              />
            </StyledCheckboxFieldWrapper>

            {errors.middlename && (
              <StyledError className="middleName">
                {errors.middlename.message}
              </StyledError>
            )}
          </StyledFiedlsRowContent>
 
          <StyledFiedlsRowContent>
            <Controller
              control={control}
              name="lastname"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  label={t("lastName")}
                  variant="standard"
                  onChange={onChange}
                  value={value}
                  InputProps={{
                    className: "textFieldForm",
                    style: {
                      color: mainTheme.main.colors.black,
                      fontSize: mainTheme.main.fontSizes.large,
                      fontWeight: mainTheme.main.fontWeights.normal,
                      fontFamily: mainTheme.main.fonts.dmsRegular,
                    },
                  }}
                />
              )}
            />
            {errors.lastname && <StyledError>{errors.lastname.message}</StyledError>}
          </StyledFiedlsRowContent>
        </StyledFiedlsRow>

        <StyledFiedlsRow>
          <StyledFiedlsRowContent>
            <Controller
              control={control}
              name="dob"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <FieldDatePicker
                  onChange={onChange}
                  value={value ? dayjs(value) : null}
                  label={t("dateOfBirth")}
                />
              )}
            />
            {errors.dob && <StyledError>{errors.dob.message}</StyledError>}
          </StyledFiedlsRowContent>
 
          <StyledFiedlsRowContent>
            <Controller
              control={control}
              name="countryCode"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <CustomSelect
                  label={t("countryOfResidence")}
                  onChange={onChange}
                  defaultValue=""
                  variant={VariantCustomSelectEnum.standard}
                >
                  {countriesList?.length && countriesList?.map((item) => (
                    <MenuItem key={item.value} value={item?.code}>
                      {item.value}
                    </MenuItem>
                  ))}
                </CustomSelect>
              )}
            />
            {errors.countryCode && <StyledError>{errors.countryCode.message}</StyledError>}
          </StyledFiedlsRowContent>
 
          <StyledFiedlsRowContent>
            <Controller
              control={control}
              name="gender"
              render={({ field: { onChange, onBlur, value, ref } }) => {
                return (
                  <CustomRadioGroup
                    label={t("gender")}
                    onChange={onChange}
                    defaultValue=""
                  >
                    <StyledRadioItemWrapper>
                      {GENDER.map((item) => (
                        <FormControlLabel
                          key={item.value}
                          value={item?.value}
                          control={<Radio checkedIcon={<IconCheckRadio />} />}
                          // @ts-ignore
                          label={t(item.label)}
                        />
                      ))}
                    </StyledRadioItemWrapper>
                  </CustomRadioGroup>
                )
              }}
            />
            {errors.gender && <StyledError>{errors.gender.message}</StyledError>}
          </StyledFiedlsRowContent>
        </StyledFiedlsRow>

        <StyledFiedlsRow>
          <StyledFiedlsRowContent>
            <Controller
              control={control}
              name="email"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  label={t("email")}
                  variant="standard"
                  onChange={onChange}
                  value={value}
                  InputProps={{
                    className: "textFieldForm",
                    style: {
                      color: mainTheme.main.colors.black,
                      fontSize: mainTheme.main.fontSizes.large,
                      fontWeight: mainTheme.main.fontWeights.normal,
                      fontFamily: mainTheme.main.fonts.dmsRegular,
                    },
                  }}
                />
              )}
            />
            {errors.email && <StyledError>{errors.email.message}</StyledError>}
          </StyledFiedlsRowContent>
        </StyledFiedlsRow>

        <StyledFiedlsRowContent>
          <Controller
            control={control}
            name="agreement"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <CustomCheckbox
                value={value}
                label={t("agreement")}
                setValue={onChange}
              />
            )}
          />
           {errors.agreement && <StyledError>{errors.agreement.message}</StyledError>}
        </StyledFiedlsRowContent>
        
        {errorsRegistration && (
          <StyledErrorMessage>
            {errorsRegistration}
          </StyledErrorMessage>
        )}
   
        <StyledButtonsWrapper>
          <StyledButtonCancel variant="contained" onClick={handleCancel}>
            {t("cancel")}
          </StyledButtonCancel>
          <StyledButtonSave
            type="submit"
            variant="contained"
            onClick={handleSubmit(onSubmit)}
          >
            {t("register")}
          </StyledButtonSave>
        </StyledButtonsWrapper>
      </form>

      {/* Модальное окно "Registration Success" */}
      <ModalInfo
        title="Registation"
        open={openModalRegistrationSuccess}
        setOpen={(value: boolean) => setOpenModalRegistrationSuccess(value)}
      >
        <StyledModalRegistrationContent>
          <span>
            {t("successRegistration")}
          </span>
          <StyledModalButtonsWrapper>
            <StyledButtonSave         
              variant="contained"
              onClick={handleCloseModalRegistration}
            >
              {t("close")}
            </StyledButtonSave>
          </StyledModalButtonsWrapper>
        </StyledModalRegistrationContent>
      </ModalInfo>
    </StyledWrapper>
  );
};
