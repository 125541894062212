import styled from "styled-components";
import { mainTheme } from "../../theme";

// Раздел "Personal Information"
export const StyledBookingInfoPersonalInfoSection = styled.div`

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    padding: 0px;
    margin-top: 0;
    grid-template-columns: minmax(100%, auto);
  }
`

export const StyledBookingInfoPersonalInfoWrapper = styled.div``

export const StyledBookingInfoItem = styled.div`
  background: ${mainTheme.main.colors.white};
  padding: 16px 24px 20px 24px;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    padding: 0px 16px 24px 16px;
  }
`;

export const StyledBookingInfoItemTitle = styled.div`
  color: ${mainTheme.main.colors.blue};
  font-size: ${mainTheme.main.fontSizes.large};
  font-weight: ${mainTheme.main.fontWeights.bold};
  text-transform: uppercase;
  font-family: ${mainTheme.main.fonts.chapMedium};

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    padding-top: 24px;
    padding-left: 0px;
  }
`;

export const StyledBookingInfoPersonalInfoContent = styled.div`
  margin-top: 15px;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    margin-top: 0px;
    padding-top: 12px;
  }
`

export const StyledBookingInfoPersonalInfoContentItem = styled.div``

export const StyledBookingInfoPersonalInfoContentSubItem = styled.div`
  background: ${mainTheme.main.colors.white};
  padding: 16px 24px 20px 24px;
`

export const StyledDocumentIconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  gap: 10px;
`

export const StyledDocumentName = styled.div`
  color: ${mainTheme.main.colors.blue};
  font-size: ${mainTheme.main.fontSizes.medium};
  font-weight: ${mainTheme.main.fontWeights.normal};
  text-decoration: underline;
`

export const StyledBookingInfoPersonalInfoContentSubItemTitle = styled.div`
  color: ${mainTheme.main.colors.blue};
  font-size: ${mainTheme.main.fontSizes.large};
  font-weight: ${mainTheme.main.fontWeights.bold};
`

export const StyledBookingInfoPersonalInfoListItemColumn = styled.div`
  display: flex;
  flex-direction: column;
  
  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    justify-content: flex-start;
  }

  &.photoColumn {
    @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
      display: none;
    }
  }

  &.paddingDown {
    justify-content: flex-end;
    @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
      justify-content: flex-start;
    }
  }

  &.lastColumn {
    @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
      align-items: self-end;
      text-align: right;
    }
  }

  &.lastRow {
    @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
      margin-top: -20px;
    }
  }

  &.mobileColumnRigth {
    @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
      div {
        justify-content: flex-end;
        align-items: flex-end;
        text-align: right;
      }
    }
  }
`

export const StyledBookingInfoPersonalInfoListItemColumnRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 120px;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    flex-direction: column;
    align-items: flex-end;
    gap: 0px;
  }
`

export const StyledBookingInfoPersonalInfoListItem = styled.div`
  margin-bottom: 20px;

  &.downContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    height: 110px;

    @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
      height: auto;
    }
  }

  &.desktop {
    @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
     display: none;
    }
  }

  &.mobile {
    display: none;
  
    @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
     display: flex;
     flex-direction: column;
     width: 100%;
    }
  }
`

export const StyledBookingInfoPersonalInfoListItemTitle = styled.div`
  color: ${mainTheme.main.colors.merin};
  font-size: ${mainTheme.main.fontSizes.small};
  font-weight: ${mainTheme.main.fontWeights.normal};
`

export const StyledBookingInfoPersonal = styled.div`
  color: ${mainTheme.main.colors.black};
  font-size: ${mainTheme.main.fontSizes.medium};
  font-weight: ${mainTheme.main.fontWeights.normal};
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  gap: 4px;
`

export const StyledChange = styled.div`
  color: ${mainTheme.main.colors.blue};
  font-size: ${mainTheme.main.fontSizes.medium};
  font-weight: ${mainTheme.main.fontWeights.bold};
  font-family:  ${mainTheme.main.fonts.dmsRegularMedium};
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
`

// Блок "Карта Лояльности" и Блок "Документы"
export const StyledBookingInfoPersonalInfoDock = styled.div`
  display: grid;
  grid-template-rows: repeat(2, 0fr);
  gap: 10px;
  grid-auto-flow: column;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    display: none;
  }
`


export const StyledBookingInfoPersonalInfoList = styled.div`
  display: grid;
  grid-template-columns: minmax(200px, 200px) minmax(200px, 160px) minmax(380px, 174px) minmax(160px, auto);
  grid-auto-flow: row;
  gap: 20px;
  background: ${mainTheme.main.colors.white};

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    display: grid;
    grid-template-columns: minmax(100px, auto) minmax(100px, auto);
    gap: 10px;
    grid-auto-flow: column;
  }
`

export const StyledAvatarColumn = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
`

export const StyledAvatarWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  background: #DBDBDB;
  flex-direction: column;
`

export const StyledIconAvatar = styled.div``

export const StyledImgAvatar = styled.img``

export const StyledChangeAvatar = styled.div`
  color: ${mainTheme.main.colors.blue};
  font-size: ${mainTheme.main.fontSizes.medium};
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family:  ${mainTheme.main.fonts.dmsRegular};
  text-decoration: underline;
`