import { useLocation } from "react-router-dom";
import { RoutesLinksType } from "../../utils/consts";
import { StyledLink, StyledNavigationWrapper } from "./Navigation.styles";

export type NavigationProps = {
  links: RoutesLinksType[];
};

export const Navigation = ({ links }: NavigationProps) => {

  const availableLinks = links?.filter((link) => !link?.ignore);

  const location = useLocation();

  const currentPath: any = location?.pathname;

  return (
    <StyledNavigationWrapper>
      {availableLinks?.map((item) => {
        return (
          <StyledLink
            key={item.name}
            to={item.path}
            className={
              (currentPath?.includes(item.key) && "active") ||
              (!currentPath?.length && item.key === "profile" && "active") ||
              ((currentPath?.includes("bookingDetails") && item.key === "bookings") && "active")
            }
          >
            {item.name}
          </StyledLink>
        );
      })}
    </StyledNavigationWrapper>
  );
};
