import { useTranslation } from "react-i18next";
import moment from "moment";
import { StyledBookingDetailsInfoColumn, StyledBookingDetailsInfoColumnContent, StyledBookingDetailsInfoColumns, StyledBookingDetailsInfoHeaderColumn, StyledBookingDetailsInfoHeaderColumns, StyledBookingDetailsInfoWrapper, StyledDateTimeColumn } from "./BookingDetailsInfo.styles"
import { OrderType } from "../../appolo/graphql/queries/types";

type BookingDetailsInfoProps = {
  booking: OrderType
}

export const BookingDetailsInfo = ({ booking }: BookingDetailsInfoProps) => {
  const { t } = useTranslation();

  const getNameTimeZone = booking?.createdAt?.replace(/[^a-zA-Z]+/g, "");

  return (
    <StyledBookingDetailsInfoWrapper>
      <StyledBookingDetailsInfoColumns>
        <StyledBookingDetailsInfoColumn>
          <StyledBookingDetailsInfoHeaderColumn>
            {t("bookingCode")}
          </StyledBookingDetailsInfoHeaderColumn>
          <StyledBookingDetailsInfoColumnContent className="code">
            {booking?.number}
          </StyledBookingDetailsInfoColumnContent>
        </StyledBookingDetailsInfoColumn>

        <StyledBookingDetailsInfoColumn>
          <StyledBookingDetailsInfoHeaderColumn>
            {t("status")}
          </StyledBookingDetailsInfoHeaderColumn>
          <StyledBookingDetailsInfoColumnContent className="status">
            {booking?.status}
          </StyledBookingDetailsInfoColumnContent>
        </StyledBookingDetailsInfoColumn>

        <StyledBookingDetailsInfoColumn>
          <StyledBookingDetailsInfoHeaderColumn>
            {t("bookedAt")}
          </StyledBookingDetailsInfoHeaderColumn>
          <StyledBookingDetailsInfoColumnContent>
            <StyledDateTimeColumn>
              {moment(booking?.createdAt, "YYYY-MM-DD hh:mm").format(`MMMM DD, YYYY`)}
            </StyledDateTimeColumn>

            <StyledDateTimeColumn>
              <span>
                {moment(booking?.createdAt, "YYYY-MM-DD hh:mm").format("hh:mm a")}
              </span>
              <span>
                {getNameTimeZone}
              </span>
              <span>
                UTC ({moment(booking?.createdAt, "YYYY-MM-DD hh:mm").format(`Z`).slice(0, -3)})
              </span>
            </StyledDateTimeColumn>
          </StyledBookingDetailsInfoColumnContent>
        </StyledBookingDetailsInfoColumn>

        <StyledBookingDetailsInfoColumn>
          <StyledBookingDetailsInfoHeaderColumn>
            {t("bookingTimeLimit")}
          </StyledBookingDetailsInfoHeaderColumn>

          <StyledBookingDetailsInfoColumnContent className="timeLimit">
            <StyledDateTimeColumn>
            {t("until")} {moment(booking?.bookedTo, "YYYY-MM-DD hh:mm").format(`MMMM DD, YYYY`)}
            </StyledDateTimeColumn>

            <StyledDateTimeColumn>
              <span>
                {moment(booking?.bookedTo, "YYYY-MM-DD hh:mm").format("hh:mm a")}
              </span>
              <span>
                {getNameTimeZone}
              </span>
              <span>
                UTC ({moment(booking?.bookedTo, "YYYY-MM-DD hh:mm").format(`Z`).slice(0, -3)})
              </span>
            </StyledDateTimeColumn>
          </StyledBookingDetailsInfoColumnContent>
        </StyledBookingDetailsInfoColumn>
      </StyledBookingDetailsInfoColumns>
    </StyledBookingDetailsInfoWrapper>
  )
}