import {gql} from '@apollo/client'

export const ORDER_INFO = gql`
  query ORDER_INFO($context: Context, $uuid: String!) {
    order(context: $context, uuid: $uuid) {
      uuid
      number
      createdAt
      bookedTo
      bookedTimeLeft
      status
      paymentStatus
      refundStatus
      price {
        totalAmount {
          amount
          currency
        }
        discountedAmount {
          amount
          currency
        }
        balanceDue {
          amount
          currency
        }
        agencyBalanceDue {
          amount
          currency
        }
        commission {
          amount
          currency
        }
        fares {
          amount
          currency
        }
        services {
          amount
          currency
        }
        paid {
          amount
          currency
        }
        discounts {
          id
          name
          value {
            amount
            currency
          }
        }
        promoCode {
          code
          value {
            amount
            currency
          }
        }
        discountsTotal {
          amount
          currency
        }
        discountsPercent
        discountsLines {
          title
          subtitle
          value {
            amount
            currency
          }
        }
      }
      currency
      companyComment
      managerComment
      cancellationConditions
      isCancelable
      isRefundable
      isPayable
      owner {
          id
          firstname
          lastname
          fullname
          gender
          dob
          contactPhone {
            code
            number
          }
          contactEmail
          bonusCardNumber
          document {
            kind
            nationality
            series
            number
            issueDate
            expirationDate
            issuePlace
            issueCountry
            placeOfBirth
          }
          company {
            id
            name
            longName
            inn
            email
            phone {
              code
              number
            }
          }
        }

      responsible {
          id
          firstname
          lastname
          fullname
          gender
          dob
          contactPhone {
            code
            number
          }
          contactEmail
          bonusCardNumber
          document {
            kind
            nationality
            series
            number
            issueDate
            expirationDate
            issuePlace
            issueCountry
            placeOfBirth
          }
          company {
            id
            name
            longName
            inn
            email
            phone {
              code
              number
            }
          }
        }

      customer {
          id
          firstname
          lastname
          middlename
          fullname
          gender
          dob
          contactPhone {
            code
            number
          }
          contactEmail
          bonusCardNumber
          document {
            kind
            nationality
            series
            number
            issueDate
            expirationDate
            issuePlace
            issueCountry
            placeOfBirth
          }
          company {
            id
            name
            longName
            inn
            email
            phone {
              code
              number
            }
          }
      }
      cruises {
        cruiseId
        name
        position
        isGroupOrder
        motorshipName
        motorshipId
        dateStartRaw
        dateEndRaw
        duration
        reservationKind
        cabins {
          id
          number
          deck
          roomClass
        }
        services {
          id
          serviceId
          placeId
          name
          type {
            id
            name
          }
          category
          cancellationPolicy
          commissionPercent
          commissionAmount {
            amount
            currency
          }
          count
          price {
            amount
            currency
            vatAmount
            vatKind
          }
          startDate
          endDate
        }
        originPort {
          portId
          cityName
          portName
        }
        destinationPort {
          portId
          cityName
          portName
        }
      }

      paymentSchedule {
        dueDate
        actualDate
        amount {
          amount
          currency
        }
        isPaid
        isOverdue
      }

      payments {
        id
        orderUuid
        status
        amount {
          amount
          currency
        }
        method
        comment
        createdAt
      }

      documents {
        link
        name
        kind
      }

    }
  }
`