import { CurrencyEnum } from "./globalTypes";

// Вернет иконку валюты
export const getCurrencyIcon = (currency: string) => {
  return (
    <>
      {currency === CurrencyEnum.EUR && (
        <span>&euro;</span>
      )}
      {currency === CurrencyEnum.GBP && (
        <span>&pound;</span>
      )}
      {currency === CurrencyEnum.RUB && (
        <span>&#8381;</span>
      )}
      {currency === CurrencyEnum.USD && (
        <span>$</span>
      )}
    </>
  )
};

// Стоимость с разделением на десятки (через запятую)
export const getFormatAmount = (amount: number) => {
  return (amount / 100)?.toString()?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}
