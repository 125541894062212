import styled from "styled-components";
import { Button } from "@mui/material";
import { mainTheme } from "../../../../theme";

export const StyledMain = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const StyledDropzoneText = styled.div`
  font-size: ${mainTheme.main.fontSizes.medium};
  font-weight: ${mainTheme.main.fontWeights.bold};
  font-family: ${mainTheme.main.fonts.dmsRegular};
  color: ${mainTheme.main.colors.grayOpacity};
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  align-items: center;

  span {
    &.under {
      color: ${mainTheme.main.colors.blue};
    }
  }
`

export const StyledImagePreview = styled.img`
  display: flex;
  max-width: 186px;
  max-height: 186px;
`

export const StyledFileName = styled.div`
  display: "flex";
  font-size: ${mainTheme.main.fontSizes.small};
  margin-top: "8px";
`

export const StyledDropZoneWrapper = styled.div`
  border: 1px solid ${mainTheme.main.colors.merin};
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  ul {
    list-style: none;
    padding-left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.activeDropzone {
    border-color: "#00adb5";
  }
`

export const StyledLabel = styled.div`
  margin-top: 20px;
  color: ${mainTheme.main.colors.grayOpacity};
  font-size: ${mainTheme.main.fontSizes.xsmall};
  font-weight: ${mainTheme.main.fontWeights.normal};
  font-family:  ${mainTheme.main.fonts.dmsRegular};
`

export const StyledControlWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
`

export const StyledButtonSave = styled(Button)`
  &.MuiButton-root {
    border-radius: 24px;
    color: ${mainTheme.main.colors.white};
    font-size: ${mainTheme.main.fontSizes.large};
    font-weight: ${mainTheme.main.fontWeights.normal};
    font-family: ${mainTheme.main.fonts.dmsRegular};
    background: ${mainTheme.main.colors.blue};
    width: 200px;
    border: 1px solid ${mainTheme.main.colors.blue};
    text-transform: capitalize;
    box-shadow: none;
    padding: 4px;

    &:hover {
      background: ${mainTheme.main.colors.blue};
    }

    @media (max-width: ${mainTheme.main.breakPoints.mobile}){
      width: 100%;
    }
  }
`;

export const StyledButtonCancel = styled(Button)`
  &.MuiButton-root {
    border-radius: 20px;
    color: ${mainTheme.main.colors.black};
    font-size: ${mainTheme.main.fontSizes.large};
    font-weight: ${mainTheme.main.fontWeights.normal};
    background: ${mainTheme.main.colors.white};
    width: 200px;
    border: 1px solid ${mainTheme.main.colors.graySilver};
    box-shadow: none;
    text-transform: capitalize;

    &.full {
      width: 100%;
    }

    &:hover {
      background: ${mainTheme.main.colors.white};
    }

    @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
      width: 100%;
    }
  }
`;