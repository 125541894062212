import styled from 'styled-components'
import { mainTheme } from "../../theme"

export const StyledPageWrapper = styled.div`
  padding: 20px;
  background: ${mainTheme.main.colors.white};
  margin: 34px;
`

export const StyledTitle = styled.div`
  font-family: ${mainTheme.main.fonts.chapMedium};
  font-size: ${mainTheme.main.fontSizes.large};
  font-weight: ${mainTheme.main.fontWeights.bold};
  color: ${mainTheme.main.colors.blue};
`

export const StyledFormWrapper = styled.div`
  margin-top: 20px;
`