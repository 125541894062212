import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { AccordionBooking } from "../AccordionBooking";
import { MESSAGE } from "./consts";
import {
  DiscountType,
  OrderPriceType,
  PromoCodes,
} from "../../appolo/graphql/queries/types";
import { getCurrencyIcon, getFormatAmount } from "../../utils/hooks";
import { CANCEL_ORDER } from "../../appolo/graphql/mutations/cancelOrder";
import { ModalInfo } from "../ModalInfo";
import {
  AccordionWrapper,
  StyledAccordionContent,
  StyledButtonControlBooking,
  StyledButtonSave,
  StyledButtonsControlWrapper,
  StyledCategory,
  StyledCategoryRow,
  StyledErrorMessage,
  StyledModalButtonsWrapper,
  StyledModalContent,
  StyledNameCategory,
  StyledPriceCategory,
  StyledPriceDetailsDescriptions,
  StyledPriceDetailsItem,
  StyledPriceDetailsPriceTotal,
  StyledPriceDetailsPriceTotalAmount,
  StyledPriceDetailsPriceTotalName,
  StyledPriceDetailsWrapper,
  StyledSubCategoryItem,
  StyledSubCategoryName,
  StyledSubCategoryPrice,
  StyledSubCategoryWrapper,
} from "./PriceDetails.styles";

type PriceDetailsType = {
  priceData: OrderPriceType;
  uuidOrder: string;
  isCancelable: boolean;
  isRefundable: boolean;
  isPayable: boolean;
};

export const PriceDetails = ({
  priceData,
  uuidOrder,
  isCancelable,
  isRefundable,
  isPayable,
}: PriceDetailsType) => {
  const { t } = useTranslation();

  const [openModalCancelBooking, setOpenModalCancelBooking] = useState(false);
  const [errorCancelBooking, setErrorCancelBooking] = useState("");

  const [cancelOrder] = useMutation(CANCEL_ORDER);

  const handleCancelOrder = () => {
    cancelOrder({
      variables: {
        uuid: uuidOrder,
        context: {
          market: "ru",
        },
      },
      onCompleted: () => {
        setErrorCancelBooking("");
        setOpenModalCancelBooking(false);
      },
      onError: (error) => {
        setErrorCancelBooking(error.message);
        setOpenModalCancelBooking(true);
      },
    });
  };

  return (
    <StyledPriceDetailsWrapper>
      <StyledPriceDetailsDescriptions>{MESSAGE}</StyledPriceDetailsDescriptions>

      <AccordionWrapper>
        <AccordionBooking title={t("priceDetails")}>
          <StyledAccordionContent>
            {/* Стоимость по категориям */}
            <StyledPriceDetailsItem>
              {/* Cabins */}
              <StyledCategory>
                <StyledNameCategory>{t("cabins")}</StyledNameCategory>
                <StyledPriceCategory>
                  <span>{getFormatAmount(priceData?.fares.amount)}</span>
                  <span>{getCurrencyIcon(priceData?.fares.currency)}</span>
                </StyledPriceCategory>
              </StyledCategory>

              {/* Additional Services */}
              <StyledCategory>
                <StyledNameCategory>
                  {t("additionalServices")}
                </StyledNameCategory>
                <StyledPriceCategory>
                  <span>{getFormatAmount(priceData?.services?.amount)}</span>
                  <span>{getCurrencyIcon(priceData?.services?.currency)}</span>
                </StyledPriceCategory>
              </StyledCategory>

              {/* Total discounts */}
              <StyledCategory>
                <StyledCategoryRow>
                  <StyledNameCategory>{t("totalDiscounts")}</StyledNameCategory>
                  <StyledPriceCategory>{"-"}</StyledPriceCategory>
                </StyledCategoryRow>

                {/* Discounts list (список скидок) */}
                <StyledSubCategoryWrapper>
                  {priceData?.discounts?.map((item: DiscountType) => (
                    <StyledSubCategoryItem key={item?.id}>
                      <StyledSubCategoryName>{item.name}</StyledSubCategoryName>
                      <StyledSubCategoryPrice>
                        <span>{getFormatAmount(item?.value?.amount)}</span>
                        <span>{getCurrencyIcon(item?.value?.currency)}</span>
                      </StyledSubCategoryPrice>
                    </StyledSubCategoryItem>
                  ))}
                </StyledSubCategoryWrapper>

                {/* Promocodes list (список промокодов) */}
                <StyledSubCategoryWrapper>
                  {priceData?.promoCodes?.map((item: PromoCodes) => (
                    <StyledSubCategoryItem key={item?.code}>
                      <StyledSubCategoryName>{item.code}</StyledSubCategoryName>
                      <StyledSubCategoryPrice>
                        <span>{getFormatAmount(item?.value?.amount)}</span>
                        <span>{getCurrencyIcon(item?.value?.currency)}</span>
                      </StyledSubCategoryPrice>
                    </StyledSubCategoryItem>
                  ))}
                </StyledSubCategoryWrapper>
              </StyledCategory>
            </StyledPriceDetailsItem>

            <StyledCategory>
              <StyledNameCategory>
                {t("paid")} / {t("balanceDue")}
              </StyledNameCategory>
              <StyledPriceCategory>
                <div>
                  <span>{getFormatAmount(priceData?.paid?.amount)}</span>
                  <span>{getCurrencyIcon(priceData?.paid?.currency)}</span>
                </div>
                /
                <div>
                  <span>{getFormatAmount(priceData?.balanceDue?.amount)}</span>
                  <span>{getCurrencyIcon(priceData?.balanceDue?.currency)}</span>
                </div>
              </StyledPriceCategory>
            </StyledCategory>

            {/* Общая стоимость */}
            <StyledPriceDetailsPriceTotal>
              <StyledPriceDetailsPriceTotalName>
                {t("paymentTotal")}
              </StyledPriceDetailsPriceTotalName>
              <StyledPriceDetailsPriceTotalAmount>
                <span>{getFormatAmount(priceData?.totalAmount?.amount)}</span>
                <span>{getCurrencyIcon(priceData?.totalAmount?.currency)}</span>
              </StyledPriceDetailsPriceTotalAmount>
            </StyledPriceDetailsPriceTotal>
          </StyledAccordionContent>

          <StyledButtonsControlWrapper>
            {isPayable && (
              <StyledButtonControlBooking
                className="pay"
              >
                {t("payAll")}
              </StyledButtonControlBooking>
            )}

            {isRefundable && (
              <StyledButtonControlBooking>
                {t("refundRequest")}
              </StyledButtonControlBooking>
            )}

            {isCancelable && (
              <StyledButtonControlBooking
                onClick={() => setOpenModalCancelBooking(true)}
              >
                {t("cancelBooking")}
              </StyledButtonControlBooking>
            )}
          </StyledButtonsControlWrapper>
        </AccordionBooking>
      </AccordionWrapper>

      {/* Модальное окно подтверждения "Cancel booking"  */}
      <ModalInfo
        title="cancelBooking"
        open={openModalCancelBooking}
        setOpen={(value: boolean) => setOpenModalCancelBooking(value)}
      >
        <StyledModalContent>
          <span>{t("requestRefund")}</span>
          <StyledModalButtonsWrapper>
            <StyledButtonSave
              variant="contained"
              onClick={() => setOpenModalCancelBooking(false)}
            >
              {t("no")}
            </StyledButtonSave>

            <StyledButtonSave variant="contained" onClick={handleCancelOrder}>
              {t("yes")}
            </StyledButtonSave>
          </StyledModalButtonsWrapper>

          {errorCancelBooking && (
            <StyledErrorMessage>{errorCancelBooking}</StyledErrorMessage>
          )}
        </StyledModalContent>
      </ModalInfo>
    </StyledPriceDetailsWrapper>
  );
};
