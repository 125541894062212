import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  StyledButtonCancel,
  StyledButtonSave,
  StyledButtonsWrapper,
  StyledStateroomsCabinButton,
  StyledStateroomsCabinsWrapper,
  StyledStateroomsColumn,
  StyledStateroomsColumnContent,
  StyledStateroomsColumnEditButton,
  StyledStateroomsColumnEditWrapper,
  StyledStateroomsColumnsWrapper,
  StyledStateroomsColumnTitle,
  StyledStateroomsSectionTitle,
  StyledStateroomsWrapper,
  StyledTextInfo,
} from "./Staterooms.styles";
import IconEdit from "../../icons/IconEdit";
import { TravelersTable } from "../TravelersTable";
import { TypeFormEnum } from "../ModalFormEdit/types";
import { CurrentEditFormType } from "../../utils/globalTypes";
import { ModalFormEdit } from "../ModalFormEdit";
import { OrderCabin, OrderCabinItemType, OrderCruiseType, OrderType } from "../../appolo/graphql/queries/types";

type StateroomsProps = {
  booking: OrderType
  handleClickCabinNumber: (cabinId: number) => void
  orderCabin: OrderCabinItemType
}

export const Staterooms = ({ booking, handleClickCabinNumber, orderCabin }: StateroomsProps) => {
  const { t } = useTranslation();

  const [currentCabin, setCurrentCabin] = useState<OrderCabin>();

  const [currentEditForm, setCurrentEditForm] = useState<CurrentEditFormType>({
    title: "",
    typeEditForm: "",
  });

  useEffect(() => {
    if (booking) {
      const cabin = booking?.cruises[0]?.cabins[0];
      setCurrentCabin(cabin)
    }
  }, [booking]);

  const onClickCabinNumber = (cabin: OrderCabin) => {
    setCurrentCabin(cabin);
    handleClickCabinNumber(cabin.id);
  }

  console.log("currentCabin: ", currentCabin);
  console.log("orderCabin: ", orderCabin);

  return (
    <StyledStateroomsWrapper>
      <StyledStateroomsSectionTitle>
        {t("staterooms")}
      </StyledStateroomsSectionTitle>

      {/* Секция с номер кают */}
      <StyledStateroomsCabinsWrapper>
        {booking?.cruises?.map((cruise: OrderCruiseType) => (
          <div>
            {cruise?.cabins.map((cabin: OrderCabin) => (
              <StyledStateroomsCabinButton
                className={cabin?.number === currentCabin?.number ? "active" : ""}
                onClick={() => onClickCabinNumber(cabin)}
              >
                {cabin?.number}
              </StyledStateroomsCabinButton>
            ))}
          </div>
        ))}
      </StyledStateroomsCabinsWrapper>

      {/* Секция с информацией о выбраной каюте */}
      <StyledStateroomsColumnsWrapper>
        <StyledStateroomsColumn>
          <StyledStateroomsColumnTitle>
            {t("stateRoomNumber")}
          </StyledStateroomsColumnTitle>
          <StyledStateroomsColumnContent>
            {orderCabin?.number}
          </StyledStateroomsColumnContent>
        </StyledStateroomsColumn>

        <StyledStateroomsColumn>
          <StyledStateroomsColumnTitle>{t("deck")}</StyledStateroomsColumnTitle>
          <StyledStateroomsColumnContent>
            {orderCabin?.deck.name}
          </StyledStateroomsColumnContent>
        </StyledStateroomsColumn>

        <StyledStateroomsColumn>
          <StyledStateroomsColumnTitle>
            {t("category")}
          </StyledStateroomsColumnTitle>
          <StyledStateroomsColumnContent>
            {orderCabin?.class.name}
          </StyledStateroomsColumnContent>
        </StyledStateroomsColumn>

        <StyledStateroomsColumn>
          <StyledStateroomsColumnEditWrapper>
            <StyledStateroomsColumnTitle>
              {t("bedType")}
            </StyledStateroomsColumnTitle>
            <StyledStateroomsColumnEditButton
              onClick={() =>
                setCurrentEditForm({
                  title: "Additional requirements",
                  typeEditForm: TypeFormEnum.bedType,
                })
              }
            >
              <IconEdit />
            </StyledStateroomsColumnEditButton>
          </StyledStateroomsColumnEditWrapper>

          <StyledStateroomsColumnContent>
            {orderCabin?.bedConfiguration?.name}
          </StyledStateroomsColumnContent>
        </StyledStateroomsColumn>
      </StyledStateroomsColumnsWrapper>

      <StyledStateroomsSectionTitle className="guest">
        {t("guest")}
      </StyledStateroomsSectionTitle>

      <TravelersTable
        places={orderCabin?.places}
        setCurrentEditForm={(value) => setCurrentEditForm(value)}
      />

      {/* Модальное окно в котором формы для редактирования данных */}
      <ModalFormEdit
        title={currentEditForm.title}
        currentEditForm={currentEditForm}
        setCurrentEditForm={(value) => setCurrentEditForm(value)}
      >
        {currentEditForm.typeEditForm === TypeFormEnum.healthDeclaration && (
          <div>
            <StyledTextInfo>
              You can submit your Medical Screening Form. You will be redirected
              to the submission page
            </StyledTextInfo>
            <StyledButtonsWrapper>
              <StyledButtonCancel>
                {t("cancel")}
              </StyledButtonCancel>
              <StyledButtonSave>
                <Link to={`https://vikandmedical.com/swan-hellenic-en/`} target="_blank">
                  {t("submit")}
                </Link>
              </StyledButtonSave>
            </StyledButtonsWrapper>
          </div>
        )}
      </ModalFormEdit>
    </StyledStateroomsWrapper>
  );
};
