/* eslint-disable */
import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { FormControlLabel, Radio, TextField } from "@mui/material";
import { mainTheme } from "../../../theme";
import { CustomSelect } from "../components/CustomSelect";
import { FieldDatePicker } from "../components/FieldDatePicker";
import { CustomRadioGroup } from "../components/CustomRadioGroup";
import IconCheckRadio from "../../../icons/IconCheckRadio";
import { VariantCustomSelectEnum } from "../components/CustomSelect/types";
import { CustomCheckbox } from "../components/CustomCheckbox";
import { GENDER, INITIALS } from "./consts";
import { FormTravellerProps } from "./types";
import { CountriesListType } from "../../../utils/globalTypes";
import { getCountriesList } from "../../../utils/axios/hooks";
import { CustomAutocomplete } from "../components/CustomAutocomplete";
import { PhoneMaskField } from "../components/PhoneMaskField";
import {
  StyledButtonCancel,
  StyledButtonSave,
  StyledButtonsWrapper,
  StyledFiedlsRow,
  StyledSectionFieldsTitle,
  StyledWrapper,
} from "./FormTraveller.styles";

type IFormTravellerInput = {
  firstName?: string;
  middleName?: string;
  lastName?: string;
  title?: string;
  birthDate?: string;
  nationality?: string;
  gender?: string;
  loyaltyNumber?: string;
  phone?: string;
  email?: string;
  placeOfBirth?: string;
  placeOfIssue?: string;
  citizenship?: string;
  passportNumber?: string;
  issueDate?: string;
  expirationDate?: string;
  agreement?: boolean;
};

export const FormTraveller = ({ setOpen }: FormTravellerProps) => {
  const { t } = useTranslation();

  const [countriesList, setCountriesList] = useState<CountriesListType[]>([]);

  const schema = yup.object().shape({
    firstName: yup.string(),
    middleName: yup.string(),
    lastName: yup.string(),
    title: yup.string(),
    birthDate: yup.string(),
    // nationality: yup.string(),
    gender: yup.string(),
    loyaltyNumber: yup.string(),
    phone: yup.string(),
    email: yup.string(),
    placeOfBirth: yup.string(),
    placeOfIssue: yup.string(),
    // citizenship: yup.string(),
    passportNumber: yup.string(),
    issueDate: yup.string(),
    expirationDate: yup.string(),
    agreement: yup.boolean(),
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<IFormTravellerInput>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset({
      firstName: "William",
      middleName: "James",
      lastName: "Smith",
      title: "1",
      birthDate: "03.01.1990",
      nationality: "1",
      gender: "1",
      loyaltyNumber: "1244 5678 9012 3456",
      phone: "+44 077 1417 0414",
      email: "j.smith@yahoo.com",
      placeOfBirth: "London, United Kingdom",
      placeOfIssue: "London, United Kingdom",
      citizenship: "1",
      passportNumber: "308 537 172",
      issueDate: "03.01.1990",
      expirationDate: "03.01.1990",
      agreement: false,
    });
  }, []);

  const onSubmit = async (data: IFormTravellerInput) => {
    const formValue = {
      ...data,
      birthDate: dayjs(data.birthDate).format("YYYY.MM.DD"),
    };
    console.log("formValue: ", formValue);
  };

  const handleCancel = () => {
    if (setOpen) {
      setOpen(false);
    }
  };

  useEffect(() => {
    getCountriesList(setCountriesList);
  }, []);

  return (
    <StyledWrapper>
      <form>
        <StyledFiedlsRow>
          <Controller
            control={control}
            name="firstName"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <TextField
                label={t("firstName")}
                variant="standard"
                onChange={onChange}
                value={value}
                InputProps={{
                  className: "textFieldForm",
                  style: {
                    color: mainTheme.main.colors.black,
                    fontSize: mainTheme.main.fontSizes.large,
                    fontWeight: mainTheme.main.fontWeights.normal,
                    fontFamily: mainTheme.main.fonts.dmsRegular,
                  },
                }}
              />
            )}
          />
          <Controller
            control={control}
            name="middleName"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <TextField
                label={t("middleName")}
                variant="standard"
                onChange={onChange}
                value={value}
                InputProps={{
                  className: "textFieldForm",
                  style: {
                    color: mainTheme.main.colors.black,
                    fontSize: mainTheme.main.fontSizes.large,
                    fontWeight: mainTheme.main.fontWeights.normal,
                    fontFamily: mainTheme.main.fonts.dmsRegular,
                  },
                }}
              />
            )}
          />
          <Controller
            control={control}
            name="lastName"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <TextField
                label={t("lastName")}
                variant="standard"
                onChange={onChange}
                value={value}
                InputProps={{
                  className: "textFieldForm",
                  style: {
                    color: mainTheme.main.colors.black,
                    fontSize: mainTheme.main.fontSizes.large,
                    fontWeight: mainTheme.main.fontWeights.normal,
                    fontFamily: mainTheme.main.fonts.dmsRegular,
                  },
                }}
              />
            )}
          />
        </StyledFiedlsRow>

        <StyledFiedlsRow>
          <Controller
            control={control}
            name="title"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <CustomSelect
                label={t("initials")}
                onChange={onChange}
                defaultValue="1"
                variant={VariantCustomSelectEnum.standard}
              >
                {INITIALS.map((item) => (
                  <MenuItem value={item.value}>{item.label}</MenuItem>
                ))}
              </CustomSelect>
            )}
          />

          <Controller
            control={control}
            name="birthDate"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <FieldDatePicker
                onChange={onChange}
                value={value ? dayjs(value) : null}
                label={t("birthDate")}
              />
            )}
          />

          <Controller
            control={control}
            name="nationality"
            render={({ field: { onChange, onBlur, value, ref } }) => {
              return (
                <CustomAutocomplete
                  label={t("nationality")}
                  value={value}
                  onChange={onChange}
                  items={countriesList}
                  getOptionLabel={(option: { code: string; value: string }) => {
                    if (option?.hasOwnProperty("value")) {
                      return option.value;
                    }
                    return option;
                  }}
                  minSize={120}
                  variant="standard"
                  keyParams="value"
                />
              );
            }}
          />
        </StyledFiedlsRow>

        <StyledFiedlsRow className="oneColumns">
          <Controller
            control={control}
            name="gender"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <CustomRadioGroup
                label={t("gender")}
                onChange={onChange}
                defaultValue="1"
              >
                {GENDER.map((item) => (
                  <FormControlLabel
                    key={item.value}
                    value={item.value}
                    control={<Radio checkedIcon={<IconCheckRadio />} />}
                    // @ts-ignore
                    label={t(item.label)}
                  />
                ))}
              </CustomRadioGroup>
            )}
          />
        </StyledFiedlsRow>

        <StyledFiedlsRow className="oneColumns">
          <Controller
            control={control}
            name="loyaltyNumber"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <TextField
                label={t("loyaltyNumber")}
                variant="standard"
                onChange={onChange}
                value={value}
                InputProps={{
                  className: "textFieldForm",
                  style: {
                    color: mainTheme.main.colors.black,
                    fontSize: mainTheme.main.fontSizes.large,
                    fontWeight: mainTheme.main.fontWeights.normal,
                    fontFamily: mainTheme.main.fonts.dmsRegular,
                  },
                }}
              />
            )}
          />
        </StyledFiedlsRow>

        <StyledFiedlsRow className="twoColumns">
          <Controller
            control={control}
            name="phone"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <PhoneMaskField
                value={value}
                label={t("phone")}
                onChange={onChange}
              />
            )}
          />

          <Controller
            control={control}
            name="email"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <TextField
                label={t("email")}
                variant="standard"
                onChange={onChange}
                value={value}
                InputProps={{
                  className: "textFieldForm",
                  style: {
                    color: mainTheme.main.colors.black,
                    fontSize: mainTheme.main.fontSizes.large,
                    fontWeight: mainTheme.main.fontWeights.normal,
                    fontFamily: mainTheme.main.fonts.dmsRegular,
                  },
                }}
              />
            )}
          />
        </StyledFiedlsRow>

        <StyledSectionFieldsTitle>
          {t("passportDetails")}
        </StyledSectionFieldsTitle>

        <StyledFiedlsRow className="twoColumns">
          <Controller
            control={control}
            name="citizenship"
            render={({ field: { onChange, onBlur, value, ref } }) => {
              return (
                <CustomAutocomplete
                  label={t("citizenship")}
                  value={value}
                  onChange={onChange}
                  items={countriesList}
                  getOptionLabel={(option: { code: string; value: string }) => {
                    if (option?.hasOwnProperty("value")) {
                      return option.value;
                    }
                    return option;
                  }}
                  minSize={120}
                  variant="standard"
                  keyParams="value"
                />
              );
            }}
          />

          <Controller
            control={control}
            name="passportNumber"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <TextField
                label={t("passportNumber")}
                variant="standard"
                onChange={onChange}
                value={value}
                InputProps={{
                  className: "textFieldForm",
                  style: {
                    color: mainTheme.main.colors.black,
                    fontSize: mainTheme.main.fontSizes.large,
                    fontWeight: mainTheme.main.fontWeights.normal,
                    fontFamily: mainTheme.main.fonts.dmsRegular,
                  },
                }}
              />
            )}
          />
        </StyledFiedlsRow>

        <StyledFiedlsRow className="twoColumns">
          <Controller
            control={control}
            name="issueDate"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <FieldDatePicker
                onChange={onChange}
                value={value ? dayjs(value) : null}
                label={t("issueDate")}
              />
            )}
          />

          <Controller
            control={control}
            name="expirationDate"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <FieldDatePicker
                onChange={onChange}
                value={value ? dayjs(value) : null}
                label={t("expirationDate")}
              />
            )}
          />
        </StyledFiedlsRow>

        <StyledFiedlsRow className="twoColumns">
          <Controller
            control={control}
            name="placeOfBirth"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <TextField
                label={t("placeOfBirth")}
                variant="standard"
                onChange={onChange}
                value={value}
                InputProps={{
                  className: "textFieldForm",
                  style: {
                    color: mainTheme.main.colors.black,
                    fontSize: mainTheme.main.fontSizes.large,
                    fontWeight: mainTheme.main.fontWeights.normal,
                    fontFamily: mainTheme.main.fonts.dmsRegular,
                  },
                }}
              />
            )}
          />

          <Controller
            control={control}
            name="placeOfIssue"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <TextField
                label={t("placeOfIssue")}
                variant="standard"
                onChange={onChange}
                value={value}
                InputProps={{
                  className: "textFieldForm",
                  style: {
                    color: mainTheme.main.colors.black,
                    fontSize: mainTheme.main.fontSizes.large,
                    fontWeight: mainTheme.main.fontWeights.normal,
                    fontFamily: mainTheme.main.fonts.dmsRegular,
                  },
                }}
              />
            )}
          />
        </StyledFiedlsRow>

        <Controller
          control={control}
          name="agreement"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <CustomCheckbox
              value={value}
              label={t("agreement")}
              setValue={onChange}
            />
          )}
        />

        <StyledButtonsWrapper>
          <StyledButtonCancel variant="contained" onClick={handleCancel}>
            {t("cancel")}
          </StyledButtonCancel>
          <StyledButtonSave
            type="submit"
            variant="contained"
            onClick={handleSubmit(onSubmit)}
          >
            {t("save")}
          </StyledButtonSave>
        </StyledButtonsWrapper>
      </form>
    </StyledWrapper>
  );
};
