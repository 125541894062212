import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FieldDatePickerProps } from './types';
import 'dayjs/locale/ru';

export const FieldDatePicker = ({ label, onChange, value, disabled }: FieldDatePickerProps) => {
  const [locale, setLocale] = useState('en');

  const { t } = useTranslation();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
      <DatePicker 
        label={t(label)}
        onChange={onChange}
        onAccept={onChange}
        value={value ? dayjs(value) : null}
        format="DD.MM.YYYY"
        slotProps={{ textField: { variant: "standard" } }}
        disabled={disabled}

      />
    </LocalizationProvider>
  );
} 