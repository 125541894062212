import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FormAuth } from "../FormAuth";
import { StyledAuthWrapper, StyledTitle } from "./Auth.styles";

export const Auth = () => {
  const { t } = useTranslation();

  useEffect(() => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
  }, []);

  return (
    <StyledAuthWrapper>
      <StyledTitle>
        {t("login")}
      </StyledTitle>
      <FormAuth />
    </StyledAuthWrapper>
  );
};
