import styled from "styled-components";
import { Button } from "@mui/material";
import { mainTheme } from "../../../theme";

export const StyledFormEditPersonalInformationWrapper = styled.div`
  display: flex;
  flex-direction: column;

  form {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0;
    gap: 20px;
  }

  .textFieldForm {
    width: 100%;
    max-width: 100%;
  }

  .MuiInputLabel-root {
    font-size: ${mainTheme.main.fontSizes.large};
    font-weight: ${mainTheme.main.fontWeights.normal};
    color: ${mainTheme.main.colors.black} !important;
    font-family: ${mainTheme.main.fonts.dmsRegular};
    opacity: 0.5;
  }
`;

export const StyledButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
`;

export const StyledButtonSave = styled(Button)`
  &.MuiButton-root {
    border-radius: 20px;
    color: ${mainTheme.main.colors.white};
    font-size: ${mainTheme.main.fontSizes.medium};
    font-weight: ${mainTheme.main.fontWeights.normal};
    background: ${mainTheme.main.colors.blue};
    width: 138px;
    border: 1px solid ${mainTheme.main.colors.blue};

    &:hover {
      background: ${mainTheme.main.colors.blue};
    }

    @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
      width: 100%;
      padding: 9px 50px;
    }
  }
`;

export const StyledButtonCancel = styled(Button)`
  &.MuiButton-root {
    border-radius: 20px;
    color: ${mainTheme.main.colors.black};
    font-size: ${mainTheme.main.fontSizes.medium};
    font-weight: ${mainTheme.main.fontWeights.normal};
    background: ${mainTheme.main.colors.white};
    width: 138px;
    border: 1px solid ${mainTheme.main.colors.graySilver};
    box-shadow: none;

    &:hover {
      background: ${mainTheme.main.colors.white};
    }

    @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
      width: 100%;
      padding: 9px 50px;
    }
  }
`;

export const StyledFiedlsRow = styled.div`
  display: grid;
  grid-template-columns: minmax(168px, auto) minmax(168px, auto) minmax(168px, auto);
  gap: 20px;
  grid-auto-flow: row;
  box-sizing: border-box;
  align-items: unset;
  margin-bottom: 12px;
  margin-top: 12px;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    display: grid;
    grid-template-columns: minmax(auto, auto);
    margin-bottom: 0px;
  }

  &.oneColumns {
    grid-template-columns: minmax(auto, auto);
    margin-top: 0px;

    @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
      display: grid;
      grid-template-columns: minmax(auto, auto);
    }
  }

  &.twoColumns {
    grid-template-columns: minmax(260px, auto) minmax(260px, auto);
    margin-top: 0px;
    
    @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
      display: grid;
      grid-template-columns: minmax(auto, auto);
    }
  }
`;

export const StyledSectionFieldsTitle = styled.div`
  color: ${mainTheme.main.colors.blue};
  font-size: ${mainTheme.main.fontSizes.large};
  font-weight: ${mainTheme.main.fontWeights.bold};
  font-family: ${mainTheme.main.fonts.chapMedium};
  margin-top: 16px;
`;

export const StyledFiedlsRowContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const StyledCheckboxFieldWrapper = styled.div`
  position: absolute;
  bottom: -40px;

  @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
    position: static;
  }
`

export const StyledError = styled.div`
  position: absolute;
  bottom: -20px;
  color: ${mainTheme.main.colors.red};
  font-size: ${mainTheme.main.fontSizes.xsmall};
  font-weight: ${mainTheme.main.fontWeights.normal};

  &.middleName {
    position: static;

    @media (max-width: ${mainTheme.main.breakPoints.mobile}) {
      top: auto;
      bottom: -12px;
    }
  }
`

export const StyledErrorMessage = styled.div`
  margin-top: 12px;
  margin-bottom: 12px;
  color: ${mainTheme.main.colors.red};
  font-size: ${mainTheme.main.fontSizes.xsmall};
  font-weight: ${mainTheme.main.fontWeights.normal};
`