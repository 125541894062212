export const SUBSCRIPTIONS_ARRAY = [
  {
    name: "Monthly newsletter", 
    checked: false
  },
  // {
  //   name: "Special offers", 
  //   checked: false
  // },
  // {
  //   name: "Additional services and amenities", 
  //   checked: false
  // },
]