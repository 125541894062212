import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { TextField } from "@mui/material";
import { StyledButton, StyledButtonLogin, StyledError, StyledFieldWrapper, StyledFooter, StyledFormAuthWrapper, StyledLink } from "./FormAuth.styles";
import { AuthDataType } from "./types";
import { mainTheme } from "../../theme";
import { AUTH } from "../../appolo/graphql/mutations/auth";
import { WRONG_LOG_PASS } from "./consts";

interface IFormAuthInput {
  email: string;
  password: string;
}

export const FormAuth = () => {

  const { t } = useTranslation();

  const navigate = useNavigate();

  const [error, setError] = useState({
    type: "",
    message: ""
  })

  const [auth] = useMutation(AUTH)

  const schema = yup.object().shape({
    email: yup.string().required(t("fieldRequired")),
    password: yup.string().required(t("fieldRequired")),
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<IFormAuthInput>({ resolver: yupResolver(schema) });

  const onSubmit = async (values: IFormAuthInput) => {
    auth({
      variables: {
        login: values.email,
        password: values.password,
      },
      onCompleted: (data: AuthDataType) => {
        if (data?.login?.accessToken?.token) {
          navigate("/profile")
          localStorage.setItem("accessToken", data?.login?.accessToken?.token);
          localStorage.setItem("refreshToken", data?.login?.refreshToken?.token)
        }
      },
      onError: ({ message }) => {
        setError({
          type: 'auth',
          message: message === WRONG_LOG_PASS ? 'Неправильный логин или пароль' : message,
        })
        sessionStorage.removeItem("accessToken");
        sessionStorage.removeItem("refreshToken");
      },
    })
  };

  const handleForgotPassword = () => {
   navigate("/forgotPassword");
  }

  return (
    <StyledFormAuthWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledFieldWrapper>
          <Controller
            control={control}
            name="email"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <TextField
                label={t("email")}
                variant="standard"
                onChange={onChange}
                value={value}
                InputProps={{
                  className: "textFieldForm",
                  style: {
                    color: mainTheme.main.colors.black,
                    fontSize: mainTheme.main.fontSizes.large,
                    fontWeight: mainTheme.main.fontWeights.normal
                  }
                }}
              />
            )}
          />
          {errors.email && <StyledError>{errors.email.message}</StyledError>}
        </StyledFieldWrapper>

        <StyledFieldWrapper>
          <Controller
            control={control}
            name="password"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <TextField
                label={t("password")} 
                variant="standard"
                onChange={onChange}
                value={value}
                InputProps={{
                  className: "textFieldForm",
                  style: {
                    color: mainTheme.main.colors.black,
                    fontSize: mainTheme.main.fontSizes.large,
                    fontWeight: mainTheme.main.fontWeights.normal,
                  }
                }}
              />
            )}
          />
          {errors.password && <StyledError>{errors.password.message}</StyledError>}
        </StyledFieldWrapper>


        <StyledFooter>
          <StyledLink to="/registration">
            {t("registration")}
          </StyledLink>
          <StyledButton onClick={handleForgotPassword}>
            {t("forgotPassword")}
          </StyledButton>
        </StyledFooter>

        <StyledButtonLogin type="submit">
          {t("login")}
        </StyledButtonLogin>
      </form>
    </StyledFormAuthWrapper>
  );
};
