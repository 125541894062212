import { gql } from '@apollo/client'

export const CURRENT_USER = gql`
  query CURRENT_USER {
    currentUser {
      id
      uuid
      crmUserId
      kind
      cashRegisterId
      firstname
      lastname
      patronymic
      email
      phone
      gender
      dob
      countryOfResidence
      address 
      markets {
        id
        name
        alias
        timezone
        settings {
          isPassengersDocumentsRequired
        }
      }
      permissions {
        canRefundAnyOrder
      }
      company {
        id
        name
        longName
        inn
        email
        phone {
          code
          number
        }
      }
      document {
        kind
        nationality
        series
        number
        issueDate
        expirationDate
        issuePlace
        issueCountry
        placeOfBirth
      }
    }
  }
`
