export const HEAD_CEALS = [
  {
    id: "№",
    numeric: false,
    disablePadding: true,
    label: "№",
  },
  {
    id: "guest",
    numeric: true,
    disablePadding: false,
    label: "Guest",
  },
  {
    id: "contacts",
    numeric: true,
    disablePadding: false,
    label: "Contacts",
  },
  {
    id: "additionalRequirements",
    numeric: true,
    disablePadding: false,
    label: "Additional requirements",
  },
  {
    id: "travelArrangement",
    numeric: true,
    disablePadding: false,
    label: "Travel arrangement",
  },
  {
    id: "healthDeclaration",
    numeric: true,
    disablePadding: false,
    label: "Health declaration",
  },
  {
    id: "farePrice",
    numeric: true,
    disablePadding: false,
    label: "Fare / Price",
  },
  {
    id: "Travel documents",
    numeric: true,
    disablePadding: false,
    label: "travelDocuments",
  },
];
